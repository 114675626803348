import React, { useEffect, useState } from "react";
import Logo from "../components/logo/Logo";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Fixed from "../components/Fixed";
import FixedHeading from "../components/FixedHeading";
import { Grid } from "@mui/material";
const Welcome = ({ handleNext, handlePrev }) => {
  return (
    <Grid container spacing="20px" sx={{height: "100%", maxHeight: "550px", maxWidth: "1600px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{ width: "100%"}}>
            <h1 className="flex pb-5 w-full justify-center text-[#7C6FF7] 2xl:text-[50px] xl:text-[40px] text-[30px] font-[800] leading-[48px] font-Montserrat">
              Disclaimer - Please read carefully
            </h1>
      </Grid>
      <Grid item sx={{ width: "100%",  overflowY: "auto", py: "1.2rem!important", px: "3rem!important", border: "solid 1px", borderRadius: "25px"}}>
          <div className="flex flex-col  gap-1  2xl:text-[24px] xl:text-[21px] text-[18px] lg:text-[20px] text-black  ">
            <p>
              The <span className="underline">Virtual Vision Screener</span>{" "}
              is
              <span className="font-bold"> not a medical evaluation</span> and
              shouldn't replace consultations with qualified eye specialists.
            </p>
            <p>
              It's not designed for disease diagnosis or its management,
              treatment, or prevention.
            </p>
            <p>
              This assessment aims to provide a basic understanding of your
              visual health and to suggest if a detailed eye check-up is
              needed.
            </p>
            <p>
              Your eyes should be examined by an eye care professional every
              two years or earlier if you observe any vision alterations.
            </p>
            <p>
              Vision Science Labs is not responsible for any harm or outcomes
              stemming from the use of the Vision Assessment or the
              information it offers.
            </p>
          </div>
      </Grid>

      <Grid item>
          <Button
            style={{
              backgroundColor: "#7C6FF7",
              borderRadius: "25px",
              color: "white",
              fontSize: "24px",
              padding: "1.8rem",
              height: "3.5rem",
              fontWeight: "700",
              width: "177px",
            }}
            appearance="primary"
            className="button-style"
            onClick={handleNext}
          >
            I AGREE
          </Button>
      </Grid>



    </Grid>
  );
};

export default Welcome;
