import React from "react";
import { Button } from "rsuite";
import { Grid, Stack } from "@mui/material";

import homevvs from "../../Images/home-vvs.png";
import Logo from "../logo/Logo";
import { LaunchGame2 } from "../../scripts/GameEngines";
import Navigation from "../navigations/Navigation";
import covereye from "../../Images/cover_eye.png";
import FixedHeading from "../FixedHeading";

function Step20({ handleNext, handlePrev, setCBResponse }) {
  return (
    <Grid container spacing="15px" sx={{height: "100%", maxHeight: "700px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{ maxWidth: "100%"}}>
            <div className="flex flex-col items-center w-full px-[6rem]">
              <p className="text-[#7C6FF7] lg:text-[30px]  text-center text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
                Maintain your distance to the screen and cover your <br /> RIGHT
                eye.
              </p>
            </div>

      </Grid>
    <Grid item sx={{ width: "100%", overflowY: "auto"}}>
      <div className="flex w-full justify-center py-[1.5rem]">
            <img
              className="lg:w-[327px] lg:h-[340px] max-w-[288px] max-h-[300px] aspect-auto"
              src={covereye}
            />
          </div>
    </Grid>
    <Grid>
      <Button
        style={{
          backgroundColor: "#7C6FF7",
          borderRadius: "25px",
          color: "white",
          fontSize: "24px",
          padding: "1.8rem",
          height: "3.5rem",
          fontWeight: "700",
          width: "177px",
        }}
        appearance="primary"
        className="button-style"
        onClick={() =>
          LaunchGame2("AmslerGrid", (data) => {
            setCBResponse(data);
            handleNext();
          })
        }      
        >
        NEXT
      </Button>
    </Grid>
  </Grid>
  );
}

export default Step20;


{/* <div className="flex flex-col justify-center  h-[10vh]  h-[80vh]  gap-[2rem] mx-auto w-max-w-[90rem] w-full align-center">
<div className="flex  justify-between   h-full  flex-col">
  <div className=" flex flex-col gap-[0.5rem]">
    <div className="flex flex-col items-center w-full px-[6rem]">
      <FixedHeading>
        <p className="text-[#7C6FF7] text-center pt- lg:text-[30px] text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
          Maintain your distance to the screen and cover your <br /> RIGHT
          eye.
        </p>
      </FixedHeading>
    </div>
    <div className="flex w-full justify-center py-[1.5rem]">
      <img
        className="lg:w-[327px] lg:h-[340px] max-w-[288px] max-h-[300px] aspect-auto"
        src={covereye}
      />
    </div>
  </div>
  <div className="flex justify-center    w-full relative   uppercase font-Montserrat">
    <Button
      style={{
        backgroundColor: "#7C6FF7",
        borderRadius: "25px",
        color: "white",
        fontSize: "24px",
        padding: "1.8rem",
        height: "3.5rem",
        fontWeight: "700",
        width: "177px",
      }}
      appearance="primary"
      className="button-style"
      onClick={() =>
        LaunchGame2("AmslerGrid", (data) => {
          setCBResponse(data);
          handleNext();
        })
      }
    >
      NEXT
    </Button>
  </div>
</div>
</div> */}