import Arrow from './Arrow.png'

import { CountdownScreen, Katalyst, ReadyScreen } from './Katalyst'

const {
  LegacyEmbed,
  PlayerCursor,
  Trackerz,
  TumblingEKreator,
} = require('./StandardEngines')
const {
  VT2,
  RDS,
  AX,
  Tracker,
  TrackerS,
  Reakt,
  Kreators,
  Shuffle4,
  Spritors,
  AXLR,
  TrackerLR,
  VectorUtils,
  Raincheck,
} = require('./LegacyEngines')
const { VT_Base } = require('./StandardEngines')
const { Meta, MetaRandom, Randex } = require('./metaengine')

let caliCompleted = false
let distanceCompleted = false

export function LaunchCali(CB = () => {}) {
  window.Meta.Do(ScreenCali_Engine, function () {
    console.log(caliCompleted)
    CB(caliCompleted)
    // if (caliCompleted) {
    // 	CB(step + 1);
    // } else {
    // 	CB(step - 1);
    // }
  })
  
}

export function LaunchDistance(CB,CB2) {
  window.Meta.Do(DistanceCali_Engine, function () {
    const MinCm = window.MetaVision.PixToCm(5)
    const ArcInRadians = (5 / 60) * (Math.PI / 180)
    const ReqDistance = Math.max(
      Math.ceil((1 / Math.tan(ArcInRadians)) * MinCm),
      50
    )
    console.log(distanceCompleted,ReqDistance)
    CB(distanceCompleted)
    CB2(ReqDistance)
  })
  
}

export function LaunchDistance2(mainFunction = () => {}) {
  window.Meta.Do(DistanceCali_Engine2(mainFunction))
}

export function LaunchGame(GID, CB, distance) {
  const engine = {
    distance: distance,
    Start: function () {
      if(window.Meta.get("distance")=="calculateDistance")
      {
        const MinCm = window.MetaVision.PixToCm(5)
        const ArcInRadians = (5 / 60) * (Math.PI / 180)
        const ReqDistance = Math.max(
          Math.ceil((1 / Math.tan(ArcInRadians)) * MinCm),
          50
        )
        window.Meta.set("distance",ReqDistance)
      }
      window.Meta.SubDo( DistanceCali_Engine3,()=>{window.Meta.Stage.Clear(); window.Meta.SubDo( CountdownScreen, Katalyst.Start)})
    },
  }
  
  engine.ENG = _Game_Library_[GID][0]
  const SEND = {}
  Object.entries(_Game_Library_[GID][1]).forEach((e) => {
    const parser = _Settings_Procs_[e[0]]
    if (parser) {
      engine[e[0]] = parser(e[1])
      //~ SEND[e[0]] = parser(e[1]);
    } else {
      engine[e[0]] = e[1]
      //~ SEND[e[0]] = e[1];
    }
  })

  //~ if(metadata.pregame && metadata.pregame.reqL){
  //~ combo["_SEND_"] = SEND;
  //~ }

  window.Meta.Do(engine, function (res) {
    if (res) {
      CB(res)
    }
  })
}
export function LaunchGame2(GID, CB = console.log) {
  const engine = {
    Start: function () {
      window.Meta.SubDo( CountdownScreen, Katalyst.Start)
    },
  }
  
  engine.ENG = _Game_Library_[GID][0]
  const SEND = {}
  Object.entries(_Game_Library_[GID][1]).forEach((e) => {
    const parser = _Settings_Procs_[e[0]]
    if (parser) {
      engine[e[0]] = parser(e[1])
      //~ SEND[e[0]] = parser(e[1]);
    } else {
      engine[e[0]] = e[1]
      //~ SEND[e[0]] = e[1];
    }
  })

  //~ if(metadata.pregame && metadata.pregame.reqL){
  //~ combo["_SEND_"] = SEND;
  //~ }

  window.Meta.Do(engine, function (res) {
    if (res) {
      CB(res)
    }
  })
}
//Temp Chart Results
export function GetSessionResults(GID, Stat, dex = null) {
  const json = localStorage.getItem('RES_' + GID)
  const array = json ? JSON.parse(json) : []

  const ret = []
  for (let R of array) {
    switch (Stat) {
      case 'Points':
        ret.push(R.Score)
        break
      default:
        if (dex != null) {
          ret.push(R[Stat][dex])
        } else {
          ret.push(R[Stat])
        }
        break
    }
  }

  return ret
}

export function ClearResults(GID) {
  localStorage.removeItem('RES_' + GID)
}

export const _Settings_Procs_ = {
  Acuity: function (val) {
    const ns = val.split('/')
    return ns[0] / ns[1]
  },
  StereoAcuity: function (val) {
    return parseInt(val)
  },
  SpeedPerHour: function (val) {
    return (val * 10000) / 60 / 60
  },
  ThreshSpeed: function (val) {
    return {
      Dex: val,
      Val: function () {
        return (this.Dex * 0.2 * 100000) / 60 / 60
      },
    }
  },
  // ThreshStereo : function(val){return {Dex: val, Val: function(){this.Dex = Math.min(this.Dex, ThreshholdStereoArray.length); return ThreshholdStereoArray[this.Dex-1];}}},
  SpeedRPM: function (val) {
    return val / 60
  },
  FlashSpeed: function (val) {
    return parseFloat(val)
  },
}

export const DistanceCali_Engine3 = {
  Start: function () {

    console.log(window.Meta.get("TrueDistance"))
    window.MetaVision.PatientDistance = window.Meta.get("distance")
    const Small = window.Meta.Stage.Height < 1080
    window.Meta.Stage.Background('#000000')

    const FOCUS = window.Meta.Circle(10, { fill: '#cccccc' })
    FOCUS.Top = -175  // -10
    FOCUS.Left = -10

    const rad = window.MetaVision.BlindRad();
    let theRad = rad;
    if (theRad == Infinity) theRad = 50;
    if (theRad > 95) theRad = 90;
    const BLIND = window.Meta.Circle(theRad, { fill: '#cc00cc' })
    const BLIND2 = window.Meta.Circle(theRad, { fill: '#cc00cc' })

    BLIND.Top = -200
      // window.MetaVision.CmToPix(window.MetaVision.ArcToCm(1.5 * 3600)) - rad
    console.log(rad)
    window.Meta.set('TrueSide', 'Right')
    var Side = ''
    BLIND2.Top = -200;
      // window.MetaVision.CmToPix(window.MetaVision.ArcToCm(1.5 * 3600)) - rad

  	BLIND.Right = -window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15*3600)) + rad;
    BLIND2.Left = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15*3600)) - rad;
    /*
		if (window.Meta.get("TrueSide") == "Right"){
			Side = "LEFT";
			BLIND.Left = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15*3600)) - rad;
		}else if (window.Meta.get("TrueSide") == "Left"){
			Side = "RIGHT";
			BLIND.Right = -window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15*3600)) + rad;
		}else{
			Side = "LEFT";
			BLIND.Left = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15*3600)) - rad;
		}
			 */
    const INST_1 = window.Meta.Text(
      `<div style="text-align:left">Distance yourself ${window.Meta.get("distance")}cm from the screen using a tape measure or use the purple dots instructions below. <br/>- Cover your LEFT eye. <br/>- Keep you head still. <br/> - Click any arrow key to begin.</div>`,
      { fill: 'white', size: Small ? 15 : 25 }
    )
    INST_1.Top = Small ? 270 : -475
    INST_1.Left = window.Meta.Stage.Width * 0.1
    INST_1.Right = 0
    const INST_0 = window.Meta.Text(
      '<div style="text-align:left">How to adjust your screen distance using the purple dots.<br/><br/>1. Stare at the white dot.<br/>2. Cover an eye with your hand and SLOWLY move your head closer or farther from the screen until the purple dot disappears<br/>&#x2022; Left eye cover: the purple dot on the right disappears.<br/>&#x2022; Right eye cover: the purple dot on the left disappears.<br/><br/>When a dot disappears, then you are at the correct distance to the screen. You can use the purple dots to distance yourself for the eye tests.</div>',
      { fill: 'white', size: Small ? 15 : 25 }
    )
    // INST_0.Top = Small ? 200 : 320
    INST_0.Bot = INST_0.Top = Small ? 0 : 120
    INST_0.Left = window.Meta.Stage.Width * 0.1
    INST_0.Right = 0

    const BUT_1 = window.Meta.Image(183, 60, 'NEXT Button.png', true)
    BUT_1.onclick = function () {
      window.Meta.Return('end')
    }
    BUT_1.Bot = Small ? 320 : 475
    // BUT_1.Right = window.Meta.Stage.Width * 0.5 - 10
    // BUT_1.Right = Small ? -135 : -160
    BUT_1.Right = Small ? -135 : 50

    window.Meta.Redraw()

    window.Meta.Redraw()
  },
}


export const DistanceCali_Engine2 = (callback) => ({
  Start: function () {
    if (window.Cali.PatientDistance == 0) window.Cali.PatientDistance = 50
    const Small = window.Meta.Stage.Height < 1080
    window.Meta.Stage.Background('#000000')

    const FOCUS = window.Meta.Circle(10, { fill: '#cccccc' })
    FOCUS.Top = -10
    FOCUS.Left = -10

    const rad = window.MetaVision.BlindRad()
    const BLIND = window.Meta.Circle(rad, { fill: '#cc00cc' })
    BLIND.Top =
      window.MetaVision.CmToPix(window.MetaVision.ArcToCm(1.5 * 3600)) - rad
    console.log(rad)
    window.Meta.set('TrueSide', 'Right')

    const INST_0 = window.Meta.Text(
      '<div style="text-align:left">How to adjust your screen distance using the purple dots.<br/><br/>1. Stare at the white dot.<br/>2. Cover an eye with your hand and SLOWLY move your head closer or farther from the screen until the purple dot disappears<br/>&#x2022; Left eye cover: the purple dot on the right disappears.<br/>&#x2022; Right eye cover: the purple dot on the left disappears.<br/><br/>When a dot disappears, then you are at the correct distance to the screen. You can use the purple dots to distance yourself for the eye tests.</div>',
      { fill: 'white', size: Small ? 15 : 25 }
    )
    INST_0.Top = Small ? -275 : -430
    INST_0.Left = window.Meta.Stage.Width * 0.1
    INST_0.Right = 0

    // const TEXT3 = window.Meta.Text('Did you get the dot to disappear?', {
    //   fill: 'white',
    //   size: Small ? 28 : 40,
    // })
    // TEXT3.Bot = Small ? 300 : 375
    // TEXT3.Left = -300

    // const BUT_0 = window.Meta.Image(183, 60, 'YES Button.png', true)
    // BUT_0.onclick = function () {
    //   distanceCompleted = true
    //   window.Meta.Return('end')
    // }
    // BUT_0.Bot = Small ? 320 : 475
    // BUT_0.Left = Small ? 135 : 160

    // Ok button
    const BUT_1 = window.Meta.Image(183, 60, 'NEXT Button.png', true)
    BUT_1.onclick = function () {
      window.Meta.Return('end', callback)
    }
    BUT_1.Bot = Small ? 320 : 475
    BUT_1.Right = window.Meta.Stage.Width * 0.5 - 10
    window.Meta.Redraw()
  },
})

export const DistanceCali_Engine = {
  Start: function () {
    window.MetaVision.PatientDistance = 50
    const Small = window.Meta.Stage.Height < 1080
    window.Meta.Stage.Background('#000000')

    const FOCUS = window.Meta.Circle(10, { fill: '#cccccc' })
    FOCUS.Top = -10
    FOCUS.Left = -10

    const rad = window.MetaVision.BlindRad()
    const BLIND = window.Meta.Circle(rad, { fill: '#cc00cc' })
    const BLIND2 = window.Meta.Circle(rad, { fill: '#cc00cc' })
    BLIND.Top =
      window.MetaVision.CmToPix(window.MetaVision.ArcToCm(1.5 * 3600)) - rad
    console.log(rad)
    window.Meta.set('TrueSide', 'Right')
    var Side = ''
    BLIND2.Top =
      window.MetaVision.CmToPix(window.MetaVision.ArcToCm(1.5 * 3600)) - rad
    console.log(rad)
  	BLIND.Right = -window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15*3600)) + rad;
    BLIND2.Left = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15*3600)) - rad;
    /*
		if (window.Meta.get("TrueSide") == "Right"){
			Side = "LEFT";
			BLIND.Left = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15*3600)) - rad;
		}else if (window.Meta.get("TrueSide") == "Left"){
			Side = "RIGHT";
			BLIND.Right = -window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15*3600)) + rad;
		}else{
			Side = "LEFT";
			BLIND.Left = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15*3600)) - rad;
		}
			 */

    const INST_0 = window.Meta.Text(
      '<div style="text-align:left">How to adjust your screen distance using the purple dots.<br/><br/>1. Stare at the white dot.<br/>2. Cover an eye with your hand and SLOWLY move your head closer or farther from the screen until the purple dot disappears<br/>&#x2022; Left eye cover: the purple dot on the right disappears.<br/>&#x2022; Right eye cover: the purple dot on the left disappears.<br/><br/>When a dot disappears, then you are at the correct distance to the screen. You can use the purple dots to distance yourself for the eye tests.</div>',
      { fill: 'white', size: Small ? 15 : 25 }
    )
    INST_0.Top = Small ? -275 : -430
    INST_0.Left = window.Meta.Stage.Width * 0.1
    INST_0.Right = 0

    const TEXT3 = window.Meta.Text('Did you get the dot to disappear?', {
      fill: 'white',
      size: Small ? 28 : 40,
    })
    TEXT3.Bot = Small ? 300 : 375
    TEXT3.Left = -300

    const BUT_0 = window.Meta.Image(183, 60, 'YES Button.png', true)
    BUT_0.onclick = function () {
      distanceCompleted = true
      window.Meta.Return('end')
    }
    BUT_0.Bot = Small ? 320 : 475
    BUT_0.Left = Small ? 135 : 160

    // Cancel button
    const BUT_1 = window.Meta.Image(183, 60, 'NO Button.png', true)
    BUT_1.onclick = function () {
      distanceCompleted = false
      window.Meta.Return('end')
    }
    BUT_1.Bot = Small ? 320 : 475
    BUT_1.Right = Small ? -135 : -160

    window.Meta.Redraw()
  },
}

// const ColorCali_Engine = {
// 	Start : function(){
// 		const Small = Meta.Stage.Height < 1080;

// 		const TITLE = Meta.Text("Color Calibration", {fill:"white", size:Small?32:48});
// 		TITLE.Top = Small?-320:-500;
// 		TITLE.Left = 0;
// 		TITLE.Right = 0;

// 		const TEXT = Meta.Text("Put on 3D glasses before beginning.<br>Cover your RIGHT eye and adjust the slider until the RED dot is barely visible.<br>Next, cover your LEFT eye and adjust the slider until the BLUE dot is barely visible.", {fill:"white", size:Small?28:40});
// 		TEXT.Top = Small?-250:-400;
// 		TEXT.Left = 0;
// 		TEXT.Right = 0;

// 		const LEFT = Meta.Circle(Small?75:150, {fill:"#ff0000"});
// 		LEFT.Top = Small?-70:-140;
// 		LEFT.Right = -112;

// 		const RIGHT = Meta.Circle(Small?75:150, {fill:"#ff0000"});
// 		RIGHT.Top = Small?-70:-140;
// 		RIGHT.Left = 112;

// 		if(Meta.get("Wizard")){
// 			const BUT = Meta.Button("Finish", function(){Meta.Return("end")}, {fill:"#000000", line:"#ffffff", stroke:4, size:Small?28:40});
// 			BUT.Bot = Small?320:500;
// 			BUT.Left = 15;

// 			const BBUT = Meta.Button("Back", function(){Meta.Return("pick")}, {fill:"#000000", line:"#ffffff", stroke:4, size:Small?28:40});
// 			BBUT.Bot = Small?320:500;
// 			BBUT.Right = -15;
// 		}else{
// 			const BUT = Meta.Button("Done", function(){Meta.Return()}, {fill:"#000000", line:"#ffffff", stroke:4, size:Small?28:40});
// 			BUT.Bot = Small?320:500;
// 			BUT.Left = -50;
// 		}

// 		var BlueVal = PIXI.utils.hex2rgb(Cali.LeftColor)[2];
// 		var RedVal = PIXI.utils.hex2rgb(Cali.RightColor)[0];

// 		function DrawColors(){
// 			LEFT.Fill = "#" + Math.round(RedVal * 256).toString(16) + "0000";
// 			Cali.RightColor = RedVal;
// 			RIGHT.Fill = "#0000" + Math.round(BlueVal * 256).toString(16);
// 			Cali.LeftColor = BlueVal;
// 		}

// 		DrawColors();

// 		const SLIDERL = Meta.Slider(36, 255, RedVal*256, function(){
// 			RedVal = this.value/256;
// 			DrawColors();
// 		});
// 		SLIDERL.Width = Small?140:280;
// 		SLIDERL.Top = Small?110:220;
// 		SLIDERL.Right = Small?-120:-125;

// 		const SLIDERR = Meta.Slider(36, 255, BlueVal*256, function(){
// 			BlueVal = this.value/256;
// 			DrawColors();
// 		});
// 		SLIDERR.Width = Small?140:280;
// 		SLIDERR.Top = Small?110:220;
// 		SLIDERR.Left = Small?120:125;

// 		Meta.Redraw();
// 	},
// };

export const ScreenCali_Engine = {
  Start: function () {
    const Small = window.Meta.Stage.Height < 1080
    const OriginalScreenSize = window.Cali.ScreenSize

    /*const TITLE = window.Meta.Text("Screen Calibration", {fill:"white", size:Small?32:48});
		TITLE.Top = Small?-320:-500;
		TITLE.Left = -500;
		TITLE.Right = 0; */

    window.Meta.Stage.Background('#000000')

    const CARD = window.Meta.Image(286, 180, 'id.png', true)
    CARD.Top = -90
    CARD.Left = -143

    const INST_1 = window.Meta.Text(
      '<div style="text-align:left;font-weight: bold">Next, let’s adjust your screen size.</div>',
      { fill: 'white', size: Small ? 25 : 36 }
    )
    INST_1.Top = Small ? -375 : -480
    // INST_1.Top = Small ? -375 : -530

    INST_1.Left = window.Meta.Stage.Width * 0.1
    INST_1.Right = 0

    const INST_0 = window.Meta.Text(
      '<div style="text-align:left">1. Hold your credit card or ID up to the screen.<br/>2. Use the up / down arrow keys to adjust the size of the image to the size of your card.</div>',
      { fill: 'white', size: Small ? 25 : 36 }
    )
    INST_0.Top = Small ? -275 : -430
    INST_0.Left = window.Meta.Stage.Width * 0.1
    INST_0.Right = 0

    const BW = Small ? 135 : 150
    const BH = Small ? 35 : 50
    const BR = Small ? 74 : 100

    const BUT_0 = window.Meta.Image(183, 60, 'NEXT Button.png', true)
    BUT_0.onclick = function () {
      caliCompleted = true
      window.Meta.Return('end')
    }
    BUT_0.Bot = Small ? 320 : 475
    BUT_0.Left = Small ? -80 : -80

    // Cancel button
    // const BUT_1 = window.Meta.Image(183, 60, 'CANCEL Button.png', true)
    // BUT_1.onclick = function () {
    //   caliCompleted = false
    //   window.Meta.Return('end')
    // }
    // BUT_1.Bot = Small ? 320 : 475
    // BUT_1.Right = Small ? -135 : -160

    const TEXT = window.Meta.Text('', { fill: 'white', size: Small ? 28 : 40 })
    TEXT.Bot = (Small ? 240 : 400) - BR / 4
    TEXT.Left = 0
    TEXT.Right = 0
    TEXT.ClickThrough = true

    function DrawCard() {
      if (window.Cali.ScreenSize == 0) window.Cali.ScreenSize = 20

      const CardWith = window.MetaVision.CmToPix(8.56)
      const CardHeight = window.MetaVision.CmToPix(5.398)
      CARD.Width = CardWith
      CARD.Height = CardHeight
      CARD.Top = -CardHeight / 2
      CARD.Left = -CardWith / 2
      window.Meta.Redraw()
      TEXT.Text = window.Cali.ScreenSize + ' in'
    }
    DrawCard()

    const SS_Up = () => {
      window.Cali.ScreenSize++
      DrawCard()
    }

    const SS_Down = () => {
      window.Cali.ScreenSize = Math.max(window.Cali.ScreenSize - 1, 7)
      DrawCard()
    }
    const SS_Cancel = () => {
      window.Cali.ScreenSize = OriginalScreenSize
    }

    window.Meta.KeyDown('Left', SS_Down)
    window.Meta.KeyDown('Right', SS_Up)
    window.Meta.KeyDown('Up', SS_Up)
    window.Meta.KeyDown('Down', SS_Down)

    window.Meta.Redraw()
  },
}

const Diamonds_FG = window.Meta.Engine(VT_Base, PlayerCursor, LegacyEmbed, {
  LegacyEngine: window.Meta.Combine(
    RDS,
    VT2,
    AX,
    Tracker,
    TrackerS,
    Reakt,
    Kreators,
    Shuffle4,
    Spritors,
    {
      Start: function (meta) {
        meta.backgroundColor = 0x000000
        this.Time = window.Meta.get('Time')
        this.VergenceType = window.Meta.get('VergenceType')
        this.SeparationRateD = window.Meta.get('SeparationRateD')
        this.SeparationRateC = window.Meta.get('SeparationRateC')
        this.SeparationRateS = window.Meta.get('SeparationRateS')
        this.SeparationRateI = window.Meta.get('SeparationRateI')
        this.Acuity = window.Meta.get('Acuity')
        this.PixelSize = window.Meta.get('PixelSize')
        this.StereoAcuity = window.Meta.get('StereoAcuity')

        window.Meta.get('SetScore')(0)
        window.Meta.get('StartTimer')(this.Time)

        meta.PixiApp.stage.addChild(this.RDS)
        this.RDS.PixelSize = this.PixelSize
        this.RDS.Depth = window.MetaVision.CmToPix(
          window.MetaVision.ArcToCm(this.StereoAcuity)
        )

        switch (this.VergenceType) {
          case 'Convergence':
            this.TrackerS.mode = 'C'
            this.TrackerS.sepAmnt = [this.SeparationRateC]
            break
          case 'Divergence':
            this.TrackerS.mode = 'D'
            this.TrackerS.sepAmnt = [this.SeparationRateD]
            break
          case 'Jump':
            this.TrackerS.mode = 'J'
            this.TrackerS.sepAmnt = [this.SeparationRateC, this.SeparationRateD]
            break
          case 'Supra':
            this.TrackerS.mode = 'BU'
            this.TrackerS.sepAmnt = [this.SeparationRateS]
            break
          case 'Infra':
            this.TrackerS.mode = 'BD'
            this.TrackerS.sepAmnt = [this.SeparationRateI]
            break
        }

        this.ScoreMult = this._ScoreMult ? this._ScoreMult : 1

        this.Diamond = meta.Object()
        this.Diamond.Kreate = (self) => {
          const dim = window.MetaVision.CmToPix(
            window.MetaVision.ArcToCm(window.MetaVision.VaToArc(this.Acuity))
          )
          self.beginFill(0xff00ff).drawRect(-dim / 2, -dim / 2, dim, dim)
          self.rotation = Math.PI / 4
          self.x = this.Stage.midX
          self.y = this.Stage.midY

          switch (self.randex) {
            case 0:
              self.y -= this.RDS.RDIM / 2 - dim
              break
            case 1:
              self.x += this.RDS.RDIM / 2 - dim
              break
            case 2:
              self.y += this.RDS.RDIM / 2 - dim
              break
            case 3:
              self.x -= this.RDS.RDIM / 2 - dim
              break
          }
        }
        this.Diamond.randex = 0
        this.AddKreator(this.Diamond)
        this.RDS.addChild(this.Diamond.S)

        const StdSounds = window.Meta.get('StdSounds')()
        this.Shuffle4.Shuffle = () => {
          this.RDS.ReSeed()
          const randex = window.MetaRandom.Compass()
          this.Diamond.S.randex = randex
          this.Diamond.Kreate(this.Diamond.S)
          return randex
        }
        this.Shuffle4.Correct = () => {
          window.Meta.get('UnpauseTimer')()
          this.ScoreUp(100 * this.ScoreMult)
          //this.RDS.Separation = [5, 0];
          this.ScoreUpL(100 * this.ScoreMult)
          this.ScoreUpR(100 * this.ScoreMult)
          window.Meta.get('SetScore')(this.Score)
          this.AX.Acc(this.Reakt_Mark())
          this.Tracker.Correct()
          this.TrackerS.Correct()
          StdSounds.Hit()
          this.RDS.Separation = this.TrackerS.val()
        }
        this.Shuffle4.Wrong = () => {
          window.Meta.get('UnpauseTimer')()
          this.ScoreDown(50 * this.ScoreMult)
          this.ScoreDownL(50 * this.ScoreMult)
          this.ScoreDownR(50 * this.ScoreMult)
          window.Meta.get('SetScore')(this.Score)
          this.AX.Acc(this.Reakt_Mark())
          this.Tracker.Wrong()
          this.TrackerS.Wrong()
          StdSounds.Miss()
          this.RDS.Separation = this.TrackerS.val()
        }

        window.Meta.KeyDown('Accept', () => {
          this.TrackerS.Mercy()
          this.RDS.Separation = this.TrackerS.val()
        })
      },
      Resize: function (meta) {
        this.RDS.RDIM = meta.Screen.height * 0.7
        this.Shuffle4.Init()
      },
      KountdownEnd: function (meta) {
        if (this.Score > 0) {
          const type = {
            Divergence: 'Relax',
            Convergence: 'Flex',
            Jump: 'Stamina',
          }

          const scoreData = {
            score: this.Score,
            hits: this.Tracker.val(),
            avg_hit_time: this.AX.val() || 0,
            break:
              this.TrackerS.mode === 'J'
                ? [this.TrackerS.best[0], this.TrackerS.best[1]]
                : this.TrackerS.best[0],
            recovery:
              this.TrackerS.mode === 'J'
                ? [this.TrackerS.worst[0], this.TrackerS.worst[1]]
                : this.TrackerS.worst[0],
          }

          window.localStorage.setItem('finishScreen', 'true')

          setTimeout(function () {
            window.location.reload()
          }, 1500)
        }

        window.Meta.Return({
          Score: this.Score,
          Hits: this.Tracker.val(),
          AvgHitTime: this.AX.val(),
          Break:
            this.TrackerS.mode == 'J'
              ? [this.TrackerS.best[0], this.TrackerS.best[1]]
              : this.TrackerS.best[0],
          Recovery:
            this.TrackerS.mode == 'J'
              ? [this.TrackerS.worst[0], this.TrackerS.worst[1]]
              : this.TrackerS.worst[0],
        })
      },
    }
  ),
})

const TrackingBaseLR = window.Meta.Combine(
  VT2,
  AXLR,
  TrackerLR,
  Reakt,
  Shuffle4,
  VectorUtils,
  Raincheck,
  {
    Load: [['Arrow', Arrow]],
    Start: function (meta) {
      meta.backgroundColor = 0x000000
      this.WidthSave = 0
      this.HeightSave = 0

      this.Time = window.Meta.get('Time')
      this.MM = window.Meta.get('SizeMM')
      this.KPH = window.Meta.get('SpeedPerHour')
      this.Orientation = window.Meta.get('Orientation')
      if (!this.Orientation)
        this.Orientation = window.Meta.get('OrientationSaccade')
      this.SaccadeType = window.Meta.get('SaccadeType')

      window.Meta.get('SetScore')(0)
      window.Meta.get('StartTimer')(this.Time)
      this.MainArrow = {}
      this.InitStimulus(meta)
      meta.backgroundColor = 0x000000
      var mainTint

      if (this.DoPeripheral) {
        this.CenterCircle = new window.PIXI.Graphics()
          .lineStyle(4, mainTint)
          .drawCircle(0, 0, 300)
        meta.PixiApp.stage.addChild(this.CenterCircle)

        this.CenterFix = new window.PIXI.Text('#', {
          fontFamily: 'Arial',
          fontSize: 34,
          fill: mainTint,
          align: 'center',
          fontWeight: 'bold',
        })
        this.CenterCircle.addChild(this.CenterFix)
      }

      this.MainArrow.ShufflePosition = () => {
        this.MainArrow.S.x = window.MetaRandom.Range(
          this.Stage.left + this.MainArrow.S.width / 2,
          this.Stage.right - this.MainArrow.S.width / 2
        )
        this.MainArrow.S.y = window.MetaRandom.Range(
          this.Stage.top + this.MainArrow.S.height / 2,
          this.Stage.bot - this.MainArrow.S.height / 2
        )

        if (this.Orientation == 'H') {
          this.MainArrow.S.y = this.Stage.midY
        } else if (this.Orientation == 'V') {
          this.MainArrow.S.x = this.Stage.midX
        } else if (this.Orientation == 'CC') {
          this.MainArrow.cX = this.MainArrow.S.x
          this.MainArrow.cY = this.MainArrow.S.y
        }

        if (this.DoPeripheral) {
          if (
            this.Vector.Distance(
              [this.MainArrow.S.x, this.MainArrow.S.y],
              [this.CenterCircle.x, this.CenterCircle.y]
            ) <
            this.CenterCircle.width / 2 + this.MainArrow.S.width / 2
          ) {
            const Vec = this.Vector.Direction(
              [this.MainArrow.S.x, this.MainArrow.S.y],
              [this.CenterCircle.x, this.CenterCircle.y]
            )
            Vec[0] *= this.CenterCircle.width / 2 + this.MainArrow.S.width / 2
            Vec[1] *= this.CenterCircle.width / 2 + this.MainArrow.S.width / 2
            this.MainArrow.S.x = this.CenterCircle.x + Vec[0]
            this.MainArrow.S.y = this.CenterCircle.y + Vec[1]
          }
        }

        this.MainArrow.Phase = window.MetaRandom.Range(0, 2 * Math.PI)
      }

      this.MainArrow.ShuffleRotation = () => {
        const randex = window.MetaRandom.Compass()
        this.MainArrow.S.rotation = (Math.PI / 2) * randex
        return randex
      }

      this.MainArrow.ShuffleVector = () => {
        if (this.Orientation == 'R') {
          this.MainArrow.Mode = 'R'
          this.MainArrow.Phase = 0
          this.MainArrow.Shift = window.MetaRandom.Dex(2) * 2 - 1
          this.MainArrow.Vec = [Math.sin(0), Math.cos(0)]
        } else if (this.Orientation == 'C') {
          this.MainArrow.Mode = 'C'
          this.MainArrow.Shift = window.MetaRandom.Dex(2) * 2 - 1
        } else if (this.Orientation == 'CC') {
          this.MainArrow.Mode = 'CC'
          this.MainArrow.cR = window.MetaRandom.Range(30, 45)
          this.MainArrow.Shift = window.MetaRandom.Dex(2) * 2 - 1
        } else if (this.Orientation == 'Fig8') {
          this.MainArrow.Mode = 'Fig8'
          this.MainArrow.Shift = MetaRandom.Dex(2) * 2 - 1
        } else {
          this.MainArrow.Mode = 'V'

          //~ if (this.Orientation == "4"){
          //~ if (this.MainArrow.S.x == this.Stage.midX){
          //~ this.MainArrow.Vec = MetaRandom.Vector(MetaVision.CmToPix(this.KPH), "V");
          //~ }else{
          //~ this.MainArrow.Vec = MetaRandom.Vector(MetaVision.CmToPix(this.KPH), "H");
          //~ }
          //~ }else{
          this.MainArrow.Vec = window.MetaRandom.Vector(
            window.MetaVision.CmToPix(this.KPH),
            this.Orientation
          )
          //~ }
        }
      }

      this.MainArrow.VecMove = (delta) => {
        if (this.MainArrow.Mode == 'V') {
          // this.MainArrow.S.x += this.MainArrow.Vec[0] * delta/60, 1;
          // this.MainArrow.S.y += this.MainArrow.Vec[1] * delta/60, 1;

          this.MainArrow.S.x += (this.MainArrow.Vec[0] * delta) / 60
          this.MainArrow.S.y += (this.MainArrow.Vec[1] * delta) / 60

          if (
            this.MainArrow.S.x <
            this.Stage.left + this.MainArrow.S.width / 2
          ) {
            this.MainArrow.Vec[0] = Math.abs(this.MainArrow.Vec[0])
          } else if (
            this.MainArrow.S.x >
            this.Stage.right - this.MainArrow.S.width / 2
          ) {
            this.MainArrow.Vec[0] = -Math.abs(this.MainArrow.Vec[0])
          }

          if (
            this.MainArrow.S.y <
            this.Stage.top + this.MainArrow.S.height / 2
          ) {
            this.MainArrow.Vec[1] = Math.abs(this.MainArrow.Vec[1])
          } else if (
            this.MainArrow.S.y >
            this.Stage.bot - this.MainArrow.S.height / 2
          ) {
            this.MainArrow.Vec[1] = -Math.abs(this.MainArrow.Vec[1])
          }
        } else if (this.MainArrow.Mode == 'R') {
          // this.MainArrow.S.x += this.MainArrow.Vec[0] * delta/60, 1;
          // this.MainArrow.S.y += this.MainArrow.Vec[1] * delta/60, 1;
          this.MainArrow.S.x += (this.MainArrow.Vec[0] * delta) / 60
          this.MainArrow.S.y += (this.MainArrow.Vec[1] * delta) / 60

          const spd = window.MetaVision.CmToPix(this.KPH)
          if (Math.abs(this.MainArrow.Phase) < 6) {
            this.MainArrow.Phase = Math.min(
              this.MainArrow.Phase + (this.MainArrow.Shift * delta) / 20,
              180
            )
            this.MainArrow.Vec = [
              Math.sin(this.MainArrow.Phase) * spd,
              Math.cos(this.MainArrow.Phase) * spd,
            ]
          }

          if (
            this.MainArrow.S.x <
            this.Stage.left + this.MainArrow.S.width / 2
          ) {
            this.MainArrow.S.x = this.Stage.left + this.MainArrow.S.width / 2
            this.MainArrow.Phase = Math.PI / 2
            //this.MainArrow.Shift *= -1;
          } else if (
            this.MainArrow.S.x >
            this.Stage.right - this.MainArrow.S.width / 2
          ) {
            this.MainArrow.S.x = this.Stage.right - this.MainArrow.S.width / 2
            this.MainArrow.Phase = (3 * Math.PI) / 2
            //this.MainArrow.Shift *= -1;
          }

          if (
            this.MainArrow.S.y <
            this.Stage.top + this.MainArrow.S.height / 2
          ) {
            this.MainArrow.S.y = this.Stage.top + this.MainArrow.S.height / 2
            this.MainArrow.Phase = 0
            this.MainArrow.Shift *= -1
          } else if (
            this.MainArrow.S.y >
            this.Stage.bot - this.MainArrow.S.height / 2
          ) {
            this.MainArrow.S.y = this.Stage.bot - this.MainArrow.S.height / 2
            this.MainArrow.Phase = Math.PI
            this.MainArrow.Shift *= -1
          }
        } else if (this.MainArrow.Mode == 'C') {
          const R =
            this.Stage.bot - this.Stage.midY - this.MainArrow.S.height / 2
          this.MainArrow.S.x =
            this.Stage.midX + Math.cos(this.MainArrow.Phase) * R
          this.MainArrow.S.y =
            this.Stage.midY + Math.sin(this.MainArrow.Phase) * R

          const spd = window.MetaVision.CmToPix(this.KPH)
          const C = 2 * Math.PI * R
          this.MainArrow.Phase +=
            ((this.MainArrow.Shift * delta) / 60) * 2 * Math.PI * (spd / C)
        } else if (this.MainArrow.Mode == 'CC') {
          this.MainArrow.S.x =
            this.MainArrow.cX +
            Math.cos(this.MainArrow.Phase) * this.MainArrow.cR
          this.MainArrow.S.y =
            this.MainArrow.cY +
            Math.sin(this.MainArrow.Phase) * this.MainArrow.cR

          const spd = window.MetaVision.CmToPix(this.KPH)
          const C = 2 * Math.PI * this.MainArrow.cR
          this.MainArrow.Phase +=
            ((this.MainArrow.Shift * delta) / 60) * 2 * Math.PI * (spd / C)
        } else if (this.MainArrow.Mode == 'Fig8') {
          const Rx =
            this.Stage.right - this.Stage.midX - this.MainArrow.S.width / 2
          const Ry =
            this.Stage.bot - this.Stage.midY - this.MainArrow.S.height / 2
          this.MainArrow.S.x =
            this.Stage.midX + Math.cos(this.MainArrow.Phase) * Rx
          this.MainArrow.S.y =
            this.Stage.midY + Math.sin(this.MainArrow.Phase * 2) * Ry

          const spd = window.MetaVision.CmToPix(this.KPH)
          this.MainArrow.Phase +=
            ((this.MainArrow.Shift * delta) / 60) *
            2 *
            Math.PI *
            (spd / meta.PixiApp.screen.width)
        }

        if (this.DoPeripheral) {
          if (
            this.Vector.Distance(
              [this.MainArrow.S.x, this.MainArrow.S.y],
              [this.CenterCircle.x, this.CenterCircle.y]
            ) <
            this.CenterCircle.width / 2 + this.MainArrow.S.width / 2
          ) {
            this.MainArrow.Vec = this.Vector.Direction(
              [this.MainArrow.S.x, this.MainArrow.S.y],
              [this.CenterCircle.x, this.CenterCircle.y]
            )
            const spd = window.MetaVision.CmToPix(this.KPH)
            this.MainArrow.Vec[0] *= spd
            this.MainArrow.Vec[1] *= spd
          }
        }
      }

      const StdSounds = window.Meta.get('StdSounds')()
      this.Shuffle4.Shuffle = () => {
        this.ShuffleLook(meta)
        // this.CurRandSide = window.MetaRandom.Dex(2);
        if (this.CurRandSide) {
          this.CurRandSide = 0
        } else {
          this.CurRandSide = 1
        }
        this.MainArrow.S.tint =
          this.CurRandSide == 0 ? window.Cali.LeftColor : window.Cali.RightColor // Aarrow Color
        return this.ShuffleStimulus(meta)
      }
      this.Shuffle4.Correct = () => {
        window.Meta.get('UnpauseTimer')()
        window.Meta.get('SetScore')(this.Score)

        this.ScoreUp(100)
        if (this.CurRandSide == 0) {
          this.AXL.Acc(this.Reakt_Mark())
          this.TrackerL.Correct()
          this.ScoreUpL(100)
        } else {
          this.AXR.Acc(this.Reakt_Mark())
          this.TrackerR.Correct()
          this.ScoreUpR(100)
        }
        StdSounds.Hit()
      }
      this.Shuffle4.Wrong = () => {
        window.Meta.get('UnpauseTimer')()
        window.Meta.get('SetScore')(this.Score)

        this.ScoreDown(50)
        if (this.CurRandSide == 0) {
          this.AXL.Acc(this.Reakt_Mark())
          this.TrackerL.Wrong()
          this.ScoreDownL(50)
        } else {
          this.AXR.Acc(this.Reakt_Mark())
          this.TrackerR.Wrong()
          this.ScoreDownR(50)
        }
        StdSounds.Miss()
      }

      if (this.DoPeripheral) {
        this.fixtimer = 0
        this.BadHits = 0
        this.xMisses = 0
        this.xShows = 0

        window.Meta.KeyDown('Accept', () => {
          if (this.CenterFix.text == 'X') {
            this.xMisses--
          } else {
            this.BadHits++
          }
          this.CycleFixation()
        })
      }
    },
    CycleFixation: function (meta) {
      if (this.CenterFix.text == 'X') {
        this.xMisses++
        this.xShows++
      }
      if (Randex(10) == 0 && this.CenterFix.text != 'X') {
        this.CenterFix.text = 'X'
      } else {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWYZ'
        const randex = window.MetaRandom.Dex(25)
        const next = letters[randex]
        if (next == this.CenterFix.text) {
          this.CenterFix.text = letters[(randex + 1) % 4]
        } else {
          this.CenterFix.text = next
        }
      }
      this.fixtimer = 1
    },
    Tick: function (meta, delta) {
      this.StimulusMotion(meta, delta)

      if (this.DoPeripheral) {
        this.fixtimer -= delta / 60
        if (this.fixtimer <= 0) {
          this.CycleFixation()
        }
      }
    },
    KountdownEnd: function (meta) {
      if (this.ScoreL > 0 && this.ScoreR > 0) {
        if (this.DoPeripheral) {
          window.Meta.Return({
            Score: this.Score,
            Hits: this.Tracker.val(),
            AvgHitTime: this.AX.val(),
            FalsePositives: this.BadHits,
            FixationLoss: [this.xMisses, this.xShows],
          })
        } else {
          let gameName

          const scoreData = {
            score_l: this.ScoreL,
            score_r: this.ScoreR,
            hits_l: this.TrackerL.val(),
            avg_hit_time_l: this.AXL.val() || 0,
            hits_r: this.TrackerR.val(),
            avg_hit_time_r: this.AXR.val() || 0,
          }

          window.localStorage.setItem('finishScreen', 'true')

          setTimeout(function () {
            window.location.reload()
          }, 1500)
        }
      }

      window.Meta.Return({
        ScoreL: this.ScoreL,
        ScoreR: this.ScoreR,
        HitsL: this.TrackerL.val(),
        AvgHitTimeL: this.AXL.val(),
        HitsR: this.TrackerR.val(),
        AvgHitTimeR: this.AXR.val(),
      })
    },
    Resize: function (meta) {
      if (this.CenterCircle) {
        this.CenterCircle.x = this.Stage.midX
        this.CenterCircle.y = this.Stage.midY
      }
      this.MainArrow.ShufflePosition()
      this.Shuffle4.Init()
      //~ if (Meta.get("TrackingType") == "Pursuit")
    },
  }
)

const JumpTracking = window.Meta.Combine(TrackingBaseLR, {
  ShuffleStimulus: function (meta) {
    this.MainArrow.ShufflePosition()
    this.MainArrow.ShuffleVector()
    return this.MainArrow.ShuffleRotation()
  },
  StimulusMotion: function (meta, delta) {
    this.MainArrow.VecMove(0)
  },
})

const SmoothTracking = window.Meta.Combine(TrackingBaseLR, {
  Start: function (meta) {
    this.MainArrow.ShufflePosition()
    this.MainArrow.ShuffleVector()

    const DoShuffle = () => {
      this.MainArrow.ShuffleVector()
      window.Meta.DoAfter(2500, DoShuffle)
    }

    if (
      this.SaccadeType == 'Reflexive' ||
      this.Orientation == '4' ||
      this.Orientation == '8'
    ) {
      DoShuffle()
    }
  },
  ShuffleStimulus: function (meta) {
    return this.MainArrow.ShuffleRotation()
  },
  StimulusMotion: function (meta, delta) {
    this.MainArrow.VecMove(delta)
  },
})

const ComboTracking = window.Meta.Combine(TrackingBaseLR, {
  ShuffleStimulus: function (meta) {
    this.MainArrow.ShufflePosition()
    this.MainArrow.ShuffleVector()
    return this.MainArrow.ShuffleRotation()
  },
  StimulusMotion: function (meta, delta) {
    this.MainArrow.VecMove(delta)
  },
})

const StandardArrow = {
  ShuffleLook: function (meta) {
    //~ this.MainArrow.S.tint = 0xffffff;
  },
  InitStimulus: function (meta) {
    this.MainArrow.S = new window.PIXI.Sprite.from(Arrow)
    this.MainArrow.S.anchor.set(0.5)
    meta.PixiApp.stage.addChild(this.MainArrow.S)
  },
  Resize: function (meta) {
    const dim = window.MetaVision.CmToPix(this.MM / 10)
    this.MainArrow.S.width = dim
    this.MainArrow.S.height = dim
  },
}

const PhoneAccomm_FG = window.Meta.Engine(VT_Base, Trackerz, TumblingEKreator, {
  Start: function () {
    const inst = window.Meta.get('GameInst')
    const KreateE = window.Meta.get('KreateE')
    const Tracker = window.Meta.get('NewTracker')()
    const SetScore = window.Meta.get('SetScore')
    SetScore(Tracker.Str)
    window.Meta.set('Tracker', Tracker)
    const AX = window.Meta.get('NewAX')()
    const ReaktMark = window.Meta.get('ReaktMark')
    window.Meta.set('AX', AX)

    const DisplayImage = KreateE(false)
    DisplayImage.Size = 720
    DisplayImage.Top = -360
    DisplayImage.Left = -360
    DisplayImage.Fill = 'white'

    const Shuffle = () => {
      DisplayImage.Rotation =
        (DisplayImage.Rotation + (window.MetaRandom.Dex(3) + 1) * 90) % 360
      inst.Write('rot', 1)
    }

    inst.On('rot', (val) => {
      if (val != null && val != 1) {
        if (val == DisplayImage.Rotation) {
          Tracker.Hit()
        } else {
          Tracker.Miss()
        }
        SetScore(Tracker.Str)
        AX.Acc(ReaktMark())
        Shuffle()
      }
    })

    Shuffle()
    window.Meta.get('StartTimer')(window.Meta.get('Time'))
  },
  EndGame: function () {
    const hits = window.Meta.get('Tracker').Val
    window.Meta.Return({
      Hits: hits,
      AvgHitTime: Meta.get('AX').Val,
      Cycles: hits[0],
    })
  },
})

// const AccommMedly_FG = window.Meta.Engine(VT_Base, Trackerz, TumblingEKreator, {
// 	Start : function(){
// 		const inst = window.Meta.get("GameInst");  // New
// 		const KreateE = window.Meta.get("KreateE");
// 		window.Meta.Stage.Background("#000000");
// 		const NewTracker = window.Meta.get("NewTracker");
// 		const SetScore = window.Meta.get("SetScore");
// 		SetScore("");
// 		const NewAX = window.Meta.get("NewAX");
// 		const ReaktMark = window.Meta.get("ReaktMark");
// 		window.Meta.set("AX", AX);

// 		window.Meta.set("ResLeft", {
// 			Tracker: NewTracker(),
// 			AX: NewAX(),
// 		});
// 		window.Meta.set("ResRight", {
// 			Tracker: NewTracker(),
// 			AX: NewAX(),
// 		});
// 		window.Meta.set("ResBoth", {
// 			Tracker: NewTracker(),
// 			AX: NewAX(),
// 		});

// 		const DisplayImage = KreateE(false);
// 		DisplayImage.Size = 100;
// 		DisplayImage.Top = -50;
// 		DisplayImage.Left = -50;
// 		DisplayImage.Fill = "black";

// 		// const Shuffle = ()=>{
// 		// 	DisplayImage.Rotation = (DisplayImage.Rotation + (MetaRandom.Dex(3)+1) * 90) % 360;
// 		// 	inst.Write("rot", 1);
// 		// }

// 		const MainTxt = window.Meta.Text("", {fill:"#ffffff", size:64});
// 		MainTxt.Width = 500;
// 		MainTxt.Left = -250;
// 		MainTxt.Height = 100;
// 		MainTxt.Top = -50;

// 		const StdSounds = window.Meta.get("StdSounds")();

// 		var state = "";
// 		var answer = "";
// 		function Shuffle(){
// 			const L = window.MetaRandom.Dex(9) + 1;
// 			const R = window.MetaRandom.Dex(9) + 1;
// 			MainTxt.Text = L + "+" + R;
// 			const ROT = (DisplayImage.Rotation + (window.MetaRandom.Dex(3)+1) * 90) % 360;
// 			DisplayImage.Rotation = ROT;
// 			const CurRand = window.MetaRandom.Dex(3);
// 			window.Meta.set("CurRand",CurRand);
// 			if (CurRand == 0){
// 				MainTxt.Fill = window.Cali.LeftColor;
// 				DisplayImage.Fill = window.Cali.LeftColor;
// 				inst.Write("Color", window.Cali.LeftColor); // New
// 			}else if (CurRand == 1){
// 				MainTxt.Fill = window.Cali.RightColor;
// 				DisplayImage.Fill = window.Cali.RightColor;
// 				inst.Write("Color", window.Cali.RightColor); // New
// 			}else{
// 				MainTxt.Fill = "white";
// 				DisplayImage.Fill = "white";
// 				inst.Write("Color", "white"); // New
// 			}

// 			if(window.MetaRandom.Dex(5)==0){
// 				DisplayImage.Fill = "black";
// 				inst.Write("Solution", L + R); // New
// 			}else{
// 				MainTxt.Text = "";
// 				inst.Write("Solution", ROT); // New
// 			}
// 		}

// 		function Correct(){
// 			StdSounds.Hit();
// 			const reakt = ReaktMark();
// 			SetScore(Math.round(reakt*1000)/1000);
// 			var Res;
// 			switch(window.Meta.get("CurRand")){
// 			case 0:
// 				Res = window.Meta.get("ResLeft");
// 				break;
// 			case 1:
// 				Res = window.Meta.get("ResRight");
// 				break;
// 			case 2:
// 				Res = window.Meta.get("ResBoth");
// 				break;
// 			}
// 			Res.Tracker.Hit();
// 			Res.AX.Acc(reakt);
// 			Shuffle();
// 		}

// 		function Wrong(){
// 			StdSounds.Miss();
// 			const reakt = ReaktMark();
// 			SetScore(Math.round(reakt*1000)/1000);
// 			var Res;
// 			switch(window.Meta.get("CurRand")){
// 			case 0:
// 				Res = window.Meta.get("ResLeft");
// 				break;
// 			case 1:
// 				Res = window.Meta.get("ResRight");
// 				break;
// 			case 2:
// 				Res = window.Meta.get("ResBoth");
// 				break;
// 			}
// 			Res.Tracker.Miss();
// 			Res.AX.Acc(reakt);
// 			Shuffle();
// 		}

// 		Shuffle();
// 		window.Meta.get("StartTimer")(window.Meta.get("Time"));

// 		inst.On("a", (val)=>{ // New
// 			if (val){ // New
// 				inst.Write("a", null); // New
// 				if (val == 1337){ // New
// 					Correct(); // New
// 				}else{ // New
// 					Wrong(); // New
// 				} // New
// 			} // New
// 		}) // New

// 		window.Meta.KeyDown("x", Wrong); // Remove
// 		window.Meta.KeyDown("c", Correct); // Remove
// 	},
// 	EndGame : async function(meta){
// 		const Left = window.Meta.get("ResLeft");
// 		const Right = window.Meta.get("ResRight");
// 		const Both = window.Meta.get("ResBoth");

// 		if(Left.AX.Val > 0 && Right.AX.Val > 0 && Both.AX.Val > 0) {
// 			const scoreData = {
// 				avg_hit_time_l: Left.AX.Val,
// 				hits_l: Left.Tracker.Val,
// 				avg_hit_time_r: Right.AX.Val,
// 				hits_r: Right.Tracker.Val,
// 				avg_hit_time_b: Both.AX.Val,
// 				hits_b: Both.Tracker.Val,
// 			}

// 			await createUserPlayedGame(
// 				'Focus Speed',
// 				'',
// 				'Astucia',
// 				scoreData
// 			)

// 			await updateUserDailyActivity('focus speed', '')
// 			window.localStorage.setItem('finishScreen', 'true');

// 			setTimeout(function() {
// 				console.log('reload');
// 				window.location.reload();
// 			}, 1500);
// 		}

// 		window.Meta.Return({
// 			AvgHitTimeL: Left.AX.Val,
// 			HitsL: Left.Tracker.Val,
// 			AvgHitTimeR: Right.AX.Val,
// 			HitsR: Right.Tracker.Val,
// 			AvgHitTimeB: Both.AX.Val,
// 			HitsB: Both.Tracker.Val,
// 		});
// 	},
// });

/**************************************************************/

const AccommTumbleE_FG = window.Meta.Engine(
  VT_Base,
  Trackerz,
  TumblingEKreator,
  {
    Start: function () {
      const inst = window.Meta.get('GameInst') // New
      const KreateE = window.Meta.get('KreateE')
      console.log(KreateE)
      window.Meta.Stage.Background('#000000')
      const NewTracker = window.Meta.get('NewTracker')
      const SetScore = window.Meta.get('SetScore')
      SetScore('')
      const NewAX = window.Meta.get('NewAX')
      const ReaktMark = window.Meta.get('ReaktMark')
      window.Meta.set('AX', AX)
      window.Meta.set('CurRand', 1)

      window.Meta.set('ResLeft', {
        Tracker: NewTracker(),
        AX: NewAX(),
      })
      window.Meta.set('ResRight', {
        Tracker: NewTracker(),
        AX: NewAX(),
      })
      window.Meta.set('ResBoth', {
        Tracker: NewTracker(),
        AX: NewAX(),
      })

      const DisplayImage = KreateE(false)
      DisplayImage.Size = 100
      DisplayImage.Top = -50
      DisplayImage.Left = -50
      DisplayImage.Fill = 'black'

      // const Shuffle = ()=>{
      // 	DisplayImage.Rotation = (DisplayImage.Rotation + (MetaRandom.Dex(3)+1) * 90) % 360;
      // 	inst.Write("rot", 1);
      // }

      const StdSounds = window.Meta.get('StdSounds')()

      var state = ''
      var answer = ''
      function Shuffle() {
        const ROT =
          (DisplayImage.Rotation + (window.MetaRandom.Dex(3) + 1) * 90) % 360
        DisplayImage.Rotation = ROT
        const OldRand = window.Meta.get('CurRand')
        const CurRand = OldRand == 0 ? 1 : 0
        window.Meta.set('CurRand', CurRand)
        if (CurRand == 0) {
          DisplayImage.Fill = window.Cali.LeftColor
          inst.Write('Color', window.Cali.LeftColor) // New
        } else if (CurRand == 1) {
          DisplayImage.Fill = window.Cali.RightColor
          inst.Write('Color', window.Cali.RightColor) // New
        }

        inst.Write('Solution', ROT) // New
      }

      function Correct() {
        window.Meta.get('UnpauseTimer')()
        StdSounds.Hit()
        const reakt = ReaktMark()
        SetScore(Math.round(reakt * 1000) / 1000)
        var Res
        switch (window.Meta.get('CurRand')) {
          case 0:
            Res = window.Meta.get('ResLeft')
            break
          case 1:
            Res = window.Meta.get('ResRight')
            break
          case 2:
            Res = window.Meta.get('ResBoth')
            break
        }
        Res.Tracker.Hit()
        Res.AX.Acc(reakt)
        Shuffle()
      }

      function Wrong() {
        window.Meta.get('UnpauseTimer')()
        StdSounds.Miss()
        const reakt = ReaktMark()
        SetScore(Math.round(reakt * 1000) / 1000)
        var Res
        switch (window.Meta.get('CurRand')) {
          case 0:
            Res = window.Meta.get('ResLeft')
            break
          case 1:
            Res = window.Meta.get('ResRight')
            break
          case 2:
            Res = window.Meta.get('ResBoth')
            break
        }
        Res.Tracker.Miss()
        Res.AX.Acc(reakt)
        Shuffle()
      }

      Shuffle()
      window.Meta.get('StartTimer')(window.Meta.get('Time'))

      inst.On('a', (val) => {
        // New
        if (val) {
          // New
          inst.Write('a', null) // New
          if (val == 1337) {
            // New
            Correct() // New
          } else {
            // New
            Wrong() // New
          } // New
        } // New
      }) // New

      window.Meta.KeyDown('x', Wrong) // Remove
      window.Meta.KeyDown('c', Correct) // Remove
    },
    EndGame: async function (meta) {
      const Left = window.Meta.get('ResLeft')
      const Right = window.Meta.get('ResRight')
      const Both = window.Meta.get('ResBoth')

      if (Left.AX.Val > 0 && Right.AX.Val > 0) {
        const scoreData = {
          avg_hit_time_l: Left.AX.Val,
          hits_l: Left.Tracker.Val,
          avg_hit_time_r: Right.AX.Val,
          hits_r: Right.Tracker.Val,
          avg_hit_time_b: Both.AX.Val,
          hits_b: Both.Tracker.Val,
        }

        window.localStorage.setItem('finishScreen', 'true')

        setTimeout(function () {
          console.log('reload')
          window.location.reload()
        }, 1500)
      }

      window.Meta.Return({
        AvgHitTimeL: Left.AX.Val,
        HitsL: Left.Tracker.Val,
        AvgHitTimeR: Right.AX.Val,
        HitsR: Right.Tracker.Val,
        AvgHitTimeB: Both.AX.Val, //Remove
        HitsB: Both.Tracker.Val, //Remove
      })
    },
  }
)

const AccommMath_FG = window.Meta.Engine(VT_Base, Trackerz, {
  Start: function () {
    const inst = window.Meta.get('GameInst') // New
    window.Meta.Stage.Background('#000000')
    const NewTracker = window.Meta.get('NewTracker')
    const SetScore = window.Meta.get('SetScore')
    SetScore('')
    const NewAX = window.Meta.get('NewAX')
    const ReaktMark = window.Meta.get('ReaktMark')
    window.Meta.set('AX', AX)
    window.Meta.set('CurRand', 1)

    window.Meta.set('ResLeft', {
      Tracker: NewTracker(),
      AX: NewAX(),
    })
    window.Meta.set('ResRight', {
      Tracker: NewTracker(),
      AX: NewAX(),
    })
    window.Meta.set('ResBoth', {
      Tracker: NewTracker(),
      AX: NewAX(),
    })

    // const Shuffle = ()=>{
    // 	DisplayImage.Rotation = (DisplayImage.Rotation + (MetaRandom.Dex(3)+1) * 90) % 360;
    // 	inst.Write("rot", 1);
    // }

    const MainTxt = window.Meta.Text('', { fill: '#ffffff', size: 64 })
    MainTxt.Width = 500
    MainTxt.Left = -250
    MainTxt.Height = 100
    MainTxt.Top = -50

    const StdSounds = window.Meta.get('StdSounds')()

    var state = ''
    var answer = ''
    function Shuffle() {
      const L = window.MetaRandom.Dex(9) + 1
      const R = window.MetaRandom.Dex(9) + 1
      MainTxt.Text = L + '+' + R
      const OldRand = window.Meta.get('CurRand')
      const CurRand = OldRand == 0 ? 1 : 0
      window.Meta.set('CurRand', CurRand)
      if (CurRand == 0) {
        MainTxt.Fill = window.Cali.LeftColor
        // DisplayImage.Fill = window.Cali.LeftColor;
        inst.Write('Color', window.Cali.LeftColor) // New
      } else if (CurRand == 1) {
        MainTxt.Fill = window.Cali.RightColor
        // DisplayImage.Fill = window.Cali.RightColor;
        inst.Write('Color', window.Cali.RightColor) // New
      }
      inst.Write('Solution', L + R) // New
    }

    function Correct() {
      window.Meta.get('UnpauseTimer')()
      StdSounds.Hit()
      const reakt = ReaktMark()
      // SetScore(Math.round(reakt*1000)/1000);
      SetScore(Math.round(reakt * 1000) / 1000 + ' s')
      var Res
      switch (window.Meta.get('CurRand')) {
        case 0:
          Res = window.Meta.get('ResLeft')
          break
        case 1:
          Res = window.Meta.get('ResRight')
          break
        case 2:
          Res = window.Meta.get('ResBoth')
          break
      }
      Res.Tracker.Hit()
      Res.AX.Acc(reakt)
      Shuffle()
    }

    function Wrong() {
      window.Meta.get('UnpauseTimer')()
      StdSounds.Miss()
      const reakt = ReaktMark()
      SetScore(Math.round(reakt * 1000) / 1000 + ' s')
      var Res
      switch (window.Meta.get('CurRand')) {
        case 0:
          Res = window.Meta.get('ResLeft')
          break
        case 1:
          Res = window.Meta.get('ResRight')
          break
        case 2:
          Res = window.Meta.get('ResBoth')
          break
      }
      Res.Tracker.Miss()
      Res.AX.Acc(reakt)
      Shuffle()
    }

    Shuffle()
    window.Meta.get('StartTimer')(window.Meta.get('Time'))

    inst.On('a', (val) => {
      // New
      if (val) {
        // New
        inst.Write('a', null) // New
        if (val == 1337) {
          // New
          Correct() // New
        } else {
          // New
          Wrong() // New
        } // New
      } // New
    }) // New

    window.Meta.KeyDown('x', Wrong) // Remove
    window.Meta.KeyDown('c', Correct) // Remove
  },
  EndGame: async function (meta) {
    const Left = window.Meta.get('ResLeft')
    const Right = window.Meta.get('ResRight')
    const Both = window.Meta.get('ResBoth')

    if (Left.AX.Val > 0 && Right.AX.Val > 0) {
      const scoreData = {
        avg_hit_time_l: Left.AX.Val,
        hits_l: Left.Tracker.Val,
        avg_hit_time_r: Right.AX.Val,
        hits_r: Right.Tracker.Val,
        avg_hit_time_b: Both.AX.Val,
        hits_b: Both.Tracker.Val,
      }

      console.log('AAA')

      window.localStorage.setItem('finishScreen', 'true')

      setTimeout(function () {
        console.log('reload')
        window.location.reload()
      }, 1500)
    }
    window.Meta.Return({
      AvgHitTimeL: Left.AX.Val,
      HitsL: Left.Tracker.Val,
      AvgHitTimeR: Right.AX.Val,
      HitsR: Right.Tracker.Val,
      AvgHitTimeB: Both.AX.Val, //Remove
      HitsB: Both.Tracker.Val, //Remove
    })
  },
})

/**************************************************************/

export const RealtimeClient_Base = {
  Start: function () {
    const inst = window.Meta.get('GameInst')
    inst.On('', (val) => {
      if (val == null) {
        window.Meta.Return(true)
      }
    })
    //whatever you might need it for
  },
  NullResponse: function () {
    var Box = window.Meta.get('RTC_Box')
    if (!Box) {
      const CM = window.Meta.get('ContrastMode')
      const line = CM == 'WoB' ? 'white' : 'black'
      const fill = line == 'white' ? 'black' : 'white'
      const newBox = window.Meta.Rect(700, 350, { fill, line, stroke: 2 })
      newBox.Left = -350
      newBox.Top = -175
      newBox.Hide()

      const BoxText = window.Meta.Text('', { fill: line, size: 50 })
      BoxText.Left = 0
      BoxText.Right = 0
      BoxText.Bot = -20
      newBox.Txt = BoxText
      BoxText.Hide()

      const BackBut = window.Meta.Button('Exit', window.Meta.Return, {
        fill,
        line,
        size: 70,
      })
      BackBut.Width = 200
      BackBut.Height = 100
      BackBut.Left = -100
      BackBut.Top = 20
      newBox.But = BackBut
      BackBut.Hide()

      window.Meta.set('RTC_Box', newBox)
      Box = newBox
    }

    if (window.Meta.get('RTC_Cert')) {
      //~ Box.Show();
      //~ Box.Txt.Show();
      //~ Box.Txt.Text = "Error";
      //~ Box.But.Show();
    } else {
      Box.Show()
      Box.Txt.Show()
      Box.Txt.Text = 'Get Ready!'
    }
  },
  ClearNullBox: function () {
    const Box = window.Meta.get('RTC_Box')
    if (Box) {
      Box.Hide()
      Box.Txt.Hide()
      Box.But.Hide()
    }
    window.Meta.set('RTC_Cert', true) // While not ontological, it's empircal
  },
}

export const AccommMedley_Client = window.Meta.Engine(
  RealtimeClient_Base,
  TumblingEKreator,
  {
    Start: function () {
      const inst = window.Meta.get('GameInst')
      const KreateE = window.Meta.get('KreateE')
      window.Meta.Stage.Background('#000000')

      var state = 'wait'

      inst.On('Color', (col) => {
        if (col) {
          Answer1.Fill = col
          Answer2.Fill = col
          Answer3.Fill = col
          Answer4.Fill = col
          DisplayImage1.Fill = col
          DisplayImage2.Fill = col
          DisplayImage3.Fill = col
          DisplayImage4.Fill = col
          inst.Write('Color', null)
        }
      })

      function ATap() {
        if (state != 'go') return

        inst.Write('a', this.Text == answer ? 1337 : 1)
        state = 'sync'
        inst.Write('Solution', null)
      }

      window.MetaVision.PatientDistance = 40
      const FontSize = window.MetaVision.CmToPix(
        window.MetaVision.ArcToCm(window.MetaVision.VaToArc(20 / 200))
      )

      const BoxDim = Math.min(FontSize * 8, 500)
      const HalfSpace = Math.round(BoxDim * 0.066666)

      const Box1 = window.Meta.Rect(BoxDim, BoxDim, {
        line: 'white',
        stroke: 3,
      })
      Box1.Left = -BoxDim - HalfSpace
      Box1.Top = -BoxDim - HalfSpace

      const Box2 = window.Meta.Rect(BoxDim, BoxDim, {
        line: 'white',
        stroke: 3,
      })
      Box2.Left = HalfSpace
      Box2.Top = -BoxDim - HalfSpace

      const Box3 = window.Meta.Rect(BoxDim, BoxDim, {
        line: 'white',
        stroke: 3,
      })
      Box3.Left = -BoxDim - HalfSpace
      Box3.Top = HalfSpace

      const Box4 = window.Meta.Rect(BoxDim, BoxDim, {
        line: 'white',
        stroke: 3,
      })
      Box4.Left = HalfSpace
      Box4.Top = HalfSpace

      const Answer1 = window.Meta.Text('?', { color: 'white', size: FontSize })
      Answer1.Width = BoxDim
      Answer1.Left = -BoxDim - HalfSpace
      Answer1.Height = BoxDim
      Answer1.Top = -BoxDim - HalfSpace
      Answer1.onclick = ATap

      const Answer2 = window.Meta.Text('?', { color: 'white', size: FontSize })
      Answer2.Width = BoxDim
      Answer2.Left = HalfSpace
      Answer2.Height = BoxDim
      Answer2.Top = -BoxDim - HalfSpace
      Answer2.onclick = ATap

      const Answer3 = window.Meta.Text('?', { color: 'white', size: FontSize })
      Answer3.Width = BoxDim
      Answer3.Left = -BoxDim - HalfSpace
      Answer3.Height = BoxDim
      Answer3.Top = HalfSpace
      Answer3.onclick = ATap

      const Answer4 = window.Meta.Text('?', { color: 'white', size: FontSize })
      Answer4.Width = BoxDim
      Answer4.Left = HalfSpace
      Answer4.Height = BoxDim
      Answer4.Top = HalfSpace
      Answer4.onclick = ATap

      const MathAnswers = [Answer1, Answer2, Answer3, Answer4]

      const DisplayImage1 = KreateE(false)
      DisplayImage1.Size = FontSize
      DisplayImage1.Left = -BoxDim / 2 - HalfSpace - DisplayImage1.Width / 2
      DisplayImage1.Top = -BoxDim / 2 - HalfSpace - DisplayImage1.Width / 2
      DisplayImage1.Fill = 'white'

      const DisplayImage2 = KreateE(false)
      DisplayImage2.Size = FontSize
      DisplayImage2.Left = BoxDim / 2 + HalfSpace - DisplayImage1.Width / 2
      DisplayImage2.Top = -BoxDim / 2 - HalfSpace - DisplayImage1.Width / 2
      DisplayImage2.Fill = 'white'

      const DisplayImage3 = KreateE(false)
      DisplayImage3.Size = FontSize
      DisplayImage3.Top = BoxDim / 2 + HalfSpace - DisplayImage1.Width / 2
      DisplayImage3.Left = -BoxDim / 2 - HalfSpace - DisplayImage1.Width / 2
      DisplayImage3.Fill = 'white'

      const DisplayImage4 = KreateE(false)
      DisplayImage4.Size = FontSize
      DisplayImage4.Top = BoxDim / 2 + HalfSpace - DisplayImage1.Width / 2
      DisplayImage4.Left = BoxDim / 2 + HalfSpace - DisplayImage1.Width / 2
      DisplayImage4.Fill = 'white'

      function ImgClick() {
        if (state != 'go') return

        inst.Write('a', this.DI.Rotation == answer ? 1337 : 1)
        state = 'sync'
        inst.Write('Solution', null)
      }
      Box1.onclick = ImgClick
      Box1.DI = DisplayImage1
      Box2.onclick = ImgClick
      Box2.DI = DisplayImage2
      Box3.onclick = ImgClick
      Box3.DI = DisplayImage3
      Box4.onclick = ImgClick
      Box4.DI = DisplayImage4

      DisplayImage1.Hide()
      DisplayImage2.Hide()
      DisplayImage3.Hide()
      DisplayImage4.Hide()
      const Images = [
        DisplayImage1,
        DisplayImage2,
        DisplayImage3,
        DisplayImage4,
      ]

      var answer = ''
      var Answers = ['', '', '', '']

      inst.On('Solution', (sol) => {
        if (sol != null) {
          answer = sol
          const rdex = [0, 90, 180, 270]
          if (rdex.includes(sol)) {
            Images[0].Rotation = rdex.splice(window.MetaRandom.Dex(4), 1)[0]
            Images[1].Rotation = rdex.splice(window.MetaRandom.Dex(3), 1)[0]
            Images[2].Rotation = rdex.splice(window.MetaRandom.Dex(2), 1)[0]
            Images[3].Rotation = rdex[0]
            Answer1.Hide()
            Answer2.Hide()
            Answer3.Hide()
            Answer4.Hide()
            DisplayImage1.Show()
            DisplayImage2.Show()
            DisplayImage3.Show()
            DisplayImage4.Show()
          } else {
            const wrongs = [0, 0, 0]
            do {
              for (var s = 0; s < 3; s++) {
                const dif = window.MetaRandom.Dex(5) + 1
                wrongs[s] =
                  answer + dif * (window.MetaRandom.Dex(2) == 0 ? 1 : -1)
              }
            } while (
              wrongs[0] == answer ||
              wrongs[1] == answer ||
              wrongs[2] == answer ||
              wrongs[0] == wrongs[1] ||
              wrongs[0] == wrongs[2] ||
              wrongs[1] == wrongs[2]
            )

            const adex = window.MetaRandom.Dex(4)

            for (var s = 0; s < 4; s++) {
              if (s == adex) {
                MathAnswers[s].Text = answer
              } else {
                MathAnswers[s].Text = wrongs.pop()
              }
            }
            Answer1.Show()
            Answer2.Show()
            Answer3.Show()
            Answer4.Show()
            DisplayImage1.Hide()
            DisplayImage2.Hide()
            DisplayImage3.Hide()
            DisplayImage4.Hide()
          }

          state = 'go'
          window.Meta.get('ClearNullBox')()
          window.Meta.Redraw()
        } else {
          if (state == 'go') {
            // Answer1.Text = "?";
            // Answer2.Text = "?";
            // Answer3.Text = "?";
            // Answer4.Text = "?";
          }
          state = 'wait'
          window.Meta.get('NullResponse')()
        }
        window.Meta.Redraw()
      })
    },
  }
)

const UfoDestroyer_FG = window.Meta.Engine(VT_Base, PlayerCursor, LegacyEmbed, {
  LegacyEngine: window.Meta.Combine(
    RDS,
    VT2,
    AX,
    Tracker,
    TrackerS,
    Reakt,
    VectorUtils,
    {
      Load: [
        ['Spaceship', '/img/Spaceship.png'],
        ['TheBullet', '/img/Bullet.png'],
      ],
      Start: function (meta) {
        meta.backgroundColor = 0x000000

        this.Time = window.Meta.get('Time')
        this.VergenceType = window.Meta.get('VergenceType')
        this.SeparationRateD = window.Meta.get('SeparationRateD')
        this.SeparationRateC = window.Meta.get('SeparationRateC')
        this.SeparationRateS = window.Meta.get('SeparationRateS')
        this.SeparationRateI = window.Meta.get('SeparationRateI')
        this.Acuity = window.Meta.get('Acuity')
        this.PixelSize = window.Meta.get('PixelSize')
        this.StereoAcuity = window.Meta.get('StereoAcuity')

        meta.PixiApp.stage.addChild(this.RDS)
        this.RDS.PixelSize = this.PixelSize
        this.RDS.Depth = window.MetaVision.CmToPix(
          window.MetaVision.ArcToCm(this.StereoAcuity)
        )

        window.Meta.get('SetScore')(0)
        window.Meta.get('StartTimer')(this.Time)

        switch (this.VergenceType) {
          case 'Convergence':
            this.TrackerS.mode = 'C'
            this.TrackerS.sepAmnt = [this.SeparationRateC]
            break
          case 'Divergence':
            this.TrackerS.mode = 'D'
            this.TrackerS.sepAmnt = [this.SeparationRateD]
            break
          case 'Jump':
            this.TrackerS.mode = 'J'
            this.TrackerS.sepAmnt = [this.SeparationRateC, this.SeparationRateD]
            break
          case 'Supra':
            this.TrackerS.mode = 'BU'
            this.TrackerS.sepAmnt = [this.SeparationRateS]
            break
          case 'Infra':
            this.TrackerS.mode = 'BD'
            this.TrackerS.sepAmnt = [this.SeparationRateI]
            break
          default:
            break
        }

        const dim = window.MetaVision.CmToPix(
          window.MetaVision.ArcToCm(window.MetaVision.VaToArc(this.Acuity))
        )
        this.DIM = dim

        this.ShipL = new window.PIXI.Sprite(this.Spaceship)
        this.ShipL.width = 75
        this.ShipL.height = 75
        this.ShipL.tint = window.Cali.LeftColorMax
        this.ShipL.blendMode = window.PIXI.BLEND_MODES.ADD
        meta.PixiApp.stage.addChild(this.ShipL)

        this.ShipR = new window.PIXI.Sprite(this.Spaceship)
        this.ShipR.width = 75
        this.ShipR.height = 75
        this.ShipR.tint = window.Cali.RightColorMax
        this.ShipR.blendMode = window.PIXI.BLEND_MODES.ADD
        meta.PixiApp.stage.addChild(this.ShipR)

        this.ShipSpot = 0.0

        this.BulletL = new window.PIXI.Sprite(this.TheBullet)
        this.BulletL.width = 75
        this.BulletL.height = 75
        this.BulletL.tint = window.Cali.LeftColor
        this.BulletL.blendMode = window.PIXI.BLEND_MODES.ADD
        meta.PixiApp.stage.addChild(this.BulletL)

        this.BulletR = new window.PIXI.Sprite(this.TheBullet)
        this.BulletR.width = 75
        this.BulletR.height = 75
        this.BulletR.tint = window.Cali.RightColor
        this.BulletR.blendMode = window.PIXI.BLEND_MODES.ADD
        meta.PixiApp.stage.addChild(this.BulletR)

        this.BulletX = 500
        this.BulletY = -1

        this.UFOS = []
        this.ReseedTime = 0
        this.SpawnUFO(meta)

        const Shot = window.Meta.Sound('/sfx/Shot.wav')
        window.Meta.set('Explosion', window.Meta.Sound('/sfx/Pop.wav'))
        window.Meta.set('Wiff', window.Meta.Sound('/sfx/Whoosh.wav', 0.9))
        const FIRE = () => {
          if (this.BulletY < 0) {
            this.BulletX = (this.ShipL.x + this.ShipR.x) / 2
            this.BulletY = 0
            Shot.Play()
          }
        }

        window.Meta.KeyDown('Up', () => {
          window.Meta.get('UnpauseTimer')()
          FIRE()
        })

        window.Meta.KeyDown('Accept', () => {
          this.TrackerS.Mercy()
          this.RDS.Separation = this.TrackerS.val()
        })
      },
      Tick: function (meta, delta) {
        const dT = delta / 60

        this.ReseedTime += dT
        if (this.ReseedTime > 0.06) {
          this.ReseedTime = 0
          this.RDS.ReSeed()
        }

        const Correct = () => {
          window.Meta.get('UnpauseTimer')()
          this.ScoreUp(150)
          window.Meta.get('SetScore')(this.Score)
          this.AX.Acc(this.Reakt_Mark())
          this.Tracker.Correct()
          this.TrackerS.Correct()
          this.RDS.Separation = this.TrackerS.val()
          window.Meta.get('Explosion').Play()
          this.SpawnUFO(meta)
        }

        const Wrong = () => {
          window.Meta.get('UnpauseTimer')()
          this.ScoreDown(50)
          window.Meta.get('SetScore')(this.Score)
          this.Tracker.Wrong()
          this.TrackerS.Wrong()
          window.Meta.get('Wiff').Play()
          this.RDS.Separation = this.TrackerS.val()
        }

        var ufodex = 0
        while (ufodex < this.UFOS.length) {
          this.UFOS[ufodex]._h += dT / 5
          if (this.UFOS[ufodex]._h > 1) {
            this.UFOS[ufodex].obj.parent.removeChild(this.UFOS[ufodex].obj)
            this.UFOS.splice(ufodex, 1)
            Wrong()
            this.SpawnUFO(meta)
          } else {
            this.UFOS[ufodex].obj.x =
              meta.Screen.width / 2 +
              (this.RDS.RDIM - this.UFOS[ufodex].obj.width) *
                this.UFOS[ufodex]._x
            this.UFOS[ufodex].obj.y = this.RDS.RDIM * this.UFOS[ufodex]._h
            ufodex++
          }
        }

        if (window.Meta.Key('Left')) {
          this.ShipSpot = window.Clamp(this.ShipSpot - 0.75 * dT, -0.5, 0.5)
        }
        if (window.Meta.Key('Right')) {
          this.ShipSpot = window.Clamp(this.ShipSpot + 0.75 * dT, -0.5, 0.5)
        }

        if (this.BulletY >= 0) {
          this.BulletY += dT
          if (this.BulletY > 1) {
            this.BulletY = -1
            Wrong()
          }
        }
        this.DrawFlats(meta)

        for (var u = 0; u < this.UFOS.length; u++) {
          if (
            this.Vector.Distance(
              [this.UFOS[u].obj.x, this.UFOS[u].obj.y],
              [this.BulletX, this.BulletL.y]
            ) <=
            this.DIM + 35
          ) {
            this.UFOS[u].obj.parent.removeChild(this.UFOS[u].obj)
            this.UFOS.splice(u, 1)
            this.BulletY = -1
            Correct()
            break
          }
        }
      },
      Resize: function (meta) {
        this.RDS.RDIM = meta.Screen.height * 0.7
        this.DrawFlats(meta)
      },
      KountdownEnd: function (meta) {
        if (this.Score > 0) {
          const type = {
            Divergence: 'Relax',
            Convergence: 'Flex',
            Jump: 'Stamina',
          }

          const scoreData = {
            score: this.Score,
            hits: this.Tracker.val(),
            avg_hit_time: this.AX.val() || 0,
            break:
              this.TrackerS.mode === 'J'
                ? [this.TrackerS.best[0], this.TrackerS.best[1]]
                : this.TrackerS.best[0],
            recovery:
              this.TrackerS.mode === 'J'
                ? [this.TrackerS.worst[0], this.TrackerS.worst[1]]
                : this.TrackerS.worst[0],
          }
          // Game Score

          // Daily check

          window.localStorage.setItem('finishScreen', 'true')

          setTimeout(function () {
            window.location.reload()
          }, 1500)
        }

        window.Meta.Return({
          Score: this.Score,
          Hits: this.Tracker.val(),
          AvgHitTime: this.AX.val(),
          Break:
            this.TrackerS.mode == 'J'
              ? [this.TrackerS.best[0], this.TrackerS.best[1]]
              : this.TrackerS.best[0],
          Recovery:
            this.TrackerS.mode == 'J'
              ? [this.TrackerS.worst[0], this.TrackerS.worst[1]]
              : this.TrackerS.worst[0],
        })
      },
      SpawnUFO: function (meta) {
        const circle = new window.PIXI.Graphics()
          .beginFill(0xffffff)
          .drawCircle(0, 0, this.DIM / 2)
        circle.y = -100
        circle.x = meta.Screen.width / 2
        this.RDS.addChild(circle)
        this.UFOS.push({
          obj: circle,
          _h: 0,
          _x: window.MetaRandom.Range(-0.5, 0.5),
        })
      },
      DrawFlats: function (meta) {
        const sep = this.TrackerS.val()
        this.ShipL.x =
          meta.Screen.width / 2 +
          (this.RDS.RDIM - this.ShipL.width) * this.ShipSpot -
          this.ShipL.width / 2 -
          sep[0]
        this.ShipL.y = meta.Screen.height / 2 + this.RDS.RDIM / 2 - sep[1]
        this.ShipR.x =
          meta.Screen.width / 2 +
          (this.RDS.RDIM - this.ShipL.width) * this.ShipSpot -
          this.ShipL.width / 2 +
          sep[0]
        this.ShipR.y = meta.Screen.height / 2 + this.RDS.RDIM / 2 + sep[1]

        this.BulletL.x = this.BulletX - sep[0]
        this.BulletL.y =
          meta.Screen.height / 2 +
          this.RDS.RDIM / 2 -
          this.RDS.RDIM * this.BulletY -
          this.BulletL.height -
          sep[1]
        this.BulletR.x = this.BulletX + sep[0]
        this.BulletR.y =
          meta.Screen.height / 2 +
          this.RDS.RDIM / 2 -
          this.RDS.RDIM * this.BulletY -
          this.BulletL.height +
          sep[1]
      },
    }
  ),
})

const UfoSmasher_FG = window.Meta.Engine(VT_Base, LegacyEmbed, {
  LegacyEngine: window.Meta.Combine(
    RDS,
    VT2,
    AX,
    Tracker,
    TrackerS,
    Reakt,
    VectorUtils,
    {
      Load: [['TheMallet', '/img/Mallet.png']],
      Start: function (meta) {
        meta.backgroundColor = 0x000000
        window.Meta.Cursor(0)

        this.Time = window.Meta.get('Time')
        this.VergenceType = window.Meta.get('VergenceType')
        this.SeparationRateD = window.Meta.get('SeparationRateD')
        this.SeparationRateC = window.Meta.get('SeparationRateC')
        this.SeparationRateS = window.Meta.get('SeparationRateS')
        this.SeparationRateI = window.Meta.get('SeparationRateI')
        this.Acuity = window.Meta.get('Acuity')
        this.PixelSize = window.Meta.get('PixelSize')
        this.StereoAcuity = window.Meta.get('StereoAcuity')

        meta.PixiApp.stage.addChild(this.RDS)
        this.RDS.PixelSize = this.PixelSize
        this.RDS.Depth = window.MetaVision.CmToPix(
          window.MetaVision.ArcToCm(this.StereoAcuity)
        )

        window.Meta.get('SetScore')(0)
        window.Meta.get('StartTimer')(this.Time)

        switch (this.VergenceType) {
          case 'Convergence':
            this.TrackerS.mode = 'C'
            this.TrackerS.sepAmnt = [this.SeparationRateC]
            break
          case 'Divergence':
            this.TrackerS.mode = 'D'
            this.TrackerS.sepAmnt = [this.SeparationRateD]
            break
          case 'Jump':
            this.TrackerS.mode = 'J'
            this.TrackerS.sepAmnt = [this.SeparationRateC, this.SeparationRateD]
            break
          case 'Supra':
            this.TrackerS.mode = 'BU'
            this.TrackerS.sepAmnt = [this.SeparationRateS]
            break
          case 'Infra':
            this.TrackerS.mode = 'BD'
            this.TrackerS.sepAmnt = [this.SeparationRateI]
            break
          default:
            console.log('>>> Unknown VergenceType', this.VergenceType)
        }

        const dim = window.MetaVision.CmToPix(
          window.MetaVision.ArcToCm(window.MetaVision.VaToArc(this.Acuity))
        )
        this.UFO = new window.PIXI.Graphics()
          .beginFill(0xffffff)
          .drawCircle(0, 0, dim / 2)
        this.RDS.addChild(this.UFO)

        const Smash = window.Meta.Sound('/sfx/Pop.wav')
        const Whoosh = window.Meta.Sound('/sfx/Whoosh.wav', 0.9)
        this.RDS.interactive = true
        this.RDS.on('pointerdown', () => {
          const Dist = this.Vector.Distance(
            [
              meta.PixiApp.renderer.plugins.interaction.mouse.global.x,
              meta.PixiApp.renderer.plugins.interaction.mouse.global.y,
            ],
            [this.UFO.x, this.UFO.y]
          )

          if (Dist <= dim / 2 + 30) {
            window.Meta.get('UnpauseTimer')()
            this.ScoreUp(100)
            this.Tracker.Correct()
            this.TrackerS.Correct()
            Smash.Play()
          } else {
            window.Meta.get('UnpauseTimer')()
            this.ScoreDown(50)
            this.Tracker.Wrong()
            this.TrackerS.Wrong()
            Whoosh.Play()
          }

          window.Meta.get('SetScore')(this.Score)
          this.AX.Acc(this.Reakt_Mark())
          this.RDS.Separation = this.TrackerS.val()
          this.Shuffle(meta)
        })

        this.MalletL = new window.PIXI.Sprite(this.TheMallet)
        this.MalletL.width = 100
        this.MalletL.height = 100
        this.MalletL.tint = window.Cali.LeftColorMax
        this.MalletL.blendMode = window.PIXI.BLEND_MODES.ADD
        meta.PixiApp.stage.addChild(this.MalletL)

        this.MalletR = new window.PIXI.Sprite(this.TheMallet)
        this.MalletR.width = 100
        this.MalletR.height = 100
        this.MalletR.tint = window.Cali.RightColorMax
        this.MalletR.blendMode = window.PIXI.BLEND_MODES.ADD
        meta.PixiApp.stage.addChild(this.MalletR)

        window.Meta.KeyDown('Accept', () => {
          this.TrackerS.Mercy()
          this.RDS.Separation = this.TrackerS.val()
        })

        this.Shuffle(meta)
      },
      Tick: function (meta, delta) {
        this.DrawFlats(meta)
      },
      Shuffle: function (meta) {
        const MIN = -this.RDS.RDIM / 2 + this.UFO.height / 2
        const MAX = this.RDS.RDIM / 2 - this.UFO.height / 2
        this.UFO.x =
          meta.PixiApp.screen.width / 2 + window.MetaRandom.Range(MIN, MAX)
        this.UFO.y =
          meta.PixiApp.screen.height / 2 + window.MetaRandom.Range(MIN, MAX)

        this.RDS.ReSeed()
      },
      Resize: function (meta) {
        this.RDS.RDIM = meta.Screen.height * 0.7
        this.Shuffle(meta)
        this.DrawFlats(meta)
      },
      KountdownEnd: function (meta) {
        if (this.Score > 0) {
          const type = {
            Divergence: 'Relax',
            Convergence: 'Flex',
            Jump: 'Stamina',
          }

          const scoreData = {
            score: this.Score,
            hits: this.Tracker.val(),
            avg_hit_time: this.AX.val() || 0,
            break:
              this.TrackerS.mode === 'J'
                ? [this.TrackerS.best[0], this.TrackerS.best[1]]
                : this.TrackerS.best[0],
            recovery:
              this.TrackerS.mode === 'J'
                ? [this.TrackerS.worst[0], this.TrackerS.worst[1]]
                : this.TrackerS.worst[0],
          }

          window.localStorage.setItem('finishScreen', 'true')

          setTimeout(function () {
            window.location.reload()
          }, 1500)
        }

        window.Meta.Return({
          Score: this.Score,
          Hits: this.Tracker.val(),
          AvgHitTime: this.AX.val(),
          Break:
            this.TrackerS.mode == 'J'
              ? [this.TrackerS.best[0], this.TrackerS.best[1]]
              : this.TrackerS.best[0],
          Recovery:
            this.TrackerS.mode == 'J'
              ? [this.TrackerS.worst[0], this.TrackerS.worst[1]]
              : this.TrackerS.worst[0],
        })
      },
      DrawFlats: function (meta) {
        const sep = this.TrackerS.val()
        this.MalletL.x =
          meta.PixiApp.renderer.plugins.interaction.mouse.global.x - 30 - sep[0]
        this.MalletL.y =
          meta.PixiApp.renderer.plugins.interaction.mouse.global.y - 30 - sep[1]
        this.MalletR.x =
          meta.PixiApp.renderer.plugins.interaction.mouse.global.x - 30 + sep[0]
        this.MalletR.y =
          meta.PixiApp.renderer.plugins.interaction.mouse.global.y - 30 + sep[1]
      },
    }
  ),
})

const _Game_Library_ = []
// Relax
_Game_Library_['diamonds-relax'] = [
  Diamonds_FG,
  {
    Time: 2,
    VergenceType: 'Divergence',
    Acuity: '20/800',
    StereoAcuity: '800',
    SeparationRateD: 1,
  },
]
_Game_Library_['destroyer-relax'] = [
  UfoDestroyer_FG,
  {
    Time: 2,
    VergenceType: 'Divergence',
    Acuity: '20/800',
    StereoAcuity: '800',
    SeparationRateD: 1,
  },
]
_Game_Library_['astucia-relax'] = [
  UfoSmasher_FG,
  {
    Time: 2,
    VergenceType: 'Divergence',
    Acuity: '20/800',
    StereoAcuity: '800',
    SeparationRateD: 1,
  },
]

// Flex
_Game_Library_['diamonds-flex'] = [
  Diamonds_FG,
  {
    Time: 2,
    VergenceType: 'Convergence',
    Acuity: '20/800',
    StereoAcuity: '800',
    SeparationRateC: 1,
  },
]
_Game_Library_['destroyer-flex'] = [
  UfoDestroyer_FG,
  {
    Time: 2,
    VergenceType: 'Convergence',
    Acuity: '20/800',
    StereoAcuity: '800',
    SeparationRateC: 1,
  },
]
_Game_Library_['astucia-flex'] = [
  UfoSmasher_FG,
  {
    Time: 2,
    VergenceType: 'Convergence',
    Acuity: '20/800',
    StereoAcuity: '800',
    SeparationRateC: 1,
  },
]

// Stamina
_Game_Library_['diamonds-stamina'] = [
  Diamonds_FG,
  {
    Time: 2,
    VergenceType: 'Jump',
    Acuity: '20/600',
    StereoAcuity: '800',
    SeparationRateD: 1,
    SeparationRateC: 1,
  },
]
_Game_Library_['destroyer-stamina'] = [
  UfoDestroyer_FG,
  {
    Time: 2,
    VergenceType: 'Jump',
    Acuity: '20/600',
    StereoAcuity: '800',
    SeparationRateD: 1,
    SeparationRateC: 1,
  },
]
_Game_Library_['astucia-stamina'] = [
  UfoSmasher_FG,
  {
    Time: 2,
    VergenceType: 'Jump',
    Acuity: '20/800',
    StereoAcuity: '800',
    SeparationRateD: 1,
    SeparationRateC: 1,
  },
]

_Game_Library_['chase'] = [
  window.Meta.Engine(VT_Base, PlayerCursor, LegacyEmbed, {
    LegacyEngine: window.Meta.Combine(SmoothTracking, StandardArrow),
  }),
  { Time: 1, SizeMM: 12, Orientation: '8', SpeedPerHour: 5 },
]
_Game_Library_['reflex'] = [
  window.Meta.Engine(VT_Base, PlayerCursor, LegacyEmbed, {
    LegacyEngine: window.Meta.Combine(JumpTracking, StandardArrow),
  }),
  { Time: 1, SizeMM: 12, Orientation: '8' },
]

_Game_Library_['math'] = [AccommMath_FG, { Time: 2 }]
_Game_Library_['symbols'] = [AccommTumbleE_FG, { Time: 2 }]

///ASSESSMENT GAMES///
const Amsler_Engine = window.Meta.Engine(LegacyEmbed, {
  LegacyEngine: {
    Start: function (meta) {
      const curApp = meta.PixiApp
      meta.backgroundColor = 0x000000

      this.AMSLER = new window.PIXI.Container()
      curApp.stage.addChild(this.AMSLER)

      const dim = window.MetaVision.CmToPix(
        window.MetaVision.ArcToCm(22 * 3600)
      )

      if (window.Meta.get('BoardType') == 'Radial') {
        const radSec = dim / 22
        const AmslerBack = new window.PIXI.Graphics()
          .beginFill(0xffffff)
          .drawCircle(dim / 2, dim / 2, dim / 2)
        const AmslerLines = new window.PIXI.Graphics()
        AmslerLines.lineStyle(2, 0x000000, 1)
        const lineCount = 20
        for (var x = 0; x < lineCount; x++) {
          AmslerLines.moveTo(dim / 2, dim / 2)
          AmslerLines.lineTo(
            dim / 2 + Math.sin((x / lineCount) * 2 * Math.PI) * radSec * 10,
            dim / 2 + Math.cos((x / lineCount) * 2 * Math.PI) * radSec * 10
          )
        }
        const AmslerCenter = new window.PIXI.Graphics()
          .beginFill(0x000000)
          .drawCircle(0 + dim / 2, 0 + dim / 2, radSec / 2)
        this.AMSLER.addChild(AmslerBack)
        this.AMSLER.addChild(AmslerLines)
        for (var x = 1; x < 11; x++) {
          this.AMSLER.addChild(
            new window.PIXI.Graphics()
              .lineStyle(2, 0x000000, 1)
              .drawCircle(dim / 2, dim / 2, radSec * x)
          )
        }
        this.AMSLER.addChild(AmslerCenter)
      } else {
        const boxDim = dim / 22
        const AmslerBack = new window.PIXI.Graphics()
          .beginFill(0xffffff)
          .drawRect(0, 0, dim, dim)
        const AmslerLines = new window.PIXI.Graphics()
        AmslerLines.lineStyle(2, 0x000000, 1)
        for (var x = 1; x < 22; x++) {
          AmslerLines.moveTo(0 + boxDim * x, 0 + boxDim)
          AmslerLines.lineTo(0 + boxDim * x, 0 + dim - boxDim)
        }
        for (var y = 1; y < 22; y++) {
          AmslerLines.moveTo(0 + boxDim, 0 + boxDim * y)
          AmslerLines.lineTo(0 + dim - boxDim, 0 + boxDim * y)
        }
        const AmslerCenter = new window.PIXI.Graphics()
          .beginFill(0x000000)
          .drawCircle(0 + dim / 2, 0 + dim / 2, boxDim / 2)
        this.AMSLER.addChild(AmslerBack)
        this.AMSLER.addChild(AmslerLines)
        this.AMSLER.addChild(AmslerCenter)
      }
      this.dim = dim

      const FontSize = 30
      const ButtonBuffer = 6

      this.SubmitButton = new window.PIXI.Graphics()
        .beginFill(0xffffff)
        .drawRect(0, 0, 120, FontSize + ButtonBuffer)
      this.SubmitButton.interactive = true
      this.SubmitButton.buttonMode = true
      this.SubmitButton.on('pointerdown', () => {
        EndGame()
      })
      curApp.stage.addChild(this.SubmitButton)

      const SubmitText = new window.PIXI.Text('Submit', {
        fontFamily: 'Arial',
        fontSize: FontSize,
        fill: 0x000000,
        align: 'center',
      })
      this.SubmitButton.addChild(SubmitText)
      SubmitText.x = 10
      SubmitText.y = ButtonBuffer / 2

      this.ClearButton = new window.PIXI.Graphics()
        .beginFill(0xffffff)
        .drawRect(0, 0, 120, FontSize + ButtonBuffer)

      this.ClearButton.interactive = true
      this.ClearButton.buttonMode = true
      this.ClearButton.on('pointerdown', () => {
        this.Drawing.clear()
      })
      curApp.stage.addChild(this.ClearButton)

      const ClearText = new window.PIXI.Text('Clear', {
        fontFamily: 'Arial',
        fontSize: FontSize,
        fill: 0x000000,
        align: 'center',
      })
      this.ClearButton.addChild(ClearText)
      ClearText.x = 10
      ClearText.y = ButtonBuffer / 2

      //~ this.Blind = new PIXI.Graphics().beginFill(Cali.BlindColor).drawCircle(0, 0, MetaVision.BlindRad());
      //~ curApp.stage.addChild(this.Blind);

      //~ this.Text = new PIXI.Text('Instructions:\n\nSit ' + MetaVision.PatientDistance + 'cm away from the screen.\nCover your ' + (Meta.get("Side") == "Left"?"right":"left") + ' eye and use the\nblue dot to help you.\n\nMaintain your focus on the black\ndot throughout the exercise.\n\nUse the mouse to draw any\nabnormalities in the grid.\n\nStart drawing when ready.\n\nClick SUBMIT when finished.',{fontFamily : 'Arial', fontSize: 24, fill : 0xffffff, align : 'left', "fontWeight":"bold"});
      //~ curApp.stage.addChild(this.Text);

      this.doDraw = false
      this.LastX = 0
      this.LastY = 0

      this.PointDown = () => {
        this.doDraw = true
        this.Drawing.lineStyle(5, 0xff0000, 1)
        this.LastX =
          curApp.renderer.plugins.interaction.mouse.global.x - this.AMSLER.x
        this.LastY =
          curApp.renderer.plugins.interaction.mouse.global.y - this.AMSLER.y
      }
      this.PointUp = () => {
        this.doDraw = false
      }

      const EndGame = () => {
        window.Meta.Return({
          IMG: curApp.renderer.plugins.extract.base64(this.AMSLER),
        })
      }

      this.Drawing = new window.PIXI.Graphics()
      this.AMSLER.addChild(this.Drawing)
    },
    Tick: function (meta) {
      if (this.doDraw) {
        this.Drawing.moveTo(this.LastX, this.LastY)
        this.LastX =
          meta.PixiApp.renderer.plugins.interaction.mouse.global.x -
          this.AMSLER.x
        this.LastY =
          meta.PixiApp.renderer.plugins.interaction.mouse.global.y -
          this.AMSLER.y
        this.Drawing.lineTo(this.LastX, this.LastY)
      }
    },
    Resize: function (meta) {
      const curApp = meta.PixiApp

      //~ if (Meta.get("Side") == "Left"){
      //~ this.Blind.x = curApp.screen.width/2 - MetaVision.CmToPix(MetaVision.ArcToCm(15*3600));
      //~ }else{
      //~ this.Blind.x = curApp.screen.width/2 + MetaVision.CmToPix(MetaVision.ArcToCm(15*3600));
      //~ }
      //~ this.Blind.y = curApp.screen.height/2;

      this.cornerX = curApp.screen.width / 2 - this.dim / 2
      this.cornerY = curApp.screen.height / 2 - this.dim / 2

      this.AMSLER.x = this.cornerX
      this.AMSLER.y = this.cornerY

      const Border = 2
      this.SubmitButton.x = this.cornerX + this.dim + Border
      this.SubmitButton.y =
        this.cornerY + this.dim / 2 - this.SubmitButton.height - Border / 2

      this.ClearButton.x = this.cornerX + this.dim + Border
      this.ClearButton.y = this.cornerY + this.dim / 2 + Border / 2

      //~ this.Text.x = 15;
      //~ this.Text.y = curApp.screen.height/2 - this.Text.height/2;
    },
  },
})

const HVF_Engine = window.Meta.Engine(LegacyEmbed, {
  LegacyEngine: {
    Start: function (meta) {
      const curApp = meta.PixiApp
      meta.backgroundColor = 0x000000

      this.WidthSave = 0
      this.HeightSave = 0

      const goldmann =
        window.MetaVision.CmToPix(window.MetaVision.ArcToCm(1800)) / 2
      var R = window.Meta.get('TrueSide') == 'Right'

      this.focus = new window.PIXI.Graphics()
        .beginFill(window.Cali.FocusColor)
        .drawCircle(0, 0, goldmann)
      this.focus.x = curApp.screen.width / 2
      this.focus.y = curApp.screen.height / 2
      curApp.stage.addChild(this.focus)

      this.blind = new window.PIXI.Graphics()
        .beginFill(window.Cali.BlindColor)
        .drawCircle(0, 0, window.MetaVision.BlindRad())
      this.blind.x =
        this.focus.x +
        window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15 * 3600)) *
          (R == true ? 1 : -1)
      this.blind.y = this.focus.y
      curApp.stage.addChild(this.blind)

      this.stim = new window.PIXI.Graphics()
        .beginFill(0xffffff)
        .drawCircle(0, 0, goldmann)
      this.stim.x = -goldmann
      this.stim.y = -goldmann
      curApp.stage.addChild(this.stim)

      const TestPoints = []
      var stimdex = -1

      function AddTestPoint(degX, degY) {
        TestPoints.push({
          x: degX,
          y: degY,
          tested: false,
          saw: false,
          lastSkip: -1,
        })
      }

      var FalseHits = 0
      var FalseShows = 0
      var BadHits = 0

      const NextStim = () => {
        this.stim.x = -goldmann
        this.stim.y = -goldmann
        task = 1
        timer = 0
        delay = window.Random(0.7, 1.1)
      }

      const MapGen = (mode) => {
        TestPoints.length = 0
        if (mode == 0) {
          //test
          AddTestPoint(-3, -3)
          AddTestPoint(3, -3)
          AddTestPoint(-3, 3)
          AddTestPoint(3, 3)
        } else if (mode == 1) {
          //large field test
          AddTestPoint(-3, -3)
          AddTestPoint(3, -3)
          AddTestPoint(-3, 3)
          AddTestPoint(3, 3)
          AddTestPoint(-27, -27)
          AddTestPoint(27, -27)
          AddTestPoint(-27, 27)
          AddTestPoint(27, 27)
        } else if (mode == 10) {
          //row 1
          AddTestPoint(-1, 9)
          AddTestPoint(1, 9)
          //row 2
          AddTestPoint(-5, 7)
          AddTestPoint(-3, 7)
          AddTestPoint(-1, 7)
          AddTestPoint(1, 7)
          AddTestPoint(3, 7)
          AddTestPoint(5, 7)
          //row 3
          AddTestPoint(-7, 5)
          AddTestPoint(-5, 5)
          AddTestPoint(-3, 5)
          AddTestPoint(-1, 5)
          AddTestPoint(1, 5)
          AddTestPoint(3, 5)
          AddTestPoint(5, 5)
          AddTestPoint(7, 5)
          //row 4
          AddTestPoint(-7, 3)
          AddTestPoint(-5, 3)
          AddTestPoint(-3, 3)
          AddTestPoint(-1, 3)
          AddTestPoint(1, 3)
          AddTestPoint(3, 3)
          AddTestPoint(5, 3)
          AddTestPoint(7, 3)
          //row 5
          AddTestPoint(-9, 1)
          AddTestPoint(-7, 1)
          AddTestPoint(-5, 1)
          AddTestPoint(-3, 1)
          AddTestPoint(-1, 1)
          AddTestPoint(1, 1)
          AddTestPoint(3, 1)
          AddTestPoint(5, 1)
          AddTestPoint(7, 1)
          AddTestPoint(9, 1)
          //row 6
          AddTestPoint(-9, -1)
          AddTestPoint(-7, -1)
          AddTestPoint(-5, -1)
          AddTestPoint(-3, -1)
          AddTestPoint(-1, -1)
          AddTestPoint(1, -1)
          AddTestPoint(3, -1)
          AddTestPoint(5, -1)
          AddTestPoint(7, -1)
          AddTestPoint(9, -1)
          //row 7
          AddTestPoint(-7, -3)
          AddTestPoint(-5, -3)
          AddTestPoint(-3, -3)
          AddTestPoint(-1, -3)
          AddTestPoint(1, -3)
          AddTestPoint(3, -3)
          AddTestPoint(5, -3)
          AddTestPoint(7, -3)
          //row 8
          AddTestPoint(-7, -5)
          AddTestPoint(-5, -5)
          AddTestPoint(-3, -5)
          AddTestPoint(-1, -5)
          AddTestPoint(1, -5)
          AddTestPoint(3, -5)
          AddTestPoint(5, -5)
          AddTestPoint(7, -5)
          //row 9
          AddTestPoint(-5, -7)
          AddTestPoint(-3, -7)
          AddTestPoint(-1, -7)
          AddTestPoint(1, -7)
          AddTestPoint(3, -7)
          AddTestPoint(5, -7)
          //row 10
          AddTestPoint(-1, -9)
          AddTestPoint(1, -9)
        } else if (mode == 24) {
          //24-2
          //row 1
          AddTestPoint(-9, 21)
          AddTestPoint(-3, 21)
          AddTestPoint(3, 21)
          AddTestPoint(9, 21)
          //row 2
          AddTestPoint(-15, 15)
          AddTestPoint(-9, 15)
          AddTestPoint(-3, 15)
          AddTestPoint(3, 15)
          AddTestPoint(9, 15)
          AddTestPoint(15, 15)
          //row 3
          AddTestPoint(-21, 9)
          AddTestPoint(-15, 9)
          AddTestPoint(-9, 9)
          AddTestPoint(-3, 9)
          AddTestPoint(3, 9)
          AddTestPoint(9, 9)
          AddTestPoint(15, 9)
          AddTestPoint(21, 9)
          //row 4
          if (R) AddTestPoint(-27, 3)
          AddTestPoint(-21, 3)
          AddTestPoint(-15, 3)
          AddTestPoint(-9, 3)
          AddTestPoint(-3, 3)
          AddTestPoint(3, 3)
          AddTestPoint(9, 3)
          AddTestPoint(15, 3)
          AddTestPoint(21, 3)
          if (!R) AddTestPoint(27, 3)
          //row 5
          if (R) AddTestPoint(-27, -3)
          AddTestPoint(-21, -3)
          AddTestPoint(-15, -3)
          AddTestPoint(-9, -3)
          AddTestPoint(-3, -3)
          AddTestPoint(3, -3)
          AddTestPoint(9, -3)
          AddTestPoint(15, -3)
          AddTestPoint(21, -3)
          if (!R) AddTestPoint(27, -3)
          //row 6
          AddTestPoint(-21, -9)
          AddTestPoint(-15, -9)
          AddTestPoint(-9, -9)
          AddTestPoint(-3, -9)
          AddTestPoint(3, -9)
          AddTestPoint(9, -9)
          AddTestPoint(15, -9)
          AddTestPoint(21, -9)
          //row 7
          AddTestPoint(-15, -15)
          AddTestPoint(-9, -15)
          AddTestPoint(-3, -15)
          AddTestPoint(3, -15)
          AddTestPoint(9, -15)
          AddTestPoint(15, -15)
          //row 8
          AddTestPoint(-9, -21)
          AddTestPoint(-3, -21)
          AddTestPoint(3, -21)
          AddTestPoint(9, -21)
        } else if (mode == 30) {
          //30-2
          //row 1
          AddTestPoint(-9, 27)
          AddTestPoint(-3, 27)
          AddTestPoint(3, 27)
          AddTestPoint(9, 27)
          //row 2
          AddTestPoint(-15, 21)
          AddTestPoint(-9, 21)
          AddTestPoint(-3, 21)
          AddTestPoint(3, 21)
          AddTestPoint(9, 21)
          AddTestPoint(15, 21)
          //row 3
          AddTestPoint(-21, 15)
          AddTestPoint(-15, 15)
          AddTestPoint(-9, 15)
          AddTestPoint(-3, 15)
          AddTestPoint(3, 15)
          AddTestPoint(9, 15)
          AddTestPoint(15, 15)
          AddTestPoint(21, 15)
          //row 4
          AddTestPoint(-27, 9)
          AddTestPoint(-21, 9)
          AddTestPoint(-15, 9)
          AddTestPoint(-9, 9)
          AddTestPoint(-3, 9)
          AddTestPoint(3, 9)
          AddTestPoint(9, 9)
          AddTestPoint(15, 9)
          AddTestPoint(21, 9)
          AddTestPoint(27, 9)
          //row 5
          AddTestPoint(-27, 3)
          AddTestPoint(-21, 3)
          AddTestPoint(-15, 3)
          AddTestPoint(-9, 3)
          AddTestPoint(-3, 3)
          AddTestPoint(3, 3)
          AddTestPoint(9, 3)
          AddTestPoint(15, 3)
          AddTestPoint(21, 3)
          AddTestPoint(27, 3)
          //row 6
          AddTestPoint(-27, -3)
          AddTestPoint(-21, -3)
          AddTestPoint(-15, -3)
          AddTestPoint(-9, -3)
          AddTestPoint(-3, -3)
          AddTestPoint(3, -3)
          AddTestPoint(9, -3)
          AddTestPoint(15, -3)
          AddTestPoint(21, -3)
          AddTestPoint(27, -3)
          //row 7
          AddTestPoint(-27, -9)
          AddTestPoint(-21, -9)
          AddTestPoint(-15, -9)
          AddTestPoint(-9, -9)
          AddTestPoint(-3, -9)
          AddTestPoint(3, -9)
          AddTestPoint(9, -9)
          AddTestPoint(15, -9)
          AddTestPoint(21, -9)
          AddTestPoint(27, -9)
          //row 8
          AddTestPoint(-21, -15)
          AddTestPoint(-15, -15)
          AddTestPoint(-9, -15)
          AddTestPoint(-3, -15)
          AddTestPoint(3, -15)
          AddTestPoint(9, -15)
          AddTestPoint(15, -15)
          AddTestPoint(21, -15)
          //row 9
          AddTestPoint(-15, -21)
          AddTestPoint(-9, -21)
          AddTestPoint(-3, -21)
          AddTestPoint(3, -21)
          AddTestPoint(9, -21)
          AddTestPoint(15, -21)
          //row 10
          AddTestPoint(-9, -27)
          AddTestPoint(-3, -27)
          AddTestPoint(3, -27)
          AddTestPoint(9, -27)
        }
        testedCount = 0
        FalseHits = 0
        FalseShows = 0
        BadHits = 0
      }

      var MapType = 24
      var GridRad = 24

      if (window.Meta.get('Layout') == '10-2') {
        MapType = 10
        GridRad = 10
      } else if (window.Meta.get('Layout') == '24-2') {
        MapType = 24
        GridRad = 24
      } else if (window.Meta.get('Layout') == '30-2') {
        MapType = 30
        GridRad = 30
      }

      MapGen(MapType)

      var testedCount = 0

      //task legend
      //0 - waiting to start
      //1 - stimulus delay
      //2 - showing stimulus
      //3 - showing blind spot
      //4 - showing result
      //5 - moving focus
      window.Meta.KeyDown('Accept', () => {
        if (task == 0) {
          meta.Cursor(0)
          this.BlindX = this.blind.x
          this.BlindY = this.blind.y
          this.blind.x = -this.blind.width
          this.blind.y = -this.blind.height
          NextStim()
        } else if (task == 1) {
          BadHits++
        } else if (task == 2 || task == 3) {
          if (task == 2) {
            TestPoints[stimdex].saw = true
          } else {
            FalseHits++
          }
          if (testedCount < TestPoints.length) {
            NextStim()
          } else {
            //DrawResults();
          }
        } else if (task == 4) {
          if (R) {
            //~ LogHits("Right");
            document.exitFullscreen()
          } else {
            //~ LogHits("Left");
            R = true
            this.blind.x =
              this.focus.x +
              window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15 * 3600))
            this.blind.y = this.focus.y
            task = 0
            this.stim.x = this.blind.x
            this.stim.y = this.blind.y
            MapGen(MapType)
            curApp.stage.removeChild(Results)
            Results = null
            cornerState = -1
          }
        }
      })

      var Results = null

      const DrawResults = () => {
        const GridSize = 20
        Results = new window.PIXI.Graphics()
          .beginFill(0xffffff)
          .drawRect(0, 0, GridSize * (GridRad + 3) * 2, GridSize * GridRad * 2)
        Results.x = this.focus.x - GridSize * GridRad
        Results.y = this.focus.y - GridSize * GridRad
        curApp.stage.addChild(Results)

        const Axis = new window.PIXI.Graphics().lineStyle(4, 0x000000, 1)
        Axis.moveTo(GridSize * GridRad, 0)
        Axis.lineTo(GridSize * GridRad, GridSize * GridRad * 2)
        Axis.moveTo(0, GridSize * GridRad)
        Axis.lineTo(GridSize * GridRad * 2, GridSize * GridRad)

        /*
				//Grid
				Axis.lineStyle(2, 0x000000, 1);
				for (var x = 0; x < GridRad*2+1; x++){
					Axis.moveTo(GridSize*x ,0);
					Axis.lineTo(GridSize*x , GridSize*GridRad*2);
				}
				for (var y = 0; y < GridRad*2+1; y++){
					Axis.moveTo(0, GridSize*y);
					Axis.lineTo(GridSize*GridRad*2, GridSize*y);
				}
				*/
        Results.addChild(Axis)

        const DarkSpots = new window.PIXI.Graphics()
        //console.log(TestPoints.length);
        TestPoints.forEach((e) => {
          if (e.tested == false) {
            const qm = new window.PIXI.Text('?', {
              fontFamily: 'Arial',
              fontSize: GridSize * 2,
              fill: 0xff0000,
              align: 'center',
            })
            qm.x = GridSize * (GridRad - 1 + e.x) + GridSize / 2
            qm.y = GridSize * (GridRad - 1 + e.y)
            DarkSpots.addChild(qm)
            //DarkSpots.beginFill(0xff0000).drawRect(GridSize * (GridRad - 1 + e.x), GridSize * (GridRad - 1 + e.y), GridSize*2, GridSize*2);
          } else if (e.saw == false) {
            DarkSpots.beginFill(0x000000).drawRect(
              GridSize * (GridRad - 1 + e.x),
              GridSize * (GridRad - 1 + e.y),
              GridSize * 1.8,
              GridSize * 1.8
            )
          } else {
            DarkSpots.beginFill(0xcccccc).drawRect(
              GridSize * (GridRad - 1 + e.x) + GridSize / 2,
              GridSize * (GridRad - 1 + e.y) + GridSize / 2,
              GridSize,
              GridSize
            )
          }
        })

        if (GridRad > 15) {
          if (R) {
            DarkSpots.beginFill(0x0334a2).drawCircle(
              GridSize * (GridRad - 1 + 16),
              GridSize * GridRad,
              GridSize
            )
          } else {
            DarkSpots.beginFill(0x0334a2).drawCircle(
              GridSize * (GridRad - 1 - 14),
              GridSize * GridRad,
              GridSize
            )
          }
        }
        Results.addChild(DarkSpots)

        curApp.stage.addChild(
          new window.PIXI.Graphics()
            .beginFill(0x000000)
            .drawRect(0, 0, curApp.screen.width, curApp.screen.height)
        )

        Meta.Return({
          IMG: curApp.renderer.plugins.extract.base64(Results),
          FalsePositives: BadHits,
          FixationLoss: [FalseHits, FalseShows],
        })
      }

      const LogHits = (dir) => {
        //~ Log(dir + " \nFalse Positives: " + BadHits + '\n' + "Fixation Loss: " + FalseHits + "/" + FalseShows);
      }

      var task = 0
      var timer = 0
      var delay = 0
      const showTime = 1.5

      var missedDots = 0
      //0 = NE
      // 1 = SE
      // 2 = SW
      // 3 = NW
      var cornerState = -1
      this.BlindX = 0
      this.BlindY = 0

      meta.TempTicker((delta) => {
        if (task == 1) {
          timer += delta / 60
          if (timer >= delay) {
            if (this.BlindX > 0 && Randex(15) == 0) {
              this.stim.x = this.BlindX
              this.stim.y = this.BlindY
              task = 3
              FalseShows++
              timer = 0
            } else {
              if (
                missedDots > 0 &&
                missedDots + testedCount >= TestPoints.length
              ) {
                //cornerState++;
                cornerState = 4
                task = 5
                timer = 0
                missedDots = 0
                //INST = new PIXI.Sprite.from("inst/VisualField2.jpg");
                //INST.anchor.set(0.5);
                //INST.x = curApp.screen.width/2;
                //INST.y = curApp.screen.height/2;
                //curApp.stage.addChild(INST);
              } else {
                stimdex = Randex(TestPoints.length)
                while (
                  TestPoints[stimdex].tested ||
                  TestPoints[stimdex].lastSkip > cornerState
                ) {
                  stimdex = (stimdex + 1) % TestPoints.length
                }
                this.stim.x =
                  this.focus.x +
                  window.MetaVision.CmToPix(
                    window.MetaVision.ArcToCm(TestPoints[stimdex].x * 3600)
                  )
                this.stim.y =
                  this.focus.y +
                  window.MetaVision.CmToPix(
                    window.MetaVision.ArcToCm(TestPoints[stimdex].y * 3600)
                  )
                if (
                  this.stim.x + goldmann > curApp.screen.width ||
                  this.stim.y + goldmann > curApp.screen.height ||
                  this.stim.x - goldmann < 0 ||
                  this.stim.y - goldmann < 0
                ) {
                  //if (0==0){
                  missedDots++
                  TestPoints[stimdex].lastSkip = cornerState + 1
                } else {
                  TestPoints[stimdex].tested = true
                  testedCount++
                  task = 2
                  timer = 0
                }
              }
            }
          }
        } else if (task == 2 || task == 3) {
          timer += delta / 60
          if (timer >= showTime) {
            if (testedCount < TestPoints.length) {
              NextStim()
            } else {
              DrawResults()
            }
          }
        } else if (task == 5) {
          var showBlindRight = false
          if (cornerState == 0) {
            this.focus.x = curApp.screen.width - goldmann
            this.focus.y = goldmann
          } else if (cornerState == 1) {
            this.focus.x = curApp.screen.width - goldmann
            this.focus.y = curApp.screen.height - goldmann
          } else if (cornerState == 2) {
            this.focus.x = goldmann
            this.focus.y = curApp.screen.height - goldmann
            showBlindRight = true
          } else if (cornerState == 3) {
            this.focus.x = goldmann
            this.focus.y = goldmann
            showBlindRight = true
          } else if (cornerState == 4) {
            this.focus.x = curApp.screen.width / 2
            this.focus.y = curApp.screen.height / 2
            DrawResults()
            return
          }
          if (R == showBlindRight) {
            if (R) {
              this.blind.x =
                this.focus.x +
                window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15 * 3600))
              this.blind.y = this.focus.y
            } else {
              this.blind.x =
                this.focus.x -
                window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15 * 3600))
              this.blind.y = this.focus.y
            }
          } else {
            this.blind.x = -this.blind.width
            this.blind.y = -this.blind.height
          }
          this.stim.x = this.blind.x
          this.stim.y = this.blind.y
          task = 0
          timer = 0
        }
      })

      window.Meta.KeyDown('Left', () => {
        DrawResults()
      })
    },
    Resize: function (meta) {
      if (this.WidthSave > 0 && this.HeightSave > 0) {
        //this.focus
        const FWP = this.focus.x / this.WidthSave
        const FHP = this.focus.y / this.HeightSave
        this.focus.x = FWP * meta.Screen.width
        this.focus.y = FHP * meta.Screen.height
        //this.blind
        const BWP = this.blind.x / this.WidthSave
        const BHP = this.blind.y / this.HeightSave
        this.blind.x = BWP * meta.Screen.width
        this.blind.y = BHP * meta.Screen.height
        this.BlindX = this.blind.x
        this.BlindY = this.blind.y
        //this.stim
        const SWP = this.stim.x / this.WidthSave
        const SHP = this.stim.y / this.HeightSave
        this.stim.x = SWP * meta.Screen.width
        this.stim.y = SHP * meta.Screen.height
      }

      this.WidthSave = meta.Screen.width
      this.HeightSave = meta.Screen.height
    },
  },
})

function TumblingE_Factory(c = true) {
  const E = window.Meta.Path(0, 0, { fill: '#000000' })
  E.Crowd = c ? true : false

  Object.defineProperty(E, 'Size', {
    get: function () {
      return this._trueSize
    },
    set: function (s) {
      this._trueSize = s

      this.CLEAR()

      if (s > 0) {
        if (s < 5) {
          this.fits = false
          this.MOVE(0, 0)
          this.LINE(0, 5)
          this.LINE(5, 5)
          this.LINE(5, 0)
          this.LINE(0, 0)
          this.Width = 5
          this.Height = 5
        } else {
          this.fits = true

          const fifth = Math.floor(s / 5)
          const half = fifth * 2
          const bar = fifth * 3
          const subwhole = fifth * 4
          const whole = fifth * 5
          const fudge = Math.round(fifth / 2)

          if (this.Crowd) {
            this.MOVE(subwhole, 0)
            this.LINE(subwhole + bar, 0)
            this.LINE(subwhole + bar, fifth)
            this.LINE(subwhole, fifth)
            this.LINE(subwhole, 0)

            this.MOVE(0, subwhole)
            this.LINE(fifth, subwhole)
            this.LINE(fifth, subwhole + bar)
            this.LINE(0, subwhole + bar)
            this.LINE(0, subwhole)

            const Shift10 = whole + whole

            this.MOVE(subwhole, Shift10)
            this.LINE(subwhole + bar, Shift10)
            this.LINE(subwhole + bar, Shift10 + fifth)
            this.LINE(subwhole, Shift10 + fifth)
            this.LINE(subwhole, Shift10)

            this.MOVE(Shift10, subwhole)
            this.LINE(Shift10 + fifth, subwhole)
            this.LINE(Shift10 + fifth, bar + subwhole)
            this.LINE(Shift10, bar + subwhole)
            this.LINE(Shift10, subwhole)

            this.MOVE(bar, bar)
            this.LINE(fifth + bar, bar)
            this.LINE(fifth + bar, subwhole + bar)
            this.LINE(half + bar, subwhole + bar)
            this.LINE(half + bar, bar)
            this.LINE(bar + bar, bar)
            this.LINE(bar + bar, subwhole + bar)
            this.LINE(subwhole + bar, subwhole + bar)
            this.LINE(subwhole + bar, bar)
            this.LINE(whole + bar, bar)
            this.LINE(whole + bar, whole + bar)
            this.LINE(bar, whole + bar)
            this.LINE(bar, bar)

            this.Width = 2 * whole + fifth
            this.Height = 2 * whole + fifth
          } else {
            this.MOVE(0, 0)
            this.LINE(fifth, 0)
            this.LINE(fifth, subwhole)
            this.LINE(half, subwhole)
            this.LINE(half, 0)
            this.LINE(bar, 0)
            this.LINE(bar, subwhole)
            this.LINE(subwhole, subwhole)
            this.LINE(subwhole, 0)
            this.LINE(whole, 0)
            this.LINE(whole, whole)
            this.LINE(0, whole)
            this.LINE(0, 0)

            this.Width = whole
            this.Height = whole
          }
        }
        this.DRAW()
      }
    },
  })

  Object.defineProperty(E, 'Answer', {
    get: function () {
      return this._A
    },
    set: function (d) {
      this._A = d
      this.Rotation = 90 * d
    },
  })

  return E
}

const VABase_EngineN = {
  Start: function () {
    console.log(window.Meta.get("TrueDistance"))
    window.Meta.Stage.Background('#ffffff')

    const DebugText = window.Meta.Text('', { fill: '#000000', size: 24 })
    DebugText.Left = 0
    DebugText.Right = 0

    const E = TumblingE_Factory(window.Meta.get('Mode') == 'Crowded')

    var adex = 0
    const Acuities =
      window.Meta.get('TestType') == 'Intermediate'
        ? [800, 400, 200, 100, 80, 60, 40, 20]
        : [800, 400, 200, 100, 70, 50, 40, 32, 25, 20, 15]

    const Shuffle = () => {
      const size = window.MetaVision.CmToPix(
        window.MetaVision.ArcToCm(
          window.window.MetaVision.VaToArc(20 / Acuities[adex])
        )
      )
      E.Answer = window.MetaRandom.Compass()
      E.Size = size
      E.Left = -Math.round(E.Width / 2)
      E.Top = -Math.round(E.Height / 2)
      E.Fill = 0x000000
      window.Meta.Redraw()
      DebugText.Text = '20/' + Acuities[adex]
    }
    Shuffle()

    window.Meta.KeyDown('Left', () => {
      CheckAnswer(3)
    })
    window.Meta.KeyDown('Right', () => {
      CheckAnswer(1)
    })
    window.Meta.KeyDown('Up', () => {
      CheckAnswer(0)
    })
    window.Meta.KeyDown('Down', () => {
      CheckAnswer(2)
    })

    var LastSize = 0
    var LastAcuity = 0
    var edex = 0
    var cors = 0
    var rows = 0
    var totcors = 0
    var lastcors = 0
    var ThinIce = 0

    const AdvanceRow = () => {
      if (adex + 1 >= Acuities.length) {
        if (ThinIce) {
          BestAcuity = Acuities[adex]
          EndGame()
        } else {
          ThinIce = 2
          edex = 1
          cors = 1
          Shuffle()
        }
        return
      }

      BestAcuity = Acuities[adex]
      adex++
      Shuffle()
      Warning = Math.min(Warning, BestAcuity)

      if (!E.fits) {
        ShowWarning = true
        if (ThinIce) {
          EndGame()
        } else {
          ThinIce = 2
          adex--
          edex = 1
          cors = 1
          Shuffle()
        }
      } else if (
        Acuities[adex] != LastAcuity &&
        LastSize == E.Height &&
        edex == 0
      ) {
        AdvanceRow()
      } else {
        LastSize = E.Height
        LastAcuity = Acuities[adex]
      }
    }

    const RetreatRow = () => {
      adex--
      if (adex < 0) {
        if (ThinIce) {
          BestAcuity = '??'
          cors = 0 //prevents plus
          EndGame()
        } else {
          adex = 0
          edex = 1
          Shuffle()
          ThinIce = 2
        }
        return
      }

      if (ThinIce >= 0) ThinIce = 2
      BestAcuity = Acuities[adex]

      edex = 0
      cors = 0

      Shuffle()

      if (LastSize == E.Height) {
        RetreatRow()
      } else {
        LastSize = E.Height
        LastAcuity = Acuities[adex]
      }
    }

    var InputLock = false
    var DisplayTime = 500
    const CheckAnswer = (a) => {
      if (InputLock) return
      InputLock = true
      if (E.Answer == a) {
        E.Fill = '#00cc00'
        window.Meta.Raincheck(DisplayTime, Correct)
      } else {
        E.Fill = '#cc0000'
        window.Meta.Raincheck(DisplayTime, Wrong)
      }
    }

    const Correct = () => {
      if (ThinIce) {
        edex++
        cors++
        if (edex >= 5) {
          CheckRow()
        } else {
          Shuffle()
        }
      } else {
        AdvanceRow()
      }
      InputLock = false
    }

    var MercyPoints = 2
    const Wrong = () => {
      if (ThinIce == 0 && adex == 0) {
        if (MercyPoints > 0) {
          MercyPoints--
          Shuffle()
          InputLock = false
          return
        } else {
          lastcors = 2 //makes it say -3
          EndGame()
          return
        }
      }

      if (ThinIce) {
        edex++
        if (edex >= 5) {
          CheckRow()
        } else {
          Shuffle()
        }
      } else {
        RetreatRow()
      }
      InputLock = false
    }

    const CheckRow = () => {
      if (cors >= 3) {
        rows++
        totcors += cors

        if (ThinIce < 0) {
          const pluscors = lastcors
          lastcors = cors
          cors = pluscors
          EndGame()
        } else {
          lastcors = cors
          edex = 0
          cors = 0
          ThinIce = 3
          AdvanceRow()
        }
      } else {
        if (ThinIce > 2) {
          EndGame()
        } else {
          lastcors = cors
          if (ThinIce == 2) ThinIce = -2
          RetreatRow()
        }
      }
    }

    var BestAcuity = '??'
    var Warning = 9000
    var ShowWarning = false

    const EndGame = () => {
      const last = lastcors - 5
      const Acuity =
        BestAcuity +
        (last < 0 ? ' -' + Math.abs(last) : '') +
        (cors > 0 ? ' +' + cors : '')
      if (ShowWarning) {
        window.Meta.Return({
          Acuity,
          Warning: Warning,
          Hits: [totcors, 5 * rows - totcors],
        })
      } else {
        window.Meta.Return({
          Acuity,
          Hits: [totcors, 5 * rows - totcors],
        })
      }
    }
    //~ Meta.KeyDown("Accept", ()=>{
    //~ if (ThinIce == 0 && adex == 0){
    //~ lastcors = 2; //makes it say -3
    //~ }else{
    //~ const pluscors = lastcors;
    //~ lastcors = cors;
    //~ cors = pluscors;
    //~ }
    //~ EndGame();
    //~ });
  },
}

const CS_EngineN = {
  Start: function () {
    window.Meta.Stage.Background('#ffffff')

    var cdex = 0
    //const Contrasts = [0, 0.291, 0.5, 0.64, 0.749, 0.822, 0.874, 0.911, 0.937, 0.955, 0.968, 0.978, 0.984, 0.989, 0.992, 0.994];
    const Contrasts = [
      '00',
      '4A',
      '7F',
      'A3',
      'BF',
      'D2',
      'DF',
      'E8',
      'EF',
      'F4',
      'F7',
      'F9',
      'FA',
      'FC',
      'FD',
      'FE',
    ]

    const E = TumblingE_Factory(window.Meta.get('Mode') == 'Crowded')
    const size = window.MetaVision.CmToPix(
      window.MetaVision.ArcToCm(window.window.MetaVision.VaToArc(20 / 1600))
    )
    E.Fill = '#000000'
    E.Size = size
    E.Left = -Math.round(E.Width / 2)
    E.Top = -Math.round(E.Height / 2)

    const Shuffle = () => {
      E.Answer = window.MetaRandom.Compass()
      E.Fill =
        '#' +
        Contrasts[Math.floor(cdex / 2)] +
        Contrasts[Math.floor(cdex / 2)] +
        Contrasts[Math.floor(cdex / 2)]
    }

    Shuffle()

    window.Meta.KeyDown('Up', () => {
      if (E.Answer == 0) {
        Correct()
      } else {
        Wrong()
      }
    })

    window.Meta.KeyDown('Down', () => {
      if (E.Answer == 2) {
        Correct()
      } else {
        Wrong()
      }
    })

    window.Meta.KeyDown('Left', () => {
      if (E.Answer == 3) {
        Correct()
      } else {
        Wrong()
      }
    })

    window.Meta.KeyDown('Right', () => {
      if (E.Answer == 1) {
        Correct()
      } else {
        Wrong()
      }
    })

    var SecondChance = 0

    const Correct = () => {
      if (SecondChance > 0) {
        Shuffle()
        SecondChance--
      } else {
        if (cdex < 32 - 1) {
          cdex++
          Shuffle()
        } else {
          cdex++
          EndGame()
        }
      }
    }

    const Wrong = () => {
      if (SecondChance > 0) {
        EndGame()
      } else {
        SecondChance = 1 //Two in a row needed
        Shuffle()
      }
    }

    const EndGame = () => {
      window.Meta.Return({
        Contrast: Math.floor(cdex / 2),
      })
    }
  },
}

//Visual Acuity
_Game_Library_['VisAcuityNear'] = [
  VABase_EngineN,
  { TestType: 'Near', Side: 'Binocular', Mode: 'Crowded' },
]
_Game_Library_['VisAcuityFar'] = [
  VABase_EngineN,
  {
    TestType: 'Intermediate',
    Side: 'Binocular',
    Mode: 'Crowded',
    TrueDistance: '20/20',
  },
]
//Amler Grid
_Game_Library_['AmslerGrid'] = [
  Amsler_Engine,
  { BoardType: 'Square', Side: 'Monocular' },
]
//Visual Field
_Game_Library_['VisualField'] = [
  HVF_Engine,
  { Side: 'Monocular', Layout: '24-2' },
]
//Contrast Sensitivity
_Game_Library_['Contrast'] = [CS_EngineN, { Side: 'Binocular' }]
