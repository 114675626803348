import React from "react";
import homevvs from "../../Images/home-vvs.png";
import { Button, Grid, Stack } from "@mui/material";
import reading from "../../Images/desk.png";
import Logo from "../logo/Logo";
import FixedHeading from "../FixedHeading";

function Step13({ handleNext, handlePrev }) {
  return (
    <Grid container spacing="20px" sx={{height: "100%", maxHeight: "680px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item sx={{ width: "100%"}}>
              <p className="text-[#7C6FF7] lg:text-[30px] text-center pb-5   text-[25px] xl:text-[40px] font-[800]   font-Montserrat">
                We’re going to measure your reading acuity.
              </p>
          </Grid>    

          <Grid item sx={{ width: "100%", overflowY: "auto"}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing="50px" sx={{width: "100%", height: "100%"}} >
                <div className=" border-[3px] h-[100%] 2xl:max-w-[700px] max-w-[500px] border-black rounded-[25px] lg:py-7 py-6 px-8 lg:px-10 flex flex-col justify-center gap-4 text-[24px]">
                  <p className="text-[23px] xl:text-[28px] font-[400] xl:leading-[39px] font-Montserrat">
                    This will help determine if you
                    need things like reading glasses
                    or other corrective methods.
                  </p>{" "}
                  <p className="text-[23px] xl:text-[28px] font-[400] xl:leading-[39px] font-Montserrat">
                    <span className="font-[700] block">
                      Please remove your glasses
                    </span>
                    after reading the instructions and before starting the tests.
                  </p>
                </div>
                <Stack direction="row" justifyContent="center"  sx={{ overflow: "hidden"}}>
                  <img
                  src={reading}
                  className="h-[100%]  w-auto max-h-[380px]"
                  ></img>
                </Stack>
            </Stack>
          </Grid>

          <Grid item>
            <Button
              style={{
                backgroundColor: "#7C6FF7",
                borderRadius: "25px",
                color: "white",
                fontSize: "24px",
                padding: "1.8rem",
                height: "3.5rem",
                fontWeight: "700",
              }}
              appearance="primary"
              className="button-style"
              onClick={handleNext}
            >
              START INSTRUCTIONS
            </Button>
          </Grid>

    </Grid>
  );
}

export default Step13;
