import React from "react";
import { Button, Grid, Stack } from "@mui/material";
import homevvs from "../../Images/home-vvs.png";
import Navigation from "../navigations/Navigation";
import Logo from "../logo/Logo";
import step17 from "../../Images/blackdots.png";
import dr from "../../Images/drwithgirl.png";
import FixedHeading from "../FixedHeading";

function Step18({ handleNext, handlePrev }) {
  return (
    <Grid container spacing="20px" sx={{height: "100%", maxHeight: "680px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item sx={{ width: "100%"}}>
            <FixedHeading>
              <p className="text-[#7C6FF7] text-center lg:text-[30px] text-[25px] xl:text-[40px] 2xl:text-[50px] font-[800] xl:leading-[48px] font-Montserrat">
                Next, we’re going to see if you are experiencing any abnormalities
                in your vision.
              </p>
            </FixedHeading>
          </Grid>

          <Grid item sx={{ width: "100%", overflowY: "auto"}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing="20px" sx={{width: "100%", height: "100%"}} >
                <div className="text-left h-[100%] flex border-[3px] 2xl:max-w-[700px] max-w-[500px] border-black rounded-[25px] lg:px-8 px-4 py-5 xl:py-10 flex-col justify-center text-[24px] ">
                  <p className="lg:text-[25px] text-[20px] lg:pr-20 xl:text-[27px] 2xl:text-[32px] font-[400] xl:leading-[39px] font-Montserrat">
                    This will help determine if you are experiencing eye conditions
                    requiring urgent care.
                  </p>{" "}
                  <br />
                  <p className="lg:text-[25px] text-[20px] xl:text-[27px] 2xl:text-[32px] font-[700] font-Montserrat">
                    You may wear your glasses now.
                  </p>
                </div>
              
                <Stack direction="row" justifyContent="center" sx={{height: "100%", overflow: "hidden"}}>
                  <img
                    src={dr}
                    className="aspect-auto"
                  ></img>
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: "#7C6FF7",
                borderRadius: "25px",
                color: "white",
                fontSize: "24px",
                padding: "1.8rem",
                height: "3.5rem",
                fontWeight: "700",
                width: "518px",
              }}
              appearance="primary"
              className="button-style"
              onClick={handleNext}
            >
              START INSTRUCTIONS
            </Button>
          </Grid>

    </Grid>
  );
}

export default Step18;
