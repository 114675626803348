import React from "react";
import placeh3 from "../../Images/gifs/AmslerGrid.gif";
import gif from "../../Images/gifs/gif.gif";
import { LaunchGame } from "../../scripts/GameEngines";
import Navigation from "../navigations/Navigation";
import { Button, Grid, Stack } from "@mui/material";
import FixedHeading from "../FixedHeading";

function Step19({ handleNext, handlePrev, setCBResponse }) {
  return (

    <Grid container spacing="20px" sx={{height: "100%", maxHeight: "700px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item sx={{ width: "100%"}}>
            <FixedHeading>
              <p className="text-[#7C6FF7] text-center lg:text-[30px] text-[25px] xl:text-[40px] 2xl:text-[50px] font-[800] xl:leading-[48px] font-Montserrat">
                Test Instructions
              </p>
            </FixedHeading>
          </Grid>

          <Grid item sx={{ width: "100%", height: "80%", overflowY: "auto"}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing="20px" sx={{width: "100%", height: "100%"}} >
                <div className="text-justify 2xl:max-w-[700px] max-w-[500px] border-[3px] flex items-center border-black rounded-[25px] lg:py-2 py-7 px-10 h-[100%] relative text-[24px]  ">
                  <ul className="font-[500] xl:leading-[34px] lg:text-[25px] text-[20px] xl:text-[24px] 2xl:text-[27px] ml-[24px] font-Montserrat">
                    <li>
                      With one eye covered, focus on the black dot in the center.
                    </li>
                    <li>
                      If the grid lines appear to be wavy or irregular, or you see a
                      hole in the grid, use the mouse to circle the distorted areas
                      or draw the shape you see with the mouse.
                    </li>
                    <li>If the grid lines appear normal, just click submit.</li>
                    <p className="font-bold"></p>
                  </ul>
                </div>
              
                <Stack direction="row" justifyContent="center" sx={{height: "100%", overflow: "hidden"}}>
                  <img src={gif} className="aspect-auto"></img>
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Button
                style={{
                  backgroundColor: "#7C6FF7",
                  borderRadius: "25px",
                  color: "white",
                  fontSize: "24px",
                  padding: "1.8rem",
                  height: "3.5rem",
                  fontWeight: "700",
                  width: "312px",
                }}
                appearance="primary"
                className="button-style"
                onClick={() =>
                  LaunchGame(
                    "AmslerGrid",
                    (data) => {
                      setCBResponse(data);
                      handleNext();
                    },
                    40
                  )
                }
              >
                START TEST
              </Button>
          </Grid>

    </Grid>

  );
}

export default Step19;
