import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const Welcome = ({ handleNext }) => {
  return (
    <Grid container spacing="20px" sx={{height: "100%", maxHeight: "700px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{height: "20%"}}>
        <img
              src="./Assets/VSL_Logo.svg"
              // className="lg:w-[272px] h-auto w-[180px] lg:h-[120.43px]"
              className="h-[100%] w-auto min-h-[80px] "
        ></img>
      </Grid>
      <Grid item sx={{height: "70%", overflowY: "auto"}}>
        <Grid container alignItems="stretch" >
          <Grid item xs={6}>
            <Stack justifyContent="center" sx={{width: "100%", height: "100%"}}>
              <h2 className="text-[#7C6FF7] 2xl:text-[50px] xl:text-[40px] lg:text-[30px]  text-[25px] whitespace-nowrap font-[800] xl:leading-[48px] font-Montserrat">
                Virtual Vision Screener
              </h2>
              <p className="font-Montserrat lg:pt-4 2xl:text-[30px] xl:text-[22px]  pt- text-[20px] font-[400] leading-tight lg:leading-[29px]">
                This screener will conduct multiple tests to assess the health of
                your eyes and vision.
              </p>
              <p className="font-Montserrat 2xl:text-[30px] xl:text-[22px]  text-[20px]  lg:pt-4 font-[400] leading-tight lg:leading-[29px]">
                At the end of the screener:
                <span className="block">
                  You’ll receive a report that can be shared with your eye care
                  professional for further steps. ie If you need to visit a doctor
                  for glasses or a concern about your vision.
                </span>
              </p>
              <p className="font-Montserrat 2xl:text-[30px] xl:text-[22px]  text-[20px] lg:pt-4 font-[700] leading-tight lg:leading-[29px]">
                {" "}
                <strong>
                  <em>Requires a screen over 12 inches.</em>
                </strong>
              </p>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack alignItems="center" justifyContent="center" sx={{width: "100%", height: "100%"}}>
              <img
                // className="xl:h-[452px] w-[400px] h-auto lg:w-[630px] lg:h-auto xl:w-[755px]"
                className=" w-auto max-h-[370px] 2xl:max-h-[380px] h-auto "
                src="./Assets/sideimg.png"
              ></img>
            </Stack>

          </Grid>
        </Grid>
      </Grid>
      <Grid item>
            <Button
                style={{
                  backgroundColor: "#7C6FF7",
                  borderRadius: "25px",
                  color: "white",
                  fontSize: "24px",
                  padding: "1.7rem",
                  height: "3.5rem",
                  fontWeight: "700",
                  width: "177px",
                }}
                appearance="primary"
                className="button-style"
                onClick={handleNext}
              >
                BEGIN
              </Button>
      </Grid>

    </Grid>
  );
};

export default Welcome;
