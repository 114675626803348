import React from "react";
import {  Button, Checkbox, Grid, Stack } from "@mui/material";
import { LaunchDistance } from "../../scripts/GameEngines";
import placeh1 from "../../Images/placeholderImage1.png";
import placeh2 from "../../Images/placeholderImage2.png";
import Navigation from "../navigations/Navigation";
import FixedHeading from "../FixedHeading";

function Step11({
  handleNext,
  handlePrev,
  setDistanceCompleted = () => {},
  setDistance = () => {},
}) {
  const handleDistance = () => {
    LaunchDistance(setDistanceCompleted, setDistance);
    setTimeout(handleNext, 500); // make sure the black screen appear first
  };
  return (
    <Grid container spacing="15px" sx={{height: "100%", maxHeight: "700px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{ width: "100%"}}>
          <h2 className="text-[#7C6FF7] text-center lg:text-[40px] text-[25px] xl:text-[40px] 2xl:text-[50px] font-black font-Montserrat">
            When you’re at the correct distance the purple dot will dissapear.
          </h2>
      </Grid>
      <Grid item sx={{ width: "100%", height: "100%", overflowY: "auto"}}>
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{width: "100%", height: "100%"}}>

          <Grid container justifyContent="center" spacing="20px" sx={{maxWidth: "1000px", height: "fit-content", pt: 0}}>
            <Grid item xs={12}>
              <Grid container spacing="12px" sx={{width: "100%", height: "100%"}}>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="center">
                    <p className="xl:leading-[29px] font-Montserrat font-[400] text-left lg:text-[20px] xl:text-[24px] 2xl:text-[28px] text-black">
                        #1 Cover the indicated eye with your hand.
                    </p>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="center">
                    <p className="xl:leading-[29px] font-Montserrat font-[400] xtext-lef lg:text-[20px] xl:text-[24px] 2xl:text-[28px] text-black">
                    {" "}
                        #2 Staring at the white dot, SLOWLY move your head close or
                        farther from the screen until one of the purple dots dissapears.
                    </p>
                  </Stack>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12}>
              <Grid container spacing="12px" justifyContent="center" sx={{width: "100%", height: "100%"}}>
                <Grid item xs={6} >
                  <Stack direction="row" justifyContent="center">
                    <img src={placeh1} className="aspect-auto max-h-[280px]" />
                  </Stack>
                </Grid>
                <Grid item xs={6} >
                  <Stack direction="row" justifyContent="flex-start">
                    <img className="aspect-auto max-h-[280px]"  src={placeh2} />
                  </Stack>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

        </Stack>
      </Grid>
      <Grid item>
          <Button
            style={{
              backgroundColor: "#7C6FF7",
              borderRadius: "25px",
              color: "white",
              fontSize: "24px",
              padding: "1.8rem",
              height: "3.5rem",
              fontWeight: "700",
              width: "312px",
            }}
            appearance="primary"
            className="button-style"
            onClick={(event) => {
              handleDistance();
            }}
          >
            START PRACTICE
          </Button>
      </Grid>
    </Grid>
  );
}

export default Step11;
