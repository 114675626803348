import html2pdf from 'html2pdf.js'

/**
 *
 * Sends an Email using Emailjs
 *
 * @param {string} to_email recipient email
 * @param {string} to_name recipient name
 * @param {string} contentId id of the container with attachment
 */

const convertComponentToPDFBase64 = async (
  contentId = 'pdfReport'
) => {
  try {

    const pdfOptions = {
      margin: 5,
      filename: 'general_vision_assement.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }

    const pdfElement = document.getElementById(contentId) // Replace with your component's ID
      // const pdf = await html2pdf(pdfElement, pdfOptions).outputPdf() //output('blob')
      // return pdf
    
    const result = await html2pdf() // move your config in the .set({...}) function below
      .set(pdfOptions)
      .from(pdfElement)
      .outputPdf()  // add this to replace implicite .save() method, which triggers file download
      .get('pdf');

      return convertPdfBlobToBase64(result.output("blob"));
      
      // var theOutput = null;
      // var reader = new FileReader();
      // reader.onloadend = function() {
      //   theOutput = reader.result;
      //   // return reader.result;          
      // }

      // reader.readAsDataURL(result.output("blob")); 

      // .then(function (pdfObj) {
      //   var base64data;
      //   var reader = new FileReader();
      //   reader.readAsDataURL(pdfObj.output("blob")); 
      //   reader.onloadend = function() {
      //     base64data = reader.result;                
      //   }
    
      //   return base64data;

      //   // return pdfObj;
      //   // pdfObj.autoPrint();
      //   // window.open(pdfObj.output("bloburl"), "F")
      // });
    



  } catch (error) {
    console.error('Error Converting to PDF Base64')
  }
}

export const downloadPDF = async (
  contentId = 'pdfReport', firstName, lastName
) => {
  try {

    const pdfOptions = {
      margin: 5,
      filename: `${firstName} ${lastName}- Vision Science Labs Assessment- ${new Date().toLocaleDateString('en-US')}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }

    const pdfElement = document.getElementById(contentId) // Replace with your component's ID
      const pdf = await html2pdf(pdfElement, pdfOptions).outputPdf() //output('blob')
      return pdf
    
  } catch (error) {
    console.error('Error downloading PDF')
  }
}

function convertPdfBlobToBase64(pdfBlob) {
  return new Promise((resolve, reject) => {
      // Create a FileReader instance
      const reader = new FileReader();

      // Define the onload event handler
      reader.onloadend = () => {
          // Convert the result to Base64 and return it
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
      };

      // Define the onerror event handler
      reader.onerror = (error) => {
          reject(error);
      };

      // Read the Blob as a data URL
      reader.readAsDataURL(pdfBlob);
  });
}

export default convertComponentToPDFBase64;
