import React from "react";
import basicInfo from "../../Images/basic-info.png";
import drr from "../../Images/drwithgirl.png";
import { Button, Grid, Stack } from "@mui/material";
import patientConsult from "../../Images/patient-consult.png";
function Step4({ handleNext, handlePrev }) {
  return (
    <Grid container spacing="20px" sx={{height: "100%", maxHeight: "600px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{ width: "100%", height: "100%", overflowY: "auto"}}>
        
        <Stack direction="row" justifyContent="center" alignItems="center" spacing="20px" sx={{width: "100%", height: "100%"}} >
            <div className="flex xl:py-15 py-10 px-10 w-[100%] h-[100%] 2xl:max-w-[700px] max-w-[500px] flex border-[3px] w-1/2  rounded-[25px] border-black items-center justify-center">
                <p className="flex lg:text-[32px] text-[22px] font-[400]">
                  First, lets get some basic information about you. <br /> Your
                  answers will help determine if you have have any problems with
                  your vision.
                </p>
            </div>
            <Stack direction="row" justifyContent="center"  sx={{height: "100%",overflow: "hidden"}}>
              <img className="aspect-auto h-[100%] lg-h-[70%] w-auto" src={patientConsult} />
            </Stack>
        </Stack>
      </Grid>

      <Grid item>
        <Button
          style={{
            backgroundColor: "#7C6FF7",
            borderRadius: "25px",
            color: "white",
            fontSize: "24px",
            padding: "1.8rem",
            height: "3.5rem",
            fontWeight: "700",
            width: "177px",
          }}
          appearance="primary"
          className="button-style"
          onClick={handleNext}
        >
          NEXT
        </Button>
      </Grid>

    </Grid>
  );
}

export default Step4;
