import React, { useEffect, useState, useRef } from "react";

import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
import Step10 from "./Step10";
import Step11 from "./Step11";
import Step12 from "./Step12";
import Step13 from "./Step13";
import Step14 from "./Step14";
import Step15 from "./Step15";
import Step16 from "./Step16";
import Step17 from "./Step17";
import Step18 from "./Step18";
import Step19 from "./Step19";
import Step20 from "./Step20";
import Step21 from "./Step21";
import Step22 from "./Step22";
import Step23 from "./Step23";
import Step24 from "./Step24";
import useStepUrlChange from "../../hooks/useStepUrlChange";
import IntermediateStep from "./IntermediateStep";
import DryEyeSurvey1 from "./DryEyeSurvey1";
import DryEyeSurvey2 from "./DryEyeSurvey2";
import Survey from "./Survey";
import { getClinicianEmail } from "../../utils/firebaseFunctions";
import { LinearProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";

function Steps() {
  const [step, setStep] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(true);

  // getting query params
  const searchPart = window.location.search;
  const searchParams = new URLSearchParams(searchPart);
  const clinicianCode = searchParams.get('clinician');
  const theTest = searchParams.get('test');
  const isTest = useRef();
  
  if (theTest && theTest == "true") isTest.current = theTest;
  const clinicianEmail = useRef();

  useEffect(() => {
      getEmailFromCode();
  }, []);

 const getEmailFromCode = async () => {
  try {
    setError(null);
    if (!clinicianCode) throw "The URL is not valid."
    const result = await getClinicianEmail(clinicianCode);
    if (result && result.email && result.email !== "") clinicianEmail.current = result.email;
    else throw "There is a problem with this link configuration. Please contact system administrator."
    setLoading(false);
  } catch (err) { setError(err); setLoading(false);}
}

  
  const handleNext = () => setStep(step === 26 ? step : step + 1);
  const handlePrev = () => setStep(step === 0 ? step : step - 1);
  const [conditions, setConditions] = useState([]);
  const [additionalData, setAdditionalData] = useState("");
  const [CBResponse, setCBResponse] = useState("");
  const [CBResponseR, setCBResponseR] = useState("");
  const [VALNCBResponse, setVALNCBResponse] = useState(null);
  const [VARNCBResponseR, setVARNCBResponseR] = useState(null);
  const [VALFCBResponse, setVALFCBResponse] = useState(null);
  const [VARFCBResponseR, setVARFCBResponseR] = useState(null);
  const [wearGlasses, setWearGlasses] = useState("no");
  const [showInterMediate, setShowIntermediate] = useState(false);
  const [useTapeMesure, setuseTapeMesure] = useState(false);
  const [tableDataContacts, setContactTableData] = useState([
    ["", "",],
    ["", "", ],
  ]);
  const [formDataVision, setFormDataVision] = useState({
    firstName: "",
    lastName: "",
    birthYear: "",
    birthSex: "",
    lastEyeExam: "",
  });
  const [tableData, setTableData] = useState([
    // Initialize the table data with empty values
    ["", "", "", "", "", ""],
    ["", "", "", "", "", ""],
  ]);
  const [contactFormData, setContactFormData] = useState({
    email: "",
    postalCode: "",
  });
  const VisionSymptomsSurvey = [
    "Do your eyes feel uncomfortable when reading or doing close work?",
    "Do you have headaches when reading or doing close work?",
    "Do you feel sleepy when reading or doing close work?",
    "Do you lose concentration when reading or doing close work?",
    "Do you have trouble remembering What you read?",
    "Do you have double vision when reading or doing close work?",
    "Do you see the words move, jump, swim or appear to float on the page when reading or doing close work?",
    "Do you feel like you read slowly?",
    "Do your eyes ever hurt when reading or doing close work?",
    "Do your eyes feel sore when reading or doing close work?",
    "Do you feel pulling feeling around your eyes when reading or doing close work?",
    "Do you notice the words blurring or coming in and out of focus when reading or doing close work?",
  ];
  const [surveyAnswers, setSurveyAnswers] = useState([
    { id: 0, answer: "-" },
    { id: 1, answer: "-" },
    { id: 2, answer: "-" },
    { id: 3, answer: "-" },
    { id: 4, answer: "-" },
    { id: 5, answer: "-" },
    { id: 6, answer: "-" },
    { id: 7, answer: "-" },
    { id: 8, answer: "-" },
    { id: 9, answer: "-" },
    { id: 10, answer: "-" },
    { id: 11, answer: "-" },
    { id: 12, answer: "-" },
    { id: 13, answer: "-" },
    { id: 14, answer: "-" },
  ]);

  const [DryEyeSurvey1Ans, setDryEyeSurvey1Ans] = useState([
    { id: 0, answer: "-" },
    { id: 1, answer: "-" },
    { id: 2, answer: "-" },
    { id: 3, answer: "-" },
    { id: 4, answer: "-" },
    { id: 5, answer: "-" },
    { id: 6, answer: "-" },
    { id: 7, answer: "-" },
  ]);

  const [DryEyeSurvey2Ans, setDryEyeSurvey2Ans] = useState([
    { id: 0, answer: "-" },
    { id: 1, answer: "-" },
    { id: 2, answer: "-" },
    { id: 3, answer: "-" },
  ]);
  const [distanceCompleted, setDistanceCompleted] = useState(false);
  const [distance, setDistance] = useState(0);

  const stepsObject = {
    0: <Step0 handleNext={handleNext} handlePrev={handlePrev} />,
    1: (
      <Step1
        handleNext={handleNext}
        contactFormData={contactFormData}
        setContactFormData={setContactFormData}
        isTest={isTest.current}
      />
    ),
    2: <Step2 handleNext={handleNext} handlePrev={handlePrev} />,
    3: <Step3 handleNext={handleNext} handlePrev={handlePrev} />,
    4: <Step4 handleNext={handleNext} handlePrev={handlePrev} />,
    5: (
      <Step5
        handleNext={handleNext}
        handlePrev={handlePrev}
        formDataVision={formDataVision}
        setFormDataVision={setFormDataVision}
        wearGlasses={wearGlasses}
        setWearGlasses={setWearGlasses}
        tableDataContacts={tableDataContacts}
        setContactTableData={setContactTableData}
        tableData={tableData}
        setTableData={setTableData}
        isTest={isTest.current}
      />
    ),
    6: (
      <Step6
        handleNext={handleNext}
        handlePrev={handlePrev}
        setConditions={setConditions}
        selectedConditions={conditions}
        setAdditionalData={setAdditionalData}
        currentAdditionalData={additionalData}
        isTest={isTest.current}

      />
    ),
    7: <Step7 handleNext={handleNext} handlePrev={handlePrev} />,
    8: <Step8 handleNext={handleNext} handlePrev={handlePrev} />,
    9: <Step9 handleNext={handleNext} handlePrev={handlePrev} />,
    10: (
      <Step10
        handleNext={handleNext}
        handlePrev={handlePrev}
        setStep={setStep}
        setUseMesure={setuseTapeMesure}
      />
    ),
    11: (
      <Step11
        setDistanceCompleted={setDistanceCompleted}
        setDistance={setDistance}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
    ),
    12: (
      <Step12
        distanceCompleted={distanceCompleted}
        handleNext={handleNext}
        handlePrev={handlePrev}
        useMesure={useTapeMesure}
      />
    ),
    13: <Step13 handleNext={handleNext} handlePrev={handlePrev} />,
    14: (
      <Step14
        handleNext={handleNext}
        handlePrev={handlePrev}
        setVALNCBResponse={setVALNCBResponse}
        isTest={isTest.current}
      />
    ),
    15: (
      <Step15
        handleNext={handleNext}
        handlePrev={handlePrev}
        setVARNCBResponseR={setVARNCBResponseR}
        isTest={isTest.current}
      />
    ),
    16: (
      <Step16
        handleNext={handleNext}
        handlePrev={handlePrev}
        setVALFCBResponse={setVALFCBResponse}
        isTest={isTest.current}
      />
    ),
    17: (
      <Step17
        handleNext={handleNext}
        handlePrev={handlePrev}
        setVARFCBResponseR={setVARFCBResponseR}
        isTest={isTest.current}
      />
    ),
    18: <Step18 handleNext={handleNext} handlePrev={handlePrev} />,
    19: (
      <Step19
        handleNext={handleNext}
        handlePrev={handlePrev}
        setCBResponse={setCBResponse}
      />
    ),
    20: (
      <Step20
        handleNext={handleNext}
        handlePrev={handlePrev}
        setCBResponse={setCBResponseR}
      />
    ),
    21: <Step21 handleNext={handleNext} handlePrev={handlePrev} />,

    22: (
      <Survey
        questions={VisionSymptomsSurvey}
        handleNext={handleNext}
        handlePrev={handlePrev}
        setSurveyAnswers={setSurveyAnswers}
        surveyAnswers={surveyAnswers}
        isTest={isTest.current}
      />
    ),
    // 23: (
    //   <DryEyeSurvey1
    //     handleNext={handleNext}
    //     handlePrev={handlePrev}
    //     setSurveyAnswers={setDryEyeSurvey1Ans}
    //   />
    // ),
    23: (
      <DryEyeSurvey2
        handleNext={handleNext}
        handlePrev={handlePrev}
        setSurveyAnswers={setDryEyeSurvey2Ans}
        surveyAnswers={DryEyeSurvey2Ans}
        isTest={isTest.current}
      />
    ),
    // 24: <Step22 handleNext={handleNext} handlePrev={handlePrev} />,
    24: <Step22 
        clinicianEmail={clinicianEmail.current}
        handlePrev={() => setStep(0)}
        formDataVision={formDataVision}
        conditions={conditions}
        contactFormData={contactFormData}
        CBResponse={CBResponse}
        CBResponseR={CBResponseR}
        wearGlasses={wearGlasses}
        tableDataContacts={tableDataContacts}
        tableData={tableData}
        additionalData={additionalData}
        VALNCBResponse={VALNCBResponse}
        VARNCBResponseR={VARNCBResponseR}
        VALFCBResponse={VALFCBResponse}
        VARFCBResponseR={VARFCBResponseR}
        distance={distance}
        dryEye1={surveyAnswers}
        dryEye2={DryEyeSurvey2Ans}
      />,
    25: (
      <Step24
        clinicianEmail={clinicianEmail.current}
        handlePrev={() => setStep(0)}
        formDataVision={formDataVision}
        conditions={conditions}
        contactFormData={contactFormData}
        CBResponse={CBResponse}
        CBResponseR={CBResponseR}
        wearGlasses={wearGlasses}
        tableDataContacts={tableDataContacts}
        tableData={tableData}
        additionalData={additionalData}
        VALNCBResponse={VALNCBResponse}
        VARNCBResponseR={VARNCBResponseR}
        VALFCBResponse={VALFCBResponse}
        VARFCBResponseR={VARFCBResponseR}
        distance={distance}
        dryEye1={surveyAnswers}
        dryEye2={DryEyeSurvey2Ans}
      />
    ),
    26: (
      <Step24
        clinicianEmail={clinicianEmail.current}
        handlePrev={() => setStep(0)}
        formDataVision={formDataVision}
        conditions={conditions}
        contactFormData={contactFormData}
        CBResponse={CBResponse}
        CBResponseR={CBResponseR}
        wearGlasses={wearGlasses}
        tableDataContacts={tableDataContacts}
        tableData={tableData}
        additionalData={additionalData}
        VALNCBResponse={VALNCBResponse}
        VARNCBResponseR={VARNCBResponseR}
        VALFCBResponse={VALFCBResponse}
        VARFCBResponseR={VARFCBResponseR}
        distance={distance}
        dryEye1={DryEyeSurvey1Ans}
        dryEye2={DryEyeSurvey2Ans}
      />
    ),
  };

  useStepUrlChange(step, setStep, clinicianCode, isTest.current);

  return (
    <>
    { !error && !isLoading && clinicianEmail.current &&
      <div className="flex w-[100%] h-[100vh]  items-center justify-center py-[3rem] px-[6rem]  ">
        {showInterMediate ? (
          <IntermediateStep
            setShowIntermediate={setShowIntermediate}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        ) : (
          stepsObject[step]
        )}
      </div>
    }
    { error &&
      <div className="flex w-[100%] h-[100vh]  items-center justify-center py-[3rem] px-[6rem]  ">
        <Stack alignItems="center" spacing="50px">
          <img
                src="./Assets/VSL_Logo.svg"
                // className="lg:w-[272px] h-auto w-[180px] lg:h-[120.43px]"
                className="h-[150px] w-auto "
          ></img>
          <Typography textAlign="center" sx={{fontSize: "28px"}} color="error">
            {error.toString()}
          </Typography>
        </Stack>

      </div>
    }
    { !error && isLoading &&
      <div className="flex w-[100%] h-[100vh]  items-center justify-center py-[3rem] px-[6rem]  ">
        <Stack alignItems="center" spacing="50px">S
          <img
                src="./Assets/VSL_Logo.svg"
                // className="lg:w-[272px] h-auto w-[180px] lg:h-[120.43px]"
                className="h-[150px] w-auto "
          ></img>
          <Typography textAlign="center" sx={{fontSize: "28px"}} >
            Loading ...
          </Typography>
          <LinearProgress sx={{width: "50vw"}} />
        </Stack>
      </div>
    }
    </>
  );
}

export default Steps;
