import React from "react";
import { Button } from "rsuite";
import basicInfo from "../../Images/basic-info.png";
import desk from "../../Images/Distance-from-screen.png";
import chacha from "../../Images/docchahcha.png";
import callibration1 from "../../Images/callibration-1.png";
import Navigation from "../navigations/Navigation";
import measuring from "../../Images/measuring.png";
function Step12({ distanceCompleted, handleNext, handlePrev, useMesure }) {
  return (
    <>
      {distanceCompleted ? (
        <div className="flex flex-col  max-w-[90rem] mx-auto gap-[6rem]">
            <div className="flex  justify-between     flex-col">
              <div className="flex flex-row-reverse items-center justify-between  gap-5">
                <div className="flex justify-center w-full ">
                  <img className="w-[30rem] aspect-auto mt-20" src={chacha} />
                </div>
                <div className="flex flex-col items-center w-full xl:px-[3rem]">
                  <p className="text-[#7C6FF7] lg:text-[30px] text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
                    Great!
                  </p>
                  <p className="lg:text-[30px] text-[25px] xl:text-[32px] pt-6 text-center font-[400] xl:leading-[39px] font-Montserrat">
                    {" "}
                    You’ll be asked to do this multiple times for the tests coming
                    up.
                  </p>
                </div>
              </div>
              <div className="flex   justify-center mt-2 uppercase font-Montserratw-full ">
                <Button
                  style={{
                    backgroundColor: "#7C6FF7",
                    borderRadius: "25px",
                    color: "white",
                    fontSize: "24px",
                    padding: "1.8rem",
                    height: "3.5rem",
                    fontWeight: "700",
                    width: "177px",
                  }}
                  appearance="primary"
                  className="button-style"
                  onClick={handleNext}
                >
                  NEXT
                </Button>
              </div>
            </div>
        </div>
      ) : 
        <div className="flex flex-col h-[10vh]   h-[80vh] max-w-[90rem] mx-auto justify-center w-full   gap-[2rem] align-center">
          <div className="flex  justify-center w-full ">
            <img className="w-[30rem] aspect-auto" src={measuring} />
          </div>
          <div className="flex  justify-between   h-full  flex-col">
            <div className="flex flex-col items-center w-full pb-[1rem] px-[6rem]">
              <p className="text-[32px] text-center ">
                You need to distance your face to the screen with a tape
                measure. Please make sure you have it ready before continuing.
              </p>
            </div>
            <div className="flex uppercase   font-Montserrat w-full justify-center  gap-[2rem]">
              {/* <Button
              style={{
                backgroundColor: "#024E99",
                borderRadius: "25px",
                color: "white",
                fontSize: "1.2rem",
                padding: "1.3rem",
                height: "3.5rem",
                fontWeight: "700",
              }}
              appearance="primary"
              className="button-style"
            >
              Save and Logout
            </Button> */}
              <Button
                style={{
                  backgroundColor: "#7C6FF7",
                  borderRadius: "25px",
                  color: "white",
                  fontSize: "24px",
                  padding: "1.8rem",
                  height: "3.5rem",
                  fontWeight: "700",
                }}
                appearance="primary"
                className="button-style"
                onClick={handleNext}
              >
                I HAVE A MEASURING DEVICE
              </Button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Step12;
