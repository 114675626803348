import React from "react";
import bullet from "../../Images/bullet-point.svg";
import bg from "../../Images/smexyimg.png";
import { Box, Button, Grid, Stack } from "@mui/material";
import Fixed from "../Fixed";
import FixedHeading from "../FixedHeading";

function Step3({ handleNext }) {
  return (
    <Grid container spacing="20px" sx={{height: "100%", maxHeight: "700px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item sx={{ width: "100%"}}>
            <FixedHeading>
              <p className=" text-[#7C6FF7]  text-center 2xl:text-[50px] lg:text-[30px] text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
                The following tests will cover:
              </p>
            </FixedHeading>
          </Grid>

          <Grid item sx={{ width: "100%", overflowY: "auto"}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing="20px" sx={{width: "100%", height: "100%"}} >
              <div className="xl:py-15 py-10 px-10 w-[100%] h-[100%] 2xl:max-w-[700px] max-w-[500px]  flex border-[3px] w-1/2 rounded-[25px] border-black items-center justify-center">
                <ul className="2xl:text-[28px] lg:text-[24px] md:text-[20px] sm:text-[15px] text-[20px] pl-10 flex flex-col gap-2 lg:gap-3 text-black leading-[32px] xs:leading=[40px] lg:leading-[35px] 2xl:leading-[50px] font-Montserrat">
                  <li>Visual Health Questions</li>
                  <li>Reading Distance Measurement</li>
                  <li>Computer Distance Measurement</li>
                  <li>Retinal Disease Screener</li>
                  <li>Eye Strain Test</li>
                  <li>Dry Eye Test</li>
                </ul>
              </div>
              
                <Stack direction="row" justifyContent="center" sx={{height: "100%", overflow: "hidden"}}>
                  <img
                    // className="lg:w-auto lg:h-auto w-[300px] h-[300px] aspect-auto"
                    className="lg:h-[120%] h-[100%] w-auto aspect-auto lg:top-[15%] top-[20%] relative translate-y-[-20%]"
                    src={bg}
                  />
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: "#7C6FF7",
                borderRadius: "25px",
                color: "white",
                fontSize: "24px",
                padding: "1.8rem",
                height: "3.5rem",
                fontWeight: "700",
                width: "177px",
              }}
              appearance="primary"
              className="button-style"
              onClick={handleNext}
            >
              START
            </Button>
          </Grid>


        <div className="flex flex-col    relative w-full   items-center  uppercase font-Montserrat  justify-end ">


          <div
            className="flex w-[50%] justify-center"
            onMouseEnter={(e) => {
              e.target.querySelector(".tooltip").style.visibility = "visible";
              e.target.querySelector(".tooltip").style.opacity = "1";
            }}
            onMouseLeave={(e) => {
              e.target.querySelector(".tooltip").style.visibility = "hidden";
              e.target.querySelector(".tooltip").style.opacity = "0";
            }}
          ></div>
        </div>

    </Grid>
  );
}

export default Step3;
