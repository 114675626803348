import React, { useState } from "react";
import { Button, Grid, Stack } from "@mui/material";
import homevvs from "../../Images/home-vvs.png";
import Navigation from "../navigations/Navigation";
import { LaunchGame } from "../../scripts/GameEngines";
import desk from "../../Images/Distance-from-screen.png";
import girls from "../../Images/girls.png";
import covereye from "../../Images/cover_eye.png";
import visualacuity from "../../Images/visualacuity.png";
import FixedHeading from "../FixedHeading";

function Step16({ handleNext, handlePrev, isTest, setVALFCBResponse = () => {} }) {
  const [stepFix, setStepFix] = useState(false);
  const handleVisualAcuity = () => {
    if (isTest) {
      setVALFCBResponse({
        "Acuity": "20",
        "Hits": [
            5,
            0
        ]
      });
      handleNext();
    } else
    LaunchGame(
      "VisAcuityFar",
      (results) => {
        console.log(results);
        setVALFCBResponse(results);
        handleNext();
      },
      "calculateDistance"
    );
  };
  return (
    <>
      {!stepFix && (
    <Grid container spacing="20px" sx={{height: "100%", maxHeight: "680px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item sx={{ width: "100%"}}>
                <FixedHeading>
                  <p className="text-[#7C6FF7] pt-0 lg:text-[30px] text-center text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
                    Next, we’re going to measure your computer acuity.
                  </p>
                </FixedHeading>
          </Grid>
          <Grid item sx={{ width: "100%", height: "80%", overflowY: "auto"}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing="20px" sx={{width: "100%", height: "100%"}} >

                  <div className="flex flex-col px-4 text-left h-[100%] 2xl:max-w-[700px] max-w-[500px] lg:px-10 xl:py-7 py-6 border-[3px] border-black rounded-[25px] justify-center items-center">
                    <p className="lg:text-[24px] text-[20px] text-black 2xl:text-[32px] xl:text-[26px] font-[400] xl:leading-[39px] font-Montserrat">
                      This will help determine if you
                        need glasses or other
                          corrective methods at a screen distance.
                    </p>{" "}
                    <br />
                    <p className="lg:text-[25px] mr-auto text-[20px] 2xl:text-[32px] text-black xl:text-[26px] font-[400] xl:leading-[39px] font-Montserrat">
                      <span className="font-[700] block">
                        Please remove your glasses
                      </span>
                      after reading the instructions
                      and before starting the tests.
                    </p>
                  </div>

                <Stack direction="row" justifyContent="center"  sx={{height: "100%", overflow: "hidden"}}>
                    <img
                      src={girls}
                      className="aspect-auto"
                    ></img>
                  </Stack>
            </Stack>
          </Grid>

          <Grid item>
              <Button
                style={{
                  backgroundColor: "#7C6FF7",
                  borderRadius: "25px",
                  color: "white",
                  fontSize: "24px",
                  padding: "1.8rem",
                  height: "3.5rem",
                  fontWeight: "700",
                  width: "518px",
                }}
                appearance="primary"
                className="button-style"
                onClick={() => setStepFix(true)}
              >
                START INSTRUCTIONS
              </Button>
          </Grid>

 
        </Grid>
      )}
      {stepFix && (
        <Grid container spacing="15px" sx={{height: "100%", maxHeight: "700px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item sx={{ maxWidth: "100%"}}>
            <FixedHeading>
              <p className="text-[#7C6FF7]  lg:text-[30px] text-left text-[25px] xl:text-[40px] 2xl:text-[50px] font-[800] xl:leading-[48px] font-Montserrat">
                Test Instructions
              </p>
            </FixedHeading>
          </Grid>
          <Grid item sx={{ width: "100%", height: "100%", overflowY: "auto"}}>
            <Stack direction="row" justifyContent="center" sx={{width: "100%" , height: "100%"}}>
              <Grid container justifyContent="center" spacing="12px" sx={{maxWidth: "1000px", height: "100%"}}>
                <Grid item xs={12}>
                  <Grid container spacing="12px" sx={{width: "100%", height: "100%"}}>
                    <Grid item xs={6}>
                      <Stack direction="row" justifyContent="center">
                        <p className="text-[24px] 2xl:text-[30px] xl:text-[28px]">
                          Cover the indicated eye with your hand.
                        </p>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack direction="row" justifyContent="center">
                        <p className="text-[24px] 2xl:text-[30px] xl:text-[28px]">
                          Using your other hand, indicate the open direction of the E
                          symbols.
                        </p>
                      </Stack>
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing="12px" justifyContent="center" sx={{width: "100%", height: "100%"}}>
                    <Grid item xs={6} >
                      <Stack direction="row" justifyContent="center">
                        <img
                          className="aspect-auto max-h-[290px]"
                          src={covereye}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6} >
                      <Stack direction="row" justifyContent="center" alignItems="center" sx={{height: "100%"}}>
                        <img className=" aspect-auto max-h-[290px]" src={visualacuity} />
                      </Stack>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item>
              <Button
                style={{
                  backgroundColor: "#7C6FF7",
                  borderRadius: "25px",
                  color: "white",
                  fontSize: "24px",
                  padding: "1.8rem",
                  height: "3.5rem",
                  fontWeight: "700",
                  width: "312px",
                }}
                appearance="primary"
                className="button-style"
                onClick={(event) => {
                  handleVisualAcuity();
                }}
              >
                START TEST
              </Button>
          </Grid>

        </Grid>
      )}
    </>
  );
}

export default Step16;


// <div className="flex flex-col w-full   w-max-w-[90rem] w-full h-[10vh]  h-[80vh] mx-auto gap-[4rem] items-center justify-center">
// <div className="flex  justify-between  w-full  h-full  flex-col">
//   <div className=" flex flex-col gap-[2rem]">
//     <div className="flex justify-start w-full">
//       <FixedHeading>
//         <p className="text-[#7C6FF7] pt-2 lg:text-[30px] text-center text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
//           Test Instructions
//         </p>
//       </FixedHeading>
//     </div>
//     <div className="flex gap-[2rem] w-full">
//       <div className="flex flex-col-reverse w-1/2 gap-6">
//         <div className="flex border-[3px] mt-2 lg:mt-0 border-black rounded-[25px] justify-center">
//           <img
//             className="xl:h-[23.5rem] h-[15rem] lg:h-[20rem] aspect-auto"
//             src={covereye}
//           />
//         </div>
//         <p className="text-[1.5rem] text-center h-[3.5rem]">
//           Cover the indicated eye with your hand.
//         </p>
//       </div>
//       <div className="flex flex-col-reverse w-1/2 gap-6">
//         <div className="xl:h-[23.5rem] mt-2 lg:mt-0 h-[15rem] lg:h-[20rem] relative z-40 border-[1px] border-black flex justify-center">
//           <img className="" src={visualacuity} />
//         </div>

//         <p className="text-[1.5rem] text-center h-[3.5rem]">
//           Using your other hand, indicate the open direction of the E
//           symbols.
//         </p>
//       </div>
//     </div>
//   </div>
//   {/* <a>Click to see the instruction video.</a> */}

//   <div className="flex justify-center  relative   w-full uppercase font-Montserrat">
//     <Button
//       style={{
//         backgroundColor: "#7C6FF7",
//         borderRadius: "25px",
//         color: "white",
//         fontSize: "24px",
//         padding: "1.8rem",
//         height: "3.5rem",
//         fontWeight: "700",
//         width: "312px",
//       }}
//       appearance="primary"
//       className="button-style"
//       onClick={(event) => {
//         handleVisualAcuity();
//       }}
//     >
//       START TEST
//     </Button>
//   </div>
// </div>
// </div>