import React from "react";
import visualacuity from "../../Images/visualacuity.png";
import {  Button, Grid, Stack } from "@mui/material";
import Navigation from "../navigations/Navigation";
import { LaunchGame } from "../../scripts/GameEngines";
import covereye from "../../Images/cover_eye.png";
import FixedHeading from "../FixedHeading";

function Step14({ handleNext, handlePrev, setVALNCBResponse, isTest }) {
  const handleVisualAcuity = () => {
    if (isTest) {
      setVALNCBResponse({
        "Acuity": "40",
        "Warning": 40,
        "Hits": [
            5,
            0
        ]
      });
      handleNext();
    } else 
    LaunchGame(
      "VisAcuityNear",
      (results) => {
        console.log(results);
        setVALNCBResponse(results);
        handleNext();
      },
      40
    );
  };

  return (
    <Grid container spacing="15px" sx={{height: "100%", maxHeight: "700px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{ maxWidth: "100%"}}>
        <FixedHeading>
          <p className="text-[#7C6FF7]  lg:text-[30px] text-left text-[25px] xl:text-[40px] 2xl:text-[50px] font-[800] xl:leading-[48px] font-Montserrat">
            Test Instructions
          </p>
        </FixedHeading>
      </Grid>
      <Grid item sx={{ width: "100%", height: "100%", overflowY: "auto"}}>
        <Stack direction="row" justifyContent="center" sx={{width: "100%" , height: "100%"}}>
          <Grid container justifyContent="center" spacing="12px" sx={{maxWidth: "1000px", height: "100%"}}>
            <Grid item xs={12}>
              <Grid container spacing="12px" sx={{width: "100%", height: "100%"}}>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="center">
                    <p className="text-[24px] 2xl:text-[30px] xl:text-[28px]">
                      Cover the indicated eye with your hand.
                    </p>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="center">
                    <p className="text-[24px] 2xl:text-[30px] xl:text-[28px]">
                      Using your other hand, indicate the open direction of the E
                      symbols.
                    </p>
                  </Stack>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12}>
              <Grid container spacing="12px" justifyContent="center" sx={{width: "100%", height: "100%"}}>
                <Grid item xs={6} >
                  <Stack direction="row" justifyContent="center">
                    <img
                      className="aspect-auto max-h-[290px]"
                      src={covereye}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6} >
                  <Stack direction="row" justifyContent="center" alignItems="center" sx={{height: "100%"}}>
                    <img className=" aspect-auto max-h-[290px]" src={visualacuity} />
                  </Stack>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item>
          <Button
            style={{
              backgroundColor: "#7C6FF7",
              borderRadius: "25px",
              color: "white",
              fontSize: "24px",
              padding: "1.8rem",
              height: "3.5rem",
              fontWeight: "700",
              width: "312px",
            }}
            appearance="primary"
            className="button-style"
            onClick={(event) => {
              handleVisualAcuity();
            }}
          >
            START TEST
          </Button>
      </Grid>

    </Grid>
  );
}

export default Step14;
