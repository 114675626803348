import React from "react";
import { Button, Grid, Stack } from "@mui/material";
import screenSize2 from "../../Images/gifs/Screen-sizeGif.gif";
import { LaunchCali } from "../../scripts/GameEngines";
import Navigation from "../navigations/Navigation";

function Step9({ handleNext, handlePrev }) {
  const stepsCB = (completed) => {
    if (completed) {
      handleNext();
    } else {
    }
  };

  const handleButton = () => {
    LaunchCali(stepsCB);
  };
  return (
    <Grid container spacing="20px" sx={{ maxHeight: "700px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{ width: "100%", height: "80%", overflowY: "auto"}}>      
        <Stack direction="row" justifyContent="center" alignItems="center" spacing="20px" sx={{width: "100%", height: "100%"}} >
            <div className="flex flex-col w-[100%]  2xl:max-w-[700px] max-w-[500px] border-[3px] border-black rounded-[25px] h-[100%] py-14 justify-center px-8 xl:px-14 lg:px-8 ">
              <p className="text-[#7C6FF7] lg:text-[30px] text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
                Use an ID or Credit Card to get your screen size.
              </p>
              <ul className="">
                <li className="font-[400] xl:leading-[39px] xl:text-[32px] lg:text-[25px] text-[20px] text-black ml-6 mt-12 font-Montserrat">
                  Click Next and follow the instructions on the next page.
                </li>
              </ul>
            </div>
            <Stack direction="row" justifyContent="center" sx={{ overflow: "hidden"}}>
              <img img className="h-[100%] 2xl:max-h-[480px]" src={screenSize2} />
            </Stack>
        </Stack>
      </Grid>
      <Grid item>
          <Button
            style={{
              backgroundColor: "#7C6FF7",
              borderRadius: "25px",
              color: "white",
              fontSize: "24px",
              padding: "1.8rem",
              height: "3.5rem",
              fontWeight: "700",
              width: "177px",
            }}
            appearance="primary"
            className="button-style"
            onClick={handleButton}
          >
            NEXT
          </Button>
      </Grid>
      
    </Grid>
  );
}

export default Step9;
