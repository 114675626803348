import React, { useState } from "react";

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  Box,
} from "@mui/material";

function ContactForm({ formData, setFormData, isError, setError, handleNext, isTest }) {


  
  const formik = useFormik({
    initialValues: {
        email: isTest ? 'patient@sample.com' : '' ,
        postalCode: isTest ? 'OX234I' : '' ,
        submit: null,
    },
    validationSchema: yup.object({
        email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
        postalCode: yup.string().required('Postal Code is required'),
    }),
    onSubmit: async (values, {resetForm}) => {
      setFormData((prevFormData) => ({
        // ...prevFormData,
        email: values.email,
        postalCode: values.postalCode
      }));
      handleNext();
    }
    });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };
  // const isEmailEmpty = () => {
  //   return formData.email.trim() === "";
  // };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!isEmailEmpty()) {
  //     handleNext();
  //   } else {
  //     console.log("Empty Email");
  //   }
  // };

  return (
    <Grid container spacing="20px" sx={{height: "100%", maxHeight: "450px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{maxHeight: "600px", width: "100%", overflowY: "auto"}}>
        <Stack justifyContent="center" alignItems="center"  sx={{width: "100%", height: "100%"}}>
              <div style={{marginBottom : "20px", maxWidth: "1000px"}} className="flex text-[#7C6FF7] text-[40px] font-[800] leading-[48px] w-[100%] font-Montserrat xl:mt-[10px]">
                Getting Started
              </div>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing="20px" sx={{width: "100%", height: "100%", maxWidth: "1000px"}} >
            <form
                  className="flex flex-col justify-center w-full max-w-[450px]"
                  onSubmit={formik.handleSubmit}
            >
            <Stack spacing="20px">

                      <TextField
                        // style={{ border: "1px solid black", borderRadius: "5px" }}
                        error={Boolean(formik.errors.email && formik.touched.email)}
                        label={"Email"}
                        fullWidth
                        type="email"
                        name="email"
                        variant="outlined"
                        placeholder="Enter your email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        helperText={ formik.touched.email && formik.errors.email}
                        
                      />

                    <div className="flex text-[24px] font-[400] leading-[29px] font-Montserrat items-center">
                        We need this to send the results to the doctor.
                    </div>


                    <TextField
                      // style={{ border: "1px solid black", borderRadius: "5px" }}
                      error={Boolean(formik.errors.postalCode && formik.touched.postalCode)}
                      helperText={ formik.touched.postalCode && formik.errors.postalCode}
                      fullWidth
                      label="Postal Code"
                      type="text"
                      name="postalCode"
                      variant="outlined"
                      placeholder="Postal Code"
                      value={formik.values.postalCode}
                      onChange={formik.handleChange}
                      
                    />



            </Stack>
            </form>

            <img
              className="w-[20rem] h-auto"
              src="./Assets/VSL_Logo.svg"
            ></img>
          </Stack>
        </Stack>
      </Grid>
      <Grid item>
          <Button
            style={{
              backgroundColor: "#7C6FF7",
              borderRadius: "25px",
              color: "white",
              fontSize: "24px",
              padding: "1.8rem",
              height: "3.5rem",
              fontWeight: "700",
              width: "177px",
            }}
            appearance="primary"
            className="button-style"
            onClick={formik.handleSubmit}
          >
            Next
          </Button>
      </Grid>

    </Grid>
  );
}

export default ContactForm;
