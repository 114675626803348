import React from "react";
import { Button } from "rsuite";
import callibration1 from "../../Images/callibration-1.png";
import Navigation from "../navigations/Navigation";
import basic from "../../Images/bgcalib.png";
import Man from "../../Images/Man.png";

function Step7({ handleNext, handlePrev }) {
  return (
    <div className="w-full  h-[10vh]   h-[80vh] ">
      <div className="absolute bottom-0 left-0">
        <img
          src={basic}
          alt=""
          className="w-[1550px] 2xl:w-[100%] h-[1100px] xl:h-[1600px]"
        />
      </div>
      <div className="flex  justify-between   h-full  flex-col">
        <div className="flex items-center      my-auto 2xl:pt-0 max-w-[90rem]">
          <div className="relative z-40 flex flex-col xl:w-[690px] lg:pt-0 -mt-14 w-1/2  place-content-center">
            <p className="text-[#7C6FF7]   text-[30px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat text-center">
              Important Next Steps
            </p>
            <p className="xl:text-[32px] mt-12 text-[25px]  pt-2 xl:leading-[39px] font-Montserrat">
              For the following tests, we’re going to need to calibrate your
              screen.
            </p>
            <p className="xl:text-[32px] text-[25px] xl:pt-6 pt-2 xl:leading-[39px] font-Montserrat">
              {" "}
              This is important as it will ensure your results are accurate.
            </p>
          </div>

          <div className="absolute bottom-0 right-4 xl:right-24">
            <img
              className="h-auto lg:w-[300px] w-[270px] 2xl:h-[500px] 2xl:w-[500px] aspect-auto"
              src={Man}
            />
          </div>
        </div>
        <div className="z-20 flex   justify-center w-full mt-[19px] overflow-hidden uppercase font-Montserrat">
          <Button
            style={{
              backgroundColor: "#7C6FF7",
              borderRadius: "25px",
              color: "white",
              fontSize: "24px",
              padding: "1.8rem",
              height: "3.5rem",
              fontWeight: "700",
              width: "177px",
            }}
            appearance="primary"
            className="button-style"
            onClick={handleNext}
          >
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Step7;
