import React, { useState, useEffect } from "react";
import { Button, Grid, Stack, FormHelperText } from "@mui/material";
import FixedHeading from "../FixedHeading";

const Survey = ({
  questions,
  surveyData,
  handleNext,
  setSurveyAnswers,
  surveyAnswers,
  isTest
}) => {

  const [error, setError] = useState();

  useEffect(() => {
    if (error) {
      if (surveyAnswers && surveyAnswers.length > 0) {
        let hasError = false;
        for (let index in surveyAnswers) {
          const answr = surveyAnswers[index].answer;
          if (index < questions.length && (!answr || answr == "" || answr == "-") ) {
            hasError = true;
          }
        }
        if (!hasError) setError(null);
      }
    }
  }, [surveyAnswers]);

  useEffect(() => {
    if (isTest) {
      setSurveyAnswers( [
        {
            "id": 0,
            "answer": "Never"
        },
        {
            "id": 1,
            "answer": "Never"
        },
        {
            "id": 2,
            "answer": "Sometimes"
        },
        {
            "id": 3,
            "answer": "Sometimes"
        },
        {
            "id": 4,
            "answer": "Sometimes"
        },
        {
            "id": 5,
            "answer": "Never"
        },
        {
            "id": 6,
            "answer": "Not Very Often"
        },
        {
            "id": 7,
            "answer": "Sometimes"
        },
        {
            "id": 8,
            "answer": "Never"
        },
        {
            "id": 9,
            "answer": "Sometimes"
        },
        {
            "id": 10,
            "answer": "Sometimes"
        },
        {
            "id": 11,
            "answer": "Not Very Often"
        },
        {
            "id": 12,
            "answer": "-"
        },
        {
            "id": 13,
            "answer": "-"
        },
        {
            "id": 14,
            "answer": "Not Very Often"
        }
    ])
    }
  }, []);

  const checkIfAnsweredAll = () => {
    if (surveyAnswers && surveyAnswers.length > 0) {
      let hasError = false;
      for (let index in surveyAnswers) {
        const answr = surveyAnswers[index].answer;
        if (index < questions.length && (!answr || answr == "" || answr == "-") ) {
          return false;
        }
      }
      if (!hasError) return true;
    }
    return false;
  }

  function Insert(id, newAnswer) {
    let newAnswers = [...surveyAnswers];
    newAnswers[id].answer = newAnswer;
    setSurveyAnswers(newAnswers);
    surveyData = newAnswers;
  }
  const onOptionChange = (e) => {
    Insert(e.target.id, e.target.value);
  };
  const onOptionClick = (id, value) => {
    Insert(id, value);
  };

  const checkAndHandleNext = (e) => {
    // first check for errors
    setError(null);
    if (surveyAnswers && surveyAnswers.length > 0) {
      for (let index in surveyAnswers) {
        const answr = surveyAnswers[index].answer;
        if (index < questions.length && (!answr || answr == "" || answr == "-") ) {
          setError("All of the questions should be answered.");
          return;
        }
      }
      handleNext(e);
    }
  }

  return (
    <Grid container spacing="20px" sx={{height: "100%", width: "100%",  maxHeight: "900px", maxWidth: "2000px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{ width: "100%"}}>
        <p className="text-[#7C6FF7]  lg:text-[30px] text-[25px] text-[50px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
          Eyestrain Survey
        </p>
      </Grid>
      
      <Grid item sx={{height: "100%", width: "100%", overflowY: "auto"}}>
            <table style={{minHeight: "100%"}} >
              <thead className="bg-[#dddddd]">
                <tr className="font-bold 2xl:text-[16px] xl:text-[13px]">
                  <th className="w-[3rem]"></th>
                  <th></th>
                  <th className="radio-cell">Never</th>
                  <th className="radio-cell">Not often</th>
                  <th className="radio-cell">Sometimes</th>
                  <th className="radio-cell">Fairly Often</th>
                  <th className="radio-cell">Always</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question, index) => (
                  <tr style={{border: error && surveyAnswers && surveyAnswers.length >= questions.length && [null, undefined, "", "-"].includes(surveyAnswers[index].answer) && "solid 2px #e74c3c" }}>
                    <td className="text-center text-[16px] xl:text-[17px] 2xl:text-[18px]">{index + 1}</td>
                    <td className="pl-1 text-[16px] xl:text-[17px] 2xl:text-[19px] pr-1 text-center">
                      {question}
                    </td>

                    <td onClick={() => onOptionClick(index, "Never")} className="radio-cell">
                      <input
                        type="radio"
                        name={"question" + index}
                        id={index}
                        checked={surveyAnswers[index].answer === "Never"}
                        value={"Never"}
                        // onChange={onOptionChange}
                      />
                    </td>
                    <td onClick={() => onOptionClick(index, "Not Very Often")} className="radio-cell">
                      <input
                        type="radio"
                        name={"question" + index}
                        id={index}
                        value={"Not Very Often"}
                        checked={surveyAnswers[index].answer === "Not Very Often"}
                        // onChange={onOptionChange}
                      />
                    </td>
                    <td onClick={() => onOptionClick(index, "Sometimes")} className="radio-cell">
                      <input
                        type="radio"
                        name={"question" + index}
                        id={index}
                        value={"Sometimes"}
                        checked={surveyAnswers[index].answer === "Sometimes"}
                        // onChange={onOptionChange}
                      />
                    </td>
                    <td onClick={() => onOptionClick(index, "Often")} className="radio-cell">
                      <input
                        type="radio"
                        name={"question" + index}
                        id={index}
                        value={"Often"}
                        checked={surveyAnswers[index].answer === "Often"}
                        // onChange={onOptionChange}
                      />
                    </td>
                    <td onClick={() => onOptionClick(index, "Always")} className="radio-cell">
                      <input
                        type="radio"
                        name={"question" + index}
                        id={index}
                        value={"Always"}
                        checked={surveyAnswers[index].answer === "Always"}
                        // onChange={onOptionChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
      </Grid>
      



        <Grid item>
          <Stack direction="row" sx={{width: "100vw", position: "relative"}} justifyContent="center" alignItems="center">

              <FormHelperText sx={{pb: "5px", position: "absolute", bottom: "-35px", color: checkIfAnsweredAll() ? "#FFFFFF" : null}} error={error}>{error? error : "Please answer all of the questions before proceeding"}</FormHelperText>

            <Button
              style={{
                backgroundColor: "#7C6FF7",
                borderRadius: "25px",
                color: "white",
                fontSize: "24px",
                padding: "1.8rem",
                height: "3.5rem",
                fontWeight: "700",
                width: "177px",
              }}
              appearance="primary"
              className="button-style"
              onClick={checkAndHandleNext}
            >
              NEXT
            </Button>
          </Stack>

        </Grid>
      {/* <div className="">
        <p className="invisible">vdc</p>
      </div> */}
    </Grid>
  );
};
export default Survey;
