import { Button } from "@mui/material";
import React from "react";
import image18 from "../../Images/image18.png";
import placeh2 from "../../Images/Distance-from-screen.png";
import Navigation from "../navigations/Navigation";
import tapemeasure from "../../Images/tapemeasurer.png";
import FixedHeading from "../FixedHeading";

function Step10({ handleNext, handlePrev, setStep, setUseMesure }) {
  return (
    <>
      {window.Cali.ScreenSize >= 10 ? (
        <div className="flex w-max-w-[90rem] w-full mx-auto flex-col h-[10vh]   h-[80vh] gap-[4rem] items-start justify-center">
          <div className="h-[80vh]     w-full   justify-center items-center">
            <FixedHeading>
              <p className="text-[#7C6FF7] w-full  lg:text-[30px] text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
                Adjust your face distance to the screen.
              </p>
            </FixedHeading>
            <p className="lg:text-[27px] pt-2 text-[25px] xl:text-[32px] xl:pt-2  font-[400] xl:leading-[39px] font-Montserrat">
              {" "}
              IMPORTANT: Follow the directions carefully for accurate results.
            </p>
            <div className="h-[20.4rem] xl:mt-16 mx-auto">
              <img src={placeh2} className="h-[20.4rem] mx-auto aspect-auto" />
            </div>
          </div>

          <div className="flex items-center flex-col justify-between -mt-6 relative  gap-4  w-[100%] lg:gap-4 xl:gap-8 xl:flex-row xl:items-end">
            <div className="w-1/2">
              <div className="flex justify-center uppercase font-Montserrat whitespace-nowrap">
                <Button
                  sx={{
                    backgroundColor: "#7C6FF7",
                    "&:hover": {backgroundColor:"#7C6FF7"},
                    borderRadius: "25px",
                    color: "#FFFFFF",
                    fontSize: { xl: "24px", lg: "22px", md: "20px" },
                    padding: "1.8rem",
                    height: "4rem",
                    fontWeight: "700",
                    width: "100%",
                  }}
                  appearance="primary"
                  className="button-style"
                  onClick={(event) => {
                    handleNext();
                  }}
                >
                  HARDWARE-FREE INSTRUCTIONS
                </Button>
              </div>
            </div>
            <div className="w-1/2   gap-[1rem]">
              <div className="flex justify-center uppercase font-Montserrat whitespace-nowrap">
                <Button
                  sx={{
                    backgroundColor: "#7C6FF7",
                    "&:hover": {backgroundColor:"#7C6FF7"},
                    borderRadius: "25px",
                    color: "white",
                    fontSize: { xl: "24px", lg: "22px", md: "20px" },
                    padding: "1.8rem",
                    height: "4rem",
                    fontWeight: "700",
                    width: "100%",
                  }}
                  appearance="primary"
                  className="button-style"
                  onClick={() => {
                    setStep(12);
                    setUseMesure(true);
                  }}
                >
                  I’LL USE A MEASURING DEVICE
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col max-w-[90rem] mx-auto justify-center align-center h-[100%] w-full">
          <h2 className="mt-[120px] text-[25px]  text-center">
            You have indicated that you are using a screen with a size below 10
            inches. <br />
            In order to get accurate results, please get a measuring device (ie
            a tape measure) ready for the eye tests.
          </h2>
          <div className="flex justify-center">
            <img height="360px" src={image18}></img>
          </div>
          <ul className="non-ul">
            <li className="text-[24px] text-center">Video Instructions</li>
          </ul>
          <div className="mt-10 button-background-double">
            <Button
              style={{ backgroundColor: "#01216B" }}
              appearance="primary"
              className="mx-[10rem] button-style"
              onClick={handlePrev}
            >
              Save and Logout
            </Button>
            <Button
              style={{ backgroundColor: "#01216B" }}
              appearance="primary"
              className="mx-[10rem] button-style"
              onClick={handleNext}
            >
              I HAVE A MEASURING DEVICE
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default Step10;
