import React, {useState, useEffect} from "react";
import { Button } from "rsuite";
import { LinearProgress, Stack, Typography} from "@mui/material";

import basicinfo from "../../Images/basic-info.png";
import callibration1 from "../../Images/callibration-1.png";
import Navigation from "../navigations/Navigation";
import doctor from "../../Images/docchahcha.png";
import convertComponentToPDFBase64 from "../../scripts/pdfConverter";

// import "../../App.css";
import bg from "../../Images/bgcalib.png";
import Step24 from "./Step24";
import { addOrUpdatePatientResults } from "../../utils/firebaseFunctions";

function Step22({ 
  handlePrev,
  clinicianEmail,
  formDataVision,
  conditions,
  contactFormData,
  CBResponse,
  wearGlasses,
  tableDataContacts,
  tableData,
  CBResponseR,
  additionalData,
  VALNCBResponse,
  VARNCBResponseR,
  VALFCBResponse,
  VARFCBResponseR,
  distance,
  dryEye1,
  dryEye2,
 }) {
  const [isReady, setReady] = useState(false);
  const [reportView, setReportView] = useState(false);

  
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState();
  const [savedData, setSavedData] = useState(null);


  useEffect(() => {
    if (!error && !isSubmitting && !savedData)
      handleSaveResults();

    if (!clinicianEmail) setReady(true);
  }, []);

  const handleSaveResults = async () => {

    try {
      setSubmitting(true);
      setError(null);

      if (!contactFormData) throw "No Data is provided. Reload the page to start over.";
      if (!contactFormData.email || contactFormData.email == "" ) throw "No Data is provided. Reload the page to start over.";
      if (!contactFormData.postalCode || contactFormData.postalCode == "" ) throw "No Data is provided. Reload the page to start over.";

      const resultData = {
          conditions: conditions,
          CBResponse: CBResponse,
          wearGlasses: wearGlasses,
          tableDataContacts: tableDataContacts,
          tableData: tableData,
          CBResponseR: CBResponseR,
          additionalData: additionalData,
          VALNCBResponse: VALNCBResponse,
          VARNCBResponseR: VARNCBResponseR,
          VALFCBResponse: VALFCBResponse,
          VARFCBResponseR: VARFCBResponseR ,
          distance: distance,
          dryEye1: dryEye1,
          dryEye2: dryEye2
      }

      const theoutput = await convertComponentToPDFBase64();


      const payload = {
        patientEmail : contactFormData.email,
        patientInfo : { postalCode: contactFormData.postalCode, ...formDataVision },
        assessmentResult : {
            clinicianEmail: clinicianEmail,
             ...resultData 
        },
        attachmentContent: theoutput
      }

      console.log(payload);

      const result = await addOrUpdatePatientResults(payload.patientEmail, payload.patientInfo, payload.assessmentResult, payload.attachmentContent);
      setSavedData(result);
      setSubmitting(false);
      setReady(true);
    } catch (err) {
        setSubmitting(false);
        setError(err);
    }
  }


  return (
    <>
    {!reportView &&
    <div className="flex flex-col max-w-[80rem] w-full my-[10vh]    h-[80vh] gap-2 mx-auto justify-center    align-center">
      <div className="absolute h-full w-full z-[-1] inset-0">
        <img
          src={bg}
          className="object-cover w-[1550px] 2xl:w-[100%] h-[1100px] xl:h-screen"
          alt=""
        />
      </div>

      <div className="">
        {/* <div className="flex   items-center justify-end gap-4 p-4 pt-4">
          <a href="#">About Us</a>
          <a href="#">How it works</a>
          <a href="#">Service</a>
        </div> */}
        <div className="flex  justify-between   h-full  flex-col">
          <div className="flex items-center justify-between gap-12 text-center text-black">
            <div className="text-left py-4 w-[520px] h-[380px] px-6 lg:text-[24px] text-[18px] border-[3px] border-black rounded-[25px] xl:py-15 my-auto lg:px-16">
              <ul className="flex flex-col justify-center h-full space-y-5 font-semibold font-Montserrat">
                <li>The screener is now complete</li>{" "}
                <li>
                  The results will be sent to your clinician for review. Also, you can download them in the next page.
                </li>{" "}
                <li >
                  Please contact your clinician for any updates.
                </li>{" "}
              </ul>
            </div>
            <div className="flex justify-center w-[50%]">
              <img src={doctor} className="lg:w-[80%] lg:h-[450px]"></img>
            </div>
          </div>

          <div className="flex items-center pt-[80px]  flex-col gap-3  relative   justify-center ">
            { clinicianEmail && !error && isSubmitting && !savedData &&
              <Stack>
                  <Typography>Sending data to your clinician...</Typography>
                  <LinearProgress />
              </Stack>
            }
            { clinicianEmail && error &&
              <Typography onClick={handleSaveResults} sx={{textDecoration: "underline", color: "#e83030", cursor: "pointer"}} >{error}</Typography>
            }
            { clinicianEmail && !isSubmitting && !error && savedData &&
              <Typography sx={{color: "1a8b0c"}} >Results are successfuly sent to your clinician: {" " + clinicianEmail}</Typography>
            }
            <Button
              style={{
                backgroundColor: "#7C6FF7",
                borderRadius: "25px",
                color: "white",
                fontSize: "24px",
                padding: "1.8rem",
                height: "3.5rem",
                fontWeight: "700",
                width: "177px",
              }}
              disabled={!isReady}
              appearance="primary"
              className="button-style"
              onClick={() => setReportView(true)}
            >
              FINISH
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center w-full uppercase font-Montserrat">
        <Button
          style={{
            backgroundColor: "#7C6FF7",
            borderRadius: "25px",
            color: "white",
            fontSize: "24px",
            padding: "1.8rem",
            height: "3.5rem",
            fontWeight: "700",
          }}
          appearance="primary"
          className="button-style"
          onClick={handleNext}
        >
          FINISH
        </Button>
      </div> */}
      <div className="h-2">
        <p className="invisible">f</p>
      </div>
    </div>
    }

    <Step24
          clinicianEmail={clinicianEmail}
          handlePrev={handlePrev}
          formDataVision={formDataVision}
          conditions={conditions}
          contactFormData={contactFormData}
          CBResponse={CBResponse}
          CBResponseR={CBResponseR}
          wearGlasses={wearGlasses}
          tableDataContacts={tableDataContacts}
          tableData={tableData}
          additionalData={additionalData}
          VALNCBResponse={VALNCBResponse}
          VARNCBResponseR={VARNCBResponseR}
          VALFCBResponse={VALFCBResponse}
          VARFCBResponseR={VARFCBResponseR}
          distance={distance}
          dryEye1={dryEye1}
          dryEye2={dryEye2}
          hidden={!reportView}
    />

    </>
  );
}

export default Step22;
