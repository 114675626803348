/**
 * Defines Different Eye Conmditions
 */
export const EyeConditions = [
  'Blurred Vision',
  'Sandy / Gritty Feeling',
  'Tired Eyes',
  'Distorted Vision / Halos',
  'Itching',
  'Lazy Eye (Crossed Eye)',
  'Loss of Side Vision',
  'Burning',
  'Dry or Irritated Eyes',
  'Double Vision',
  'Foreign Body Sensation',
  'Post-Concussion Visual Symptoms',
  'Dryness',
  'Glare / Light Sensitivity',
  'Headaches',
  'Mucous Discharge',
  'Sties',
  'Migraines',
  'Redness',
  'Flashes/Floaters in Vision',
  'Seizures',
]

/**
 * Categorises Eye conditions base on their med backgrounds
 */
export const MedBackground = [
  {
    category: 'Visual Disturbances',
    conditions: [
      'Blurred Vision',
      'Distorted Vision / Halos',
      'Double Vision',
      'Flashers / Floaters in Vision',
      'Loss of Side Vision',
      'Lazy Eye (Crossed Eye)',
    ],
  },
  {
    category: 'Eye Conditions',
    conditions: [
      
      'Mucous Discharge',
      'Sties',
      'Redness',
      'Sandy / Gritty Feeling',
      'Tired Eyes',
      'Itching',
    ],
  },
  {
    category: 'Eye Disconfort',
    conditions: [
      

      'Burning',
      'Dry or Irritated Eyes',
      'Foreign Body Sensation',
      'Dryness',
      'Glare / Light Sensitivity',
    ],
  },
 
]

/**
 * List of Basic Survey Questions For Vision Symptomps
 */
export const VisionSymptomsSurvey = [
  'Do your eyes feel tired when reading or doing close work?',
  'Do your eyes feel uncomfortable when reading or doing close work?',
  'Do you have headaches when reading or doing close work?',
  'Do you feel sleepy when reading or doing close work?',
  'Do you lose concentration when reading or doing close work?',
  'Do you have trouble remembering What you read?',
  'Do you have double vision when reading or doing close work?',
  'Do you see the words move, jump, swim or appear to float on the page when reading or doing close work?',
  'Do you feel like you read slowly?',
  'Do your eyes ever hurt when reading or doing close work?',
  'Do your eyes feel sore when reading or doing close work?',
  'Do you feel pulling feeling around your eyes when reading or doing close work?',
  'Do you notice the words blurring or coming in and out of focus when reading or doing close work?',
  'Do you lose your place while reading or doing close work?',
  'Do you have to reread the same line of words when reading?',
]

/**
 * Lists out Tools Used in the System and Their Functions
 */

export const Library = [
  {
    title: 'Handheld Visual Performance',
    description:
      'Measures your vision when using handheld devices and reading a book.',
  },
  {
    title: 'Workstation Visual Performance',
    description:
      'Measures your vision at the screen the test is being performed.',
  },
  {
    title: 'Visual Symptom Score',
    description:
      'This survey analyzes certain symptoms that can impact the quality of your vision.',
  },
  {
    title: 'Visual Distortion Grid',
    description: 'This grid helps detect spots of vision loss or distortion.',
  },
  {
    title: 'Blind Spot Checker (Optional)',
    description: 'This checks for blind spots in your visual field.',
  },
  {
    title: 'Shadow Detection (Optional)',
    description: 'Measures your contrast sensitivity.',
  },
  {
    title: 'Do I have dry eyes? (Optional)',
    description:
      "Helps determine if you're experiencing a condition known as dry eye.",
  },
]

/**
 * Different Properties of Glass
 */
export const GlassProps = ['sph', 'cyl', 'axis', 'prism', 'base', 'pd']
