import React from "react";
import covereye from "../../Images/cover_eye.png";
import homevvs from "../../Images/home-vvs.png";
import { Button, Stack, Grid } from "@mui/material";
import Logo from "../logo/Logo";
import { LaunchGame2 } from "../../scripts/GameEngines";
import FixedHeading from "../FixedHeading";

function Step15({ handleNext, handlePrev, isTest, setVARNCBResponseR = () => {} }) {
  const handleVisualAcuity = () => {
    if (isTest) {
      setVARNCBResponseR({
        "Acuity": "32",
        "Warning": 32,
        "Hits": [
            5,
            0
        ]
      })
      handleNext();
    } else
    LaunchGame2("VisAcuityNear", (results) => {
      console.log(results);
      setVARNCBResponseR(results);
      handleNext();
    });
  };
  return (
    <Grid container spacing="15px" sx={{height: "100%", maxHeight: "700px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{ maxWidth: "100%"}}>
            <div className="flex flex-col items-center w-full px-[6rem]">
              <p className="text-[#7C6FF7] lg:text-[30px]  text-center text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
                Maintain your distance to the screen and cover your <br /> RIGHT
                eye.
              </p>
            </div>

      </Grid>
    <Grid item sx={{ width: "100%", overflowY: "auto"}}>
      <div className="flex w-full justify-center py-[1.5rem]">
            <img
              className="lg:w-[327px] lg:h-[340px] max-w-[288px] max-h-[300px] aspect-auto"
              src={covereye}
            />
          </div>
    </Grid>
    <Grid>
      <Button
        style={{
          backgroundColor: "#7C6FF7",
          borderRadius: "25px",
          color: "white",
          fontSize: "24px",
          padding: "1.8rem",
          height: "3.5rem",
          fontWeight: "700",
          width: "177px",
        }}
        appearance="primary"
        className="button-style"
        onClick={handleVisualAcuity}
      >
        NEXT
      </Button>
    </Grid>
  </Grid>
  );
}

export default Step15;
