import React, { useState } from "react";
import { Button } from "rsuite";
import BasicVisionInfo12 from "../../scenes/BasicVisionInfo1_2";
import Navigation from "../navigations/Navigation";
import FixedHeading from "../FixedHeading";
import { Box, Paper, Stack } from "@mui/material";

function Step5({
  handleNext,
  handlePrev,
  formDataVision,
  setFormDataVision,
  setWearGlasses,
  wearGlasses,
  tableDataContacts,
  setContactTableData,
  tableData,
  setTableData,
  isTest
}) {
  const [includePrescription, setIncludePrescription] = useState("no");


  const handleChangeVision = (e) => {
    const { name, value } = e.target;
    setFormDataVision({ ...formDataVision, [name]: value });
  };
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    if (name === "wearGlasses") {
      setWearGlasses(value);
    } else if (name === "includePrescription") {
      setIncludePrescription(value);
    }
  };

  const handleChangeTable1 = (e, row, col) => {
    const newData = [...tableData];
    newData[row][col] = e.target.value;
    setTableData(newData);
  };

  const handleChangeTableContacts = (e, row, col) => {
    const newData = [...tableDataContacts];
    newData[row][col] = e.target.value;
    setContactTableData(newData);
  };
  return (
    <Stack justifyContent="center" sx={{width: "100%", height: "100%"}}>
        <BasicVisionInfo12
          setFormDataVision={setFormDataVision}
          // handleChange={handleChangeVision}
          formData={formDataVision}
          handleRadioChange={handleRadioChange}
          wearGlasses={wearGlasses}
          includePrescription={includePrescription}
          tableData={tableData}
          handleChangeTable1={handleChangeTable1}
          tableDataContacts={tableDataContacts}
          handleChangeTableContacts={handleChangeTableContacts}
          handleNext={handleNext}
          isTest={isTest}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            width: "100%",
            alignItems: "center",
          }}
        >
        </div>

    </Stack>
  );
}

export default Step5;
