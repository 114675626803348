import React, {useEffect} from "react";
import { Input, Button, Checkbox, Grid, Stack, TextField } from "@mui/material";
import { MedBackground } from "../../utils/constants";
import Navigation from "../navigations/Navigation";
import { useState } from "react";
import FixedHeading from "../FixedHeading";

function Step6({ handleNext, handlePrev, setConditions, setAdditionalData, selectedConditions, currentAdditionalData, isTest }) {

  useEffect(() => {
    if(isTest) {
      setAdditionalData("This is some additional data in a note just for information.");
      setConditions(['Loss of Side Vision', 'Sandy / Gritty Feeling','Foreign Body Sensation']);
    }
  }, []);

  return (
    <Grid container spacing="15px" sx={{height: "100%", maxHeight: "650px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
      <Grid item sx={{ width: "100%"}}>
        <p className="text-[#7C6FF7] lg:text-[40px]   font-Montserrat text-[25px] font-[800]">
          Do you experience any of the following vision problems?
        </p>
      </Grid>
      <Grid item sx={{ width: "100%", overflowY: "auto"}}>
        <Stack sx={{width: "100%"}} justifyContent="space-between">
            <p className="text-[24px] font-[400] leading-[29px] font-Montserrat">
              Hover over any item for more details. Check the boxes below.
            </p>
            <div className="grid grid-cols-3 w-[100%] mt-[12px] text-[24px] pb-3">
              {MedBackground.map((obj, index) => {
                // Determine the background color class based on the column index
                const bgColorClass = index % 2 === 0 ? "bg-grey" : "bg-blue";
                return (
                  <div key={index}>
                    {obj.conditions.map((condition, conditionIndex) => (
                      <div className="flex items-center" key={conditionIndex}>
                        <Checkbox
                          key={condition}
                          id={`checkbox-${condition}`}
                          value={condition}
                          color="primary"
                          checked={selectedConditions && selectedConditions.includes(condition)}
                          onChange={(checked) => {
                            if (checked) {
                              setConditions((conditions) => [
                                ...conditions,
                                condition,
                              ]);
                            } else {
                              setConditions((conditions) =>
                                conditions.filter((c) => c !== condition)
                              );
                            }
                          }}
                        ></Checkbox>
                        <label
                          htmlFor={`checkbox-${condition}`}
                          className="text-[18px] cursor-pointer font-Montserrat"
                        >
                          {" "}
                          {condition}
                        </label>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
            <TextField
              placeholder="(Optional) Type out any additional details about your vision for the doctor."
              // style={{
              //   border: "1px solid black",
              //   fontSize: "20px",
              // }}
              value={currentAdditionalData}
              onChange={(e) => setAdditionalData(e.target.value)}
            ></TextField>

 

        </Stack>
      </Grid>
      <Grid item sx={{width: "100%"}}>
           <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{width: "100%"}}>
              <Button
                style={{
                  backgroundColor: "#7C6FF7",
                  borderRadius: "25px",
                  color: "white",
                  fontSize: "24px",
                  padding: "1.8rem",
                  height: "3.5rem",
                  fontWeight: "700",
                  width: "177px",
                }}
                appearance="primary"
                className="button-style"
                onClick={handlePrev}
              >
                BACK
              </Button>
              <Button
                style={{
                  backgroundColor: "#7C6FF7",
                  borderRadius: "25px",
                  color: "white",
                  fontSize: "24px",
                  padding: "1.8rem",
                  height: "3.5rem",
                  fontWeight: "700",
                  width: "177px",
                }}
                appearance="primary"
                className="button-style"
                onClick={handleNext}
              >
                NEXT
              </Button>
            </Stack>
      </Grid>
    </Grid>
  );
}

export default Step6;
