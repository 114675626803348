import React from "react";
import { Button, Grid, Stack } from "@mui/material";
import homevvs from "../../Images/home-vvs.png";
import Navigation from "../navigations/Navigation";
import Logo from "../logo/Logo";
import step17 from "../../Images/eyestrain.png";
import step18 from "../../Images/health].png";
import FixedHeading from "../FixedHeading";

function Step21({ handleNext, handlePrev }) {
  return (
    <Grid container spacing="20px" sx={{height: "100%", maxHeight: "680px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item sx={{ width: "100%"}}>
                <p className="text-[#7C6FF7] pb-5 text-center lg:text-[30px] 2xl:text-[50px] text-[25px] xl:text-[40px] font-[800]   font-Montserrat">
                  Do your eyes work well?
                </p>
          </Grid>

          <Grid item sx={{ width: "100%", height: "80%", overflowY: "auto"}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing="40px" sx={{width: "100%", height: "100%"}} >
                <div className="text-left xl:py-4 2xl:max-w-[700px] max-w-[500px] lg:text-[28px] lg:text-[24px] text-[20px] border-[3px] border-black rounded-[25px]  h-[100%] flex items-center my-auto px-6 lg:px-10">
                  <ul className="space-y-5 font-Montserrat ml-3">
                    <li>
                      Millions of people have been diagnosed with digital eye
                      strain since 2021 impacting work and academics
                    </li>{" "}
                    <li>
                      The following will ask you questions to help determine if
                      you’re experiencing eye strain and dry eye.
                    </li>{" "}
                    <li className="font-bold">You may wear your glasses.</li>{" "}
                  </ul>
                </div>
              
                <Stack direction="row" justifyContent="center" sx={{height: "100%", overflow: "hidden"}}>
                  <img src={step18} className="aspec-auto"></img>
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: "#7C6FF7",
                borderRadius: "25px",
                color: "white",
                fontSize: "24px",
                padding: "1.8rem",
                height: "3.5rem",
                fontWeight: "700",
                width: "15rem",
              }}
              appearance="primary"
              className="button-style"
              onClick={handleNext}
            >
              START TEST
            </Button>
          </Grid>

    </Grid>

  );
}

export default Step21;
